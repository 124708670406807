import { Close, Delete, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Typography,
  Box,
  Divider,
  CardMedia,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { deleteBrands } from "redux/festures/brandSlice";
import { updateBrands } from "redux/festures/brandSlice";
import { getBrands } from "redux/festures/brandSlice";
import { formattedDateServer } from "Utils/dateFunc";
import CreateBranner from "./createBanner";
import SkConfirm from "components/SkComfirm";
import { getAllHomeBanner, getSingleHomeBanner } from "redux/festures/homeBannerSlice";
import { SwiperSlide } from "swiper/react";
import SwiperSlider from "components/SkSlider/Swiper";
import { deleteHomeBanner } from "redux/festures/homeBannerSlice";
const columns = {
  AllBrands: [
    // { Header: "S.No", accessor: "no" },
    // { Header: "brand details", accessor: "brand details" },
    // // { Header: "Brand status", accessor: "Brand status" },
    // // { Header: "payment method ", accessor: "payment method" },
    // // { Header: "total Brands", accessor: "total Brands" },
    // // { Header: "place Brand", accessor: "place Brand" },
    // { Header: "date", accessor: "date" },
    // // { Header: "showInHome", accessor: "showInHome" },
    // { Header: "disable", accessor: "disable" },
    // { Header: "view", accessor: "view" },
    // { Header: "action", accessor: "action" },
    // { Header: "delete", accessor: "delete" },
  ],
};
const HomeBanner = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const { Loading, AllHomeBanner, singleHomeBanner } = useSelector((state) => ({
    ...state.isHomeBanner,
  }));
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const [rowsData, setRowsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isSubtitle, setIsSubtitle] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState({ open: false, isId: null });
  // console.log(AllHomeBanner);
  useEffect(() => {
    dispatch(getAllHomeBanner(`/getAllBanner`));
  }, []);

  // for upper banner index
  const [upperBannerIndex, setUpperBannerIndex] = useState(0);
  const handleSlideChangeUpperBanner = (swiper) => {
    setUpperBannerIndex(swiper.activeIndex);
  };

  // for upper banner 2 index
  const [upperBannerIndex2, setUpperBannerIndex2] = useState(0);
  const handleSlideChangeUpperBanner2 = (swiper) => {
    setUpperBannerIndex2(swiper.activeIndex);
  };

  // banner type number
  const [bannerType, setBannerType] = useState(null);
  const [allFormat,setAllFormat]=useState(false)

  // console.log(upperBannerIndex);
  // console.log(AllHomeBanner?.typeData5);

  const supportedImageFormats = ["png", "jpg", "jpeg"];

  const isImageFormatSupported = (fileName) => {
    const lowerCaseFileName = fileName.toLowerCase();
    return supportedImageFormats.some((format) => lowerCaseFileName.endsWith(format));
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="white">
              Home Banner &apos;s{" "}
            </MDTypography>
            {/* <MDButton
              variant="gradient"
              color="dark"
              disabled={Loading}
              sx={({ palette: { dark, white, info ,} }) => ({
                color: white.main,
                backgroundColor: dark.main,
                "&:hover": {
                  color: white.main,
                  backgroundColor: dark.main,
                },
              })}
              onClick={() => {
                setIsOpen(true);
                setIsOpenUpdate(false);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp; Create Home Banner
            </MDButton> */}
          </MDBox>

          {/* <SkLoading /> */}
        </MDBox>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MDBox
            mx={2}
            mt={3}
            py={1.5}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="white">
              Upper Banner Slider{" "}
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( image size - 1519 × 443 px )
              </MDTypography>
            </MDTypography>
            <MDButton
              variant="gradient"
              color="dark"
              disabled={Loading}
              sx={({ palette: { dark, white, info } }) => ({
                color: white.main,
                backgroundColor: dark.main,
                "&:hover": {
                  color: white.main,
                  backgroundColor: dark.main,
                },
              })}
              onClick={() => {
                setIsOpen(true);
                setIsOpenUpdate(false);
                setIsSubtitle(false);
                setBannerType(1);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp; Create Upper Banner
            </MDButton>
          </MDBox>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", my: 4, width: "100%", px: 2.5 }}
          >
            <MDTypography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                width: "75%",
              }}
              color="white"
            >
              {AllHomeBanner?.typeData1 && AllHomeBanner?.typeData1[upperBannerIndex]?.title}
            </MDTypography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isAdmin?.permissions?.all ? (
                <MDButton
                  variant="gradient"
                  color="dark"
                  sx={({ palette: { dark, white, info, success } }) => ({
                    color: white.main,
                    backgroundColor: white.main,
                    width: { sm: "9rem" },
                    "&:hover": {
                      color: success.main,
                      backgroundColor: success.main,
                    },
                    mx: 1,
                  })}
                  onClick={() => {
                    setIsOpen(true);
                    setIsOpenUpdate(true);
                    setIsSubtitle(false);
                    setAllFormat(false);
                    dispatch(getSingleHomeBanner(AllHomeBanner?.typeData1[upperBannerIndex]));
                    dispatch(getAllHomeBanner(`/getAllBanner`));
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update ({upperBannerIndex + 1})
                </MDButton>
              ) : (
                isAdmin?.permissions?.banner.includes("UPDATE") && (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    sx={({ palette: { dark, white, info, success } }) => ({
                      color: white.main,
                      backgroundColor: white.main,
                      width: { sm: "9rem" },
                      "&:hover": {
                        color: success.main,
                        backgroundColor: success.main,
                      },
                      mx: 1,
                    })}
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(true);
                      setIsSubtitle(false);
                      setAllFormat(false);
                      dispatch(getSingleHomeBanner(AllHomeBanner?.typeData1[upperBannerIndex]));
                      dispatch(getAllHomeBanner(`/getAllBanner`));
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                  </MDButton>
                )
              )}
              <MDButton
                variant="gradient"
                color="dark"
                sx={({ palette: { dark, white, info, error } }) => ({
                  color: white.main,
                  backgroundColor: white.main,
                  width: { sm: "8rem" },
                  "&:hover": {
                    color: error.main,
                    backgroundColor: error.main,
                  },
                  mx: 1,
                })}
                onClick={() => {
                  setIsOpenDialog((Preview) => ({
                    ...Preview,
                    open: true,
                    isId: AllHomeBanner?.typeData1[upperBannerIndex]?._id,
                  }));
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>&nbsp; Delete
              </MDButton>
            </Box>
          </Box>
          <Box sx={{ px: 2.5 }}>
            <SwiperSlider onSlideChange={handleSlideChangeUpperBanner} items={1}>
              {Loading ? (
                <SkLoading />
              ) : (
                AllHomeBanner?.typeData1 &&
                AllHomeBanner?.typeData1.length > 0 &&
                AllHomeBanner?.typeData1.map((value, index) => (
                  <SwiperSlide key={index}>
                    <MDBox // sx={{ height: "20rem", width: "100%" }}
                      sx={({ breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          height: "10rem",
                          width: "100%",
                        },
                        [breakpoints.up("sm")]: {
                          height: "10rem",
                          width: "100%",
                        },
                        [breakpoints.up("md")]: {
                          height: "20rem",
                          width: "100%",
                        },
                        [breakpoints.up("lg")]: {
                          height: "20rem",
                          width: "100%",
                        },
                      })}
                    >
                      <img
                        src={`${process.env.REACT_APP_URI}/${value?.bannerImage}`}
                        alt="..."
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fill",
                          borderRadius: "5px",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("../../assets/images/no123.jpeg");
                        }}
                      />
                    </MDBox>
                  </SwiperSlide>
                ))
              )}
            </SwiperSlider>
            <Divider />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MDBox
            mx={2}
            mt={3}
            py={1.5}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="white">
              Second Banner Slider{" "}
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( image size - 1519 × 521.4 px )
              </MDTypography>
            </MDTypography>
            <MDButton
              variant="gradient"
              color="dark"
              disabled={Loading}
              sx={({ palette: { dark, white, info } }) => ({
                color: white.main,
                backgroundColor: dark.main,
                "&:hover": {
                  color: white.main,
                  backgroundColor: dark.main,
                },
              })}
              onClick={() => {
                setIsOpen(true);
                setIsOpenUpdate(false);
                setIsSubtitle(false);
                setBannerType(2);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp; Create Second Banner
            </MDButton>
          </MDBox>
          <Box
            sx={{ display: "flex", justifyContent: "space-between", my: 4, width: "100%", px: 2.5 }}
          >
            <MDTypography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                width: "75%",
              }}
              color="white"
            >
              {AllHomeBanner?.typeData2 && AllHomeBanner?.typeData2[upperBannerIndex2]?.title}
            </MDTypography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isAdmin?.permissions?.all ? (
                <MDButton
                  variant="gradient"
                  color="dark"
                  sx={({ palette: { dark, white, info, success } }) => ({
                    color: white.main,
                    backgroundColor: white.main,
                    width: { sm: "9rem" },
                    "&:hover": {
                      color: success.main,
                      backgroundColor: success.main,
                    },
                    mx: 1,
                  })}
                  onClick={() => {
                    setIsOpen(true);
                    setIsOpenUpdate(true);
                    setIsSubtitle(false);
                    setAllFormat(false);
                    dispatch(getSingleHomeBanner(AllHomeBanner?.typeData2[upperBannerIndex2]));
                    dispatch(getAllHomeBanner(`/getAllBanner`));
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update ({upperBannerIndex2 + 1}
                  )
                </MDButton>
              ) : (
                isAdmin?.permissions?.banner.includes("UPDATE") && (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    sx={({ palette: { dark, white, info, success } }) => ({
                      color: white.main,
                      backgroundColor: white.main,
                      width: { sm: "9rem" },
                      "&:hover": {
                        color: success.main,
                        backgroundColor: success.main,
                      },
                      mx: 1,
                    })}
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(true);
                      setIsSubtitle(false);
                      setAllFormat(false);
                      dispatch(getSingleHomeBanner(AllHomeBanner?.typeData2[upperBannerIndex2]));
                      dispatch(getAllHomeBanner(`/getAllBanner`));
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                  </MDButton>
                )
              )}
              <MDButton
                variant="gradient"
                color="dark"
                sx={({ palette: { dark, white, info, error } }) => ({
                  color: white.main,
                  backgroundColor: white.main,
                  width: { sm: "8rem" },
                  "&:hover": {
                    color: error.main,
                    backgroundColor: error.main,
                  },
                  mx: 1,
                })}
                onClick={() => {
                  setIsOpenDialog((Preview) => ({
                    ...Preview,
                    open: true,
                    isId: AllHomeBanner?.typeData2[upperBannerIndex2]?._id,
                  }));
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>&nbsp; Delete
              </MDButton>
            </Box>
          </Box>
          <Box sx={{ px: 2.5 }}>
            <SwiperSlider onSlideChange={handleSlideChangeUpperBanner2} items={1}>
              {Loading ? (
                <SkLoading />
              ) : (
                AllHomeBanner?.typeData2 &&
                AllHomeBanner?.typeData2.length > 0 &&
                AllHomeBanner?.typeData2.map((value, index) => (
                  <SwiperSlide key={index}>
                    <MDBox // sx={{ height: "20rem", width: "100%" }}
                      sx={({ breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          height: "10rem",
                          width: "100%",
                        },
                        [breakpoints.up("sm")]: {
                          height: "10rem",
                          width: "100%",
                        },
                        [breakpoints.up("md")]: {
                          height: "20rem",
                          width: "100%",
                        },
                        [breakpoints.up("lg")]: {
                          height: "20rem",
                          width: "100%",
                        },
                      })}
                    >
                      <img
                        src={`${process.env.REACT_APP_URI}/${value?.bannerImage}`}
                        alt="..."
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fill",
                          borderRadius: "5px",
                        }}
                        onError={(e) => {
                          e.onerror = null;
                          e.target.src = require("../../assets/images/no123.jpeg");
                        }}
                      />
                    </MDBox>
                  </SwiperSlide>
                ))
              )}
            </SwiperSlider>
            <Divider />
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MDBox
            mx={2}
            mt={3}
            py={1.5}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="white">
              Banner Third, Fourth and Last{" "}
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( image size - 1519 × 521.4 px )
              </MDTypography>
            </MDTypography>
            {/* <MDButton
              variant="gradient"
              color="dark"
              disabled={Loading}
              sx={({ palette: { dark, white, info } }) => ({
                color: white.main,
                backgroundColor: dark.main,
                "&:hover": {
                  color: white.main,
                  backgroundColor: dark.main,
                },
              })}
              onClick={() => {
                setIsOpen(true);
                setIsOpenUpdate(false);
              }}
            >
              <Icon sx={{ fontWeight: "bold" }}>add</Icon>
              &nbsp; Create Home Banner
            </MDButton> */}
          </MDBox>
          {AllHomeBanner?.other?.map((ele, i) => (
            <Box sx={{ px: 2.5 }} key={i}>
              <Box sx={{ display: "flex", justifyContent: "space-between", my: 4, width: "100%" }}>
                <MDTypography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    width: "75%",
                  }}
                  color="white"
                >
                  {ele?.title}
                </MDTypography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {isAdmin?.permissions?.all ? (
                    <MDButton
                      variant="gradient"
                      color="dark"
                      sx={({ palette: { dark, white, info, success } }) => ({
                        color: white.main,
                        backgroundColor: white.main,
                        width: { sm: "9rem" },
                        "&:hover": {
                          color: success.main,
                          backgroundColor: success.main,
                        },
                        mx: 2,
                      })}
                      onClick={() => {
                        setIsOpen(true);
                        setIsOpenUpdate(true);
                        setIsSubtitle(false);
                        setAllFormat(false);
                        dispatch(getSingleHomeBanner(ele));
                        dispatch(getAllHomeBanner(`/getAllBanner`));
                      }}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                    </MDButton>
                  ) : (
                    isAdmin?.permissions?.banner.includes("UPDATE") && (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        sx={({ palette: { dark, white, info, success } }) => ({
                          color: white.main,
                          backgroundColor: white.main,
                          width: { sm: "9rem" },
                          "&:hover": {
                            color: success.main,
                            backgroundColor: success.main,
                          },
                          mx: 2,
                        })}
                        onClick={() => {
                          setIsOpen(true);
                          setIsOpenUpdate(true);
                          setIsSubtitle(false);
                          setAllFormat(false);
                          dispatch(getSingleHomeBanner(ele));
                          dispatch(getAllHomeBanner(`/getAllBanner`));
                        }}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                      </MDButton>
                    )
                  )}
                  {/* <MDButton
                    variant="gradient"
                    color="dark"
                    sx={({ palette: { dark, white, info ,error} }) => ({
                      color: white.main,
                      backgroundColor: white.main,
                      "&:hover": {
                        color: error.main,
                        backgroundColor: error.main,
                      },
                      mx:2
                    })}
                    onClick={() => {
                      setIsOpenDialog((Preview) => ({
                        ...Preview,
                        open: true,
                        isId: ele?._id,
                      }));
                    }}
                  >
                      <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                    Delete
                  </MDButton> */}
                </Box>
              </Box>
              <Box sx={{ height: "50vh", width: "100%", marginBottom: "5%" }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${ele?.bannerImage}`}
                  alt={"img"}
                  onError={(e) => {
                    (e.onError = null), (e.target.src = require("../../assets/images/no123.jpeg"));
                  }}
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <MDBox
            mx={2}
            mt={3}
            py={1.5}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="white">
              Banner fifth ( Having Two Image or Video ){" "}
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( image size - 298.86 × 400 px )
              </MDTypography>
            </MDTypography>
          </MDBox>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {AllHomeBanner?.typeData5?.map((ele, i) => (
              <Box sx={{ px: 2.5, width: "100%" }} key={i}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    my: 3,
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <MDTypography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        width: "100%",
                      }}
                      color="white"
                    >
                      {ele?.title}
                    </MDTypography>
                    <MDTypography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical",
                        width: "100%",
                        fontSize: "1rem",
                      }}
                      color="white"
                    >
                      {ele?.subTitle}
                    </MDTypography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        sx={({ palette: { dark, white, info, success } }) => ({
                          color: white.main,
                          backgroundColor: white.main,
                          width: { sm: "9rem" },
                          "&:hover": {
                            color: success.main,
                            backgroundColor: success.main,
                          },
                          mx: 2,
                        })}
                        onClick={() => {
                          setIsOpen(true);
                          setIsOpenUpdate(true);
                          setIsSubtitle(true);
                          dispatch(getSingleHomeBanner(ele));
                          dispatch(getAllHomeBanner(`/getAllBanner`));
                          setAllFormat(true);
                        }}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.banner.includes("UPDATE") && (
                        <MDButton
                          variant="gradient"
                          color="dark"
                          sx={({ palette: { dark, white, info, success } }) => ({
                            color: white.main,
                            backgroundColor: white.main,
                            width: { sm: "9rem" },
                            "&:hover": {
                              color: success.main,
                              backgroundColor: success.main,
                            },
                            mx: 2,
                          })}
                          onClick={() => {
                            setIsOpen(true);
                            setIsOpenUpdate(true);
                            setIsSubtitle(true);
                            dispatch(getSingleHomeBanner(ele));
                            dispatch(getAllHomeBanner(`/getAllBanner`));
                            setAllFormat(true);
                          }}
                        >
                          <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                        </MDButton>
                      )
                    )}
                  </Box>
                </Box>
                {ele?.bannerImage && isImageFormatSupported(ele?.bannerImage) ? (
                  <Box sx={{ height: "60vh", width: "100%", marginBottom: "5%" }}>
                    <img
                      src={`${process.env.REACT_APP_URI}/${ele?.bannerImage}`}
                      alt={"img"}
                      onError={(e) => {
                        (e.onError = null),
                          (e.target.src = require("../../assets/images/no123.jpeg"));
                      }}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                ) : (
                  <Box sx={{ height: "60vh", width: "100%", marginBottom: "5%" }}>
                    <CardMedia
                      component="video"
                      sx={{
                        height: "100%",
                        width: "100%",
                        // height: { xs: "180px", sm: "100px" },
                        // width: { xs: "170px", sm: "220px" },
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                      image={`${process.env.REACT_APP_URI}/${ele?.bannerImage}`}
                      title="wellmarts"
                      autoPlay={false}
                      controls
                      // poster={`${process.env.REACT_APP_URI}/${ele?.bannerImage}`}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../assets/images/no123.jpeg");
                      }}
                    />
                  </Box>
                )}
                <Divider />
              </Box>
            ))}
          </Box>
        </Box>

        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <CreateBranner
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
          setIsOpen={setIsOpen}
          allow={allFormat}
          isOpen={isOpen}
          bannerType={bannerType}
          subTitle={isSubtitle}
        />
        {/* <SingleProduct viewProductId={viewProductId} setViewProductModal={setViewProductModal} /> */}
      </SkModal>
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"90vh"}
        padding={3}
        overflowY={true}
      >
        {/* <SingleBrand /> */}
      </SkModal>
      <SkConfirm
        dialogTitle={"Delete"}
        dialogContent={"Are you sure you want to delete this Banner?"}
        open={isOpenDialog}
        setOpen={setIsOpenDialog}
        dialogAction={
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,

              px: 3,
            }}
          >
            <MDButton
              variant="gradient"
              color="info"
              sx={{ width: "50%" }}
              onClick={() => {
                setIsOpenDialog((Preview) => ({
                  ...Preview,
                  open: false,
                  isId: null,
                }));
              }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              sx={{ width: "50%" }}
              color="error"
              onClick={() => {
                dispatch(
                  deleteHomeBanner(`${process.env.REACT_APP_API}deleteBanner/${isOpenDialog?.isId}`)
                ).then((data) => {
                  dispatch(
                    handleAlert({
                      isOpen: true,
                      type: `${data?.payload?.success ? "success" : "error"}`,
                      msg: data?.payload?.message,
                    })
                  );
                  if (data?.payload?.success) {
                    dispatch(getAllHomeBanner(`/getAllBanner`));
                  }
                });
                setIsOpenDialog((Preview) => ({
                  ...Preview,
                  open: false,
                  isId: null,
                }));
              }}
            >
              yes
            </MDButton>
          </MDBox>
        }
      />
    </>
  );
};

export default HomeBanner;

import { Delete } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import ImagePicker from "components/ApnaUploader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SkEditor from "components/SKeditor/SkEditor";
import SkLoading from "components/SkLoading";
import SwiperSlider from "components/SkSlider/Swiper";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { updateDynamicPage } from "redux/festures/partnerSlice";
import { getPartnerPage } from "redux/festures/partnerSlice";
import { SwiperSlide } from "swiper/react";
import { formattedDateServer } from "Utils/dateFunc";

const DynamicPage = () => {
  const [controller] = useMaterialUIController();
  const dispatch = useDispatch();
  const { darkMode } = controller;
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const { partnerPage, Loading } = useSelector((state) => ({
    ...state.isPartner,
  }));

  // Banner state
  const [banner, setBanner] = useState([]);
  const [bannerShow, setBannerShow] = useState([]);
  // console.log(bannerShow,"bannerShow")

  //   career states
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [addOption, setAddOption] = useState("");
  const [careerOption, setCareerOption] = useState([]);
  const [careerImg, setCarerrImg] = useState("");

  //   Dream job states
  const [dreamTitle, setDreamTitle] = useState("");
  const [addDreamOption, setAddDreamOption] = useState({
    title: "",
    subTitle: "",
  });
  const [dreamOption, setDreamOption] = useState([]);

  //   partership detail section
  const [partnershipTitle, setPartnershipTitle] = useState("");
  const [trainMode, setTrainMode] = useState("");
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [batch, setBatch] = useState("");
  const [upcomingDate, setUpcomingDate] = useState("");

  // benefit state
  const [benefitTitle, setBenefitTitle] = useState("");
  const [addBenefitOption, setAddBenefitOption] = useState("");
  const [benefitOption, setBenefitOption] = useState([]);
  const [benefitImg, setBenefitImg] = useState("");
  const [description, setDescription] = useState("");

  // console.log(partnerPage, "partnerPage");

  useEffect(() => {
    dispatch(getPartnerPage(`${process.env.REACT_APP_API}/getPartnerEnquiry`));
  }, []);

  useEffect(() => {
    setBannerShow(partnerPage?.banner);
    setTitle(partnerPage?.startCareer?.title);
    setSubTitle(partnerPage?.startCareer?.subTitle);
    setCareerOption(partnerPage?.startCareer?.options);
    setDreamTitle(partnerPage?.dreamJob?.title);
    setDreamOption(partnerPage?.dreamJob?.option);
    setPartnershipTitle(partnerPage?.partnerShipDetailsTitle);
    setTrainMode(partnerPage?.partnerShipDetails?.modeofTraining);
    setPrice(partnerPage?.partnerShipDetails?.regularPrice);
    setTime(partnerPage?.partnerShipDetails?.time);
    setOfferPrice(partnerPage?.partnerShipDetails?.offerPrice);
    setDuration(partnerPage?.partnerShipDetails?.duration);
    setBatch(partnerPage?.partnerShipDetails?.batch);
    setUpcomingDate(partnerPage?.partnerShipDetails?.upcomingDate);
    setBenefitTitle(partnerPage?.benefits?.title);
    setBenefitOption(partnerPage?.benefits?.options);
  }, [partnerPage]);

  const handleUpdate = () => {
    const formdata = new FormData();
    formdata.append("id", partnerPage?._id);

    // Banner
    banner && banner?.length > 0 && banner?.map((ele, i) => formdata.append(`banner`, ele));

    // career section
    title && formdata.append("startCareer[title]", title);
    subTitle && formdata.append("startCareer[subTitle]", subTitle);
    careerOption &&
      careerOption?.length > 0 &&
      careerOption?.map((ele, i) => formdata.append(`startCareer[options][${i}]`, ele));
    careerImg && formdata.append("startCareerImage", careerImg);

    //   Dream Job section
    dreamTitle && formdata.append("dreamJob[title]", dreamTitle);
    dreamOption &&
      dreamOption?.length > 0 &&
      dreamOption?.map((ele, i) => formdata.append(`dreamJob[option][${i}][title]`, ele?.title));
    dreamOption &&
      dreamOption?.length > 0 &&
      dreamOption?.map((ele, i) =>
        formdata.append(`dreamJob[option][${i}][subTitle]`, ele?.subTitle)
      );

    // PartnerShip section
    partnershipTitle && formdata.append("partnerShipDetailsTitle", partnershipTitle);
    const formattedStartDate = new Date(upcomingDate).toISOString();
    upcomingDate && formdata.append("partnerShipDetails[upcomingDate]", formattedStartDate);
    duration && formdata.append("partnerShipDetails[duration]", duration);
    trainMode && formdata.append("partnerShipDetails[modeofTraining]", trainMode);
    batch && formdata.append("partnerShipDetails[batch]", batch);
    price && formdata.append("partnerShipDetails[regularPrice]", price);
    time && formdata.append("partnerShipDetails[time]", time);
    offerPrice && formdata.append("partnerShipDetails[offerPrice]", offerPrice);

    // Benefits Section
    benefitTitle && formdata.append("benefits[title]", benefitTitle);
    benefitOption &&
      benefitOption?.length > 0 &&
      benefitOption?.map((ele, i) => formdata.append(`benefits[options][${i}]`, ele));
    benefitImg && formdata.append("benefitsImage", benefitImg);
    description && formdata.append("focusedArea", description);

    dispatch(
      updateDynamicPage({
        url: `${process.env.REACT_APP_API}/createPartnerEnquiry`,
        data: formdata,
      })
    ).then((res) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${res?.payload?.success ? "success" : "error"}`,
          msg: res?.payload?.message,
        })
      );
      if (res?.payload) {
        dispatch(getPartnerPage(`${process.env.REACT_APP_API}/getPartnerEnquiry`));
      }
    });
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Become a partner page
              </MDTypography>
            </MDBox>

            {/* Banner section */}

            <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                [breakpoints.up("xs")]: {
                  flexDirection: "column",
                  px: 1,
                  width: "100%",
                },
                [breakpoints.up("sm")]: {
                  flexDirection: "column",
                  px: 1,
                  width: "100%",
                },
                [breakpoints.up("md")]: {
                  // flexDirection: "row",
                  flexDirection: "column",
                  px: 3,
                  width: "100%",
                },
                [breakpoints.up("lg")]: {
                  // flexDirection: "row",
                  flexDirection: "column",
                  px: 3,
                  width: "100%",
                },

                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2,
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  my: 4,
                  width: "100%",
                  px: 2.5,
                }}
              >
                <MDTypography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                    width: "75%",
                  }}
                  color="white"
                >
                  Banner
                </MDTypography>
                {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  {isAdmin?.permissions?.all ? (
                    <MDButton
                      variant="gradient"
                      color="dark"
                      sx={({ palette: { dark, white, info, success } }) => ({
                        color: white.main,
                        backgroundColor: white.main,
                        width: { sm: "9rem" },
                        "&:hover": {
                          color: success.main,
                          backgroundColor: success.main,
                        },
                        mx: 1,
                      })}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                    </MDButton>
                  ) : (
                    isAdmin?.permissions?.banner.includes("UPDATE") && (
                      <MDButton
                        variant="gradient"
                        color="dark"
                        sx={({ palette: { dark, white, info, success } }) => ({
                          color: white.main,
                          backgroundColor: white.main,
                          width: { sm: "9rem" },
                          "&:hover": {
                            color: success.main,
                            backgroundColor: success.main,
                          },
                          mx: 1,
                        })}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                      </MDButton>
                    )
                  )}
                  <MDButton
                    variant="gradient"
                    color="dark"
                    sx={({ palette: { dark, white, info, error } }) => ({
                      color: white.main,
                      backgroundColor: white.main,
                      width: { sm: "8rem" },
                      "&:hover": {
                        color: error.main,
                        backgroundColor: error.main,
                      },
                      mx: 1,
                    })}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>delete</Icon>&nbsp; Delete
                  </MDButton>
                </Box> */}
              </Box>
              <Box sx={{ width: "100%" }}>
                <SwiperSlider
                  //  onSlideChange={handleSlideChangeUpperBanner}
                  items={1}
                >
                  {Loading ? (
                    <SkLoading />
                  ) : (
                    partnerPage?.banner &&
                    partnerPage?.banner?.length > 0 &&
                    partnerPage?.banner?.map((value, index) => (
                      <SwiperSlide key={index}>
                        <MDBox
                          sx={({ breakpoints }) => ({
                            [breakpoints.up("xs")]: {
                              height: "10rem",
                              width: "100%",
                            },
                            [breakpoints.up("sm")]: {
                              height: "10rem",
                              width: "100%",
                            },
                            [breakpoints.up("md")]: {
                              height: "20rem",
                              width: "100%",
                            },
                            [breakpoints.up("lg")]: {
                              height: "20rem",
                              width: "100%",
                            },
                          })}
                        >
                          <img
                            src={`${process.env.REACT_APP_URI}/${value}`}
                            alt="..."
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "fill",
                              borderRadius: "5px",
                            }}
                            onError={(e) => {
                              e.onerror = null;
                              e.target.src = require("../../assets/images/no123.jpeg");
                            }}
                          />
                        </MDBox>
                      </SwiperSlide>
                    ))
                  )}
                </SwiperSlider>
                <Divider />
              </Box>
              <Box
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  [breakpoints.up("xs")]: {
                    display: "flex",
                    gap: 2,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  },
                  [breakpoints.up("sm")]: {
                    display: "flex",
                    gap: 2,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  },
                  [breakpoints.up("md")]: {
                    display: "flex",
                    gap: 5,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "row",
                  },
                  [breakpoints.up("lg")]: {
                    display: "flex",
                    gap: 5,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "row",
                  },
                })}
              >
                <ImagePicker name="Banner" multiple={true} images={banner} setImages={setBanner} />

                {isAdmin?.permissions?.all ? (
                  <MDButton
                    color={"info"}
                    verdant={"gradient"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUpdate();
                    }}
                  >
                    update
                  </MDButton>
                ) : (
                  isAdmin?.permissions?.company.includes("UPDATE") && (
                    <MDButton
                      color={"info"}
                      verdant={"gradient"}
                      onClick={(e) => {
                        e.preventDefault();
                        handleUpdate();
                      }}
                    >
                      update
                    </MDButton>
                  )
                )}
              </Box>
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "start",
                  width: "100%",
                }}
              >
                {banner?.length === 0 &&
                  bannerShow &&
                  bannerShow?.length &&
                  bannerShow?.map((value, i) => (
                    <div key={i} style={{ display: "flex", alignItems: "flex-start" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "50px",
                          height: "50px",
                          margin: "0 0.5rem",
                        }}
                      >
                        {console.log(value)}
                        <img
                          className="Image"
                          style={{ width: "100%", height: "100%", objectFit: "fill" }}
                          src={`${process.env.REACT_APP_URI}/${value}`}
                        />
                      </span>
                    </div>
                  ))}
              </div> */}
            </MDBox>

            {/* career section */}

            <MDBox py={3}>
              <MDBox
                mx={2}
                mt={3}
                mb={3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Career Section
                </MDTypography>
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  [breakpoints.up("xs")]: {
                    flexDirection: "column",
                    px: 1,
                    width: "100%",
                  },
                  [breakpoints.up("sm")]: {
                    flexDirection: "column",
                    px: 1,
                    width: "100%",
                  },
                  [breakpoints.up("md")]: {
                    // flexDirection: "row",
                    flexDirection: "column",
                    px: 3,
                    width: "100%",
                  },
                  [breakpoints.up("lg")]: {
                    // flexDirection: "row",
                    flexDirection: "column",
                    px: 3,
                    width: "100%",
                  },

                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 2,
                })}
              >
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Title :</MDTypography>
                  <MDTypography variant="h6">{partnerPage?.startCareer?.title}</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Title"
                      fullWidth
                      name="title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Sub Title:</MDTypography>
                  <MDTypography variant="h6">{partnerPage?.startCareer?.subTitle}</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Sub Title"
                      fullWidth
                      name="subTitle"
                      value={subTitle}
                      onChange={(e) => setSubTitle(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Career Options:</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      mb: 2,
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Career Option"
                      fullWidth
                      name="careerOptions"
                      value={addOption}
                      onChange={(e) => setAddOption(e.target.value)}
                    />
                    <MDButton
                      color={"info"}
                      verdant={"gradient"}
                      onClick={() => {
                        if (addOption !== "") {
                          setCareerOption([...careerOption, addOption]);
                          setAddOption("");
                        }
                      }}
                    >
                      Add
                    </MDButton>
                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                  {careerOption?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                    >
                      <MDTypography variant="h6" sx={{ width: "75%" }}>
                        {item}
                      </MDTypography>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          const newOption = [...careerOption];
                          newOption.splice(index, 1);
                          setCareerOption(newOption);
                        }}
                      >
                        <Delete
                          sx={({ palette: { white, info } }) => ({
                            color: darkMode ? white.main : info.main,
                          })}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Career Image :</MDTypography>
                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        height: 220,
                        width: "100%",
                      },
                      [breakpoints.up("sm")]: {
                        height: 220,
                        width: "100%",
                      },
                      [breakpoints.up("md")]: {
                        height: 320,
                        width: "100%",
                      },
                      [breakpoints.up("lg")]: {
                        height: 320,
                        width: "100%",
                      },
                    })}
                  >
                    <img
                      src={`${process.env.REACT_APP_URI}/${partnerPage?.startCareer?.startCareerImage}`}
                      style={{ height: "100%", width: "100%", objectFit: "cover" }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../assets/images/bg-sign-up-cover.jpeg");
                      }}
                    />
                  </MDBox>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <ImagePicker
                      name="Banner"
                      multiple={false}
                      images={careerImg}
                      setImages={setCarerrImg}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
              </MDBox>
            </MDBox>

            {/* Dream job section */}

            <MDBox py={3}>
              <MDBox
                mx={2}
                mt={3}
                mb={3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Dream Job Section
                </MDTypography>
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  [breakpoints.up("xs")]: {
                    flexDirection: "column",
                    px: 1,
                    width: "100%",
                  },
                  [breakpoints.up("sm")]: {
                    flexDirection: "column",
                    px: 1,
                    width: "100%",
                  },
                  [breakpoints.up("md")]: {
                    // flexDirection: "row",
                    flexDirection: "column",
                    px: 3,
                    width: "100%",
                  },
                  [breakpoints.up("lg")]: {
                    // flexDirection: "row",
                    flexDirection: "column",
                    px: 3,
                    width: "100%",
                  },

                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 2,
                })}
              >
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Title :</MDTypography>
                  <MDTypography variant="h6">{partnerPage?.dreamJob?.title}</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Title"
                      fullWidth
                      name="title"
                      value={dreamTitle}
                      onChange={(e) => setDreamTitle(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Career Options:</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      mb: 2,
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Dream Title"
                      fullWidth
                      name="careerOptions"
                      value={addDreamOption?.title}
                      onChange={(e) =>
                        setAddDreamOption({ ...addDreamOption, title: e.target.value })
                      }
                    />
                    <MDInput
                      type="text"
                      placeholder="Dream Subtitle"
                      fullWidth
                      name="careerOptions"
                      value={addDreamOption?.subTitle}
                      onChange={(e) =>
                        setAddDreamOption({ ...addDreamOption, subTitle: e.target.value })
                      }
                    />
                    <MDButton
                      color={"info"}
                      verdant={"gradient"}
                      onClick={() => {
                        if (addDreamOption?.title !== "" && addDreamOption?.subTitle !== "") {
                          setDreamOption([...dreamOption, addDreamOption]);
                          setAddDreamOption({
                            title: "",
                            subTitle: "",
                          });
                        }
                      }}
                    >
                      Add
                    </MDButton>
                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                  {dreamOption?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <MDTypography variant="h6" sx={{ width: "100%" }}>
                          Title :- {item?.title}
                        </MDTypography>
                        <MDTypography variant="h6" sx={{ width: "100%" }}>
                          Subtitle :- {item?.subTitle}
                        </MDTypography>
                      </Box>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          const newOption = [...dreamOption];
                          newOption.splice(index, 1);
                          setDreamOption(newOption);
                        }}
                      >
                        <Delete
                          sx={({ palette: { white, info } }) => ({
                            color: darkMode ? white.main : info.main,
                          })}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </MDBox>
              </MDBox>
            </MDBox>

            {/* Partnership Details */}

            <MDBox py={3}>
              <MDBox
                mx={2}
                mt={3}
                mb={3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Partnership Details Section
                </MDTypography>
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  [breakpoints.up("xs")]: {
                    flexDirection: "column",
                    px: 1,
                    width: "100%",
                  },
                  [breakpoints.up("sm")]: {
                    flexDirection: "column",
                    px: 1,
                    width: "100%",
                  },
                  [breakpoints.up("md")]: {
                    // flexDirection: "row",
                    flexDirection: "column",
                    px: 3,
                    width: "100%",
                  },
                  [breakpoints.up("lg")]: {
                    // flexDirection: "row",
                    flexDirection: "column",
                    px: 3,
                    width: "100%",
                  },

                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 2,
                })}
              >
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Title :</MDTypography>
                  <MDTypography variant="h6">{partnerPage?.partnerShipDetailsTitle}</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Title"
                      fullWidth
                      name="title"
                      value={partnershipTitle}
                      onChange={(e) => setPartnershipTitle(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Mode of Training :</MDTypography>
                  <MDTypography variant="h6">
                    {partnerPage?.partnerShipDetails?.modeofTraining}
                  </MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Training Mode"
                      fullWidth
                      name="mode"
                      value={trainMode}
                      onChange={(e) => setTrainMode(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">
                    Upcoming Date
                    <MDTypography variant="body1" component="span" fontSize={10}>
                      &nbsp; (dd/mm/yyyy)
                    </MDTypography>
                    <MDTypography variant="h6">
                      {partnerPage?.partnerShipDetails?.upcomingDate}
                    </MDTypography>
                  </MDTypography>

                  <Box sx={{ display: "flex", width: "100%", gap: 5 }}>
                    <MDInput
                      placeholder="Upcoming Date"
                      type="date"
                      name="upcomingDate"
                      value={formattedDateServer(new Date(upcomingDate))}
                      onChange={(e) => setUpcomingDate(e.target.value)}
                      inputProps={{
                        min: formattedDateServer(new Date(upcomingDate)),
                      }}
                      fullWidth={true}
                    />
                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Price :</MDTypography>
                  <MDTypography variant="h6">
                    {partnerPage?.partnerShipDetails?.regularPrice}
                  </MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Price"
                      fullWidth
                      name="price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Time :</MDTypography>
                  <MDTypography variant="h6">{partnerPage?.partnerShipDetails?.time}</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Time"
                      fullWidth
                      name="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Offer Price :</MDTypography>
                  <MDTypography variant="h6">
                    {partnerPage?.partnerShipDetails?.offerPrice}
                  </MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Offer Price"
                      fullWidth
                      name="Offer Price"
                      value={offerPrice}
                      onChange={(e) => setOfferPrice(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Duration :</MDTypography>
                  <MDTypography variant="h6">
                    {partnerPage?.partnerShipDetails?.duration}
                  </MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Duration"
                      fullWidth
                      name="duration"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Batch :</MDTypography>
                  <MDTypography variant="h6">{partnerPage?.partnerShipDetails?.batch}</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Batch"
                      fullWidth
                      name="batch"
                      value={batch}
                      onChange={(e) => setBatch(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
              </MDBox>
            </MDBox>

            {/* Benefits section */}

            <MDBox py={3}>
              <MDBox
                mx={2}
                mt={3}
                mb={3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <MDTypography variant="h6" color="white">
                  Benefit Section
                </MDTypography>
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  [breakpoints.up("xs")]: {
                    flexDirection: "column",
                    px: 1,
                    width: "100%",
                  },
                  [breakpoints.up("sm")]: {
                    flexDirection: "column",
                    px: 1,
                    width: "100%",
                  },
                  [breakpoints.up("md")]: {
                    // flexDirection: "row",
                    flexDirection: "column",
                    px: 3,
                    width: "100%",
                  },
                  [breakpoints.up("lg")]: {
                    // flexDirection: "row",
                    flexDirection: "column",
                    px: 3,
                    width: "100%",
                  },

                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 2,
                })}
              >
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Title :</MDTypography>
                  <MDTypography variant="h6">{partnerPage?.benefits?.title}</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Title"
                      fullWidth
                      name="title"
                      value={benefitTitle}
                      onChange={(e) => setBenefitTitle(e.target.value)}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                      width: "100%",
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                      width: "100%",
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                      width: "100%",
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Benefit Options:</MDTypography>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      mb: 2,
                    })}
                  >
                    <MDInput
                      type="text"
                      placeholder="Benefit Option"
                      fullWidth
                      name="benefitOptions"
                      value={addBenefitOption}
                      onChange={(e) => setAddBenefitOption(e.target.value)}
                    />
                    <MDButton
                      color={"info"}
                      verdant={"gradient"}
                      onClick={() => {
                        if (addBenefitOption !== "") {
                          setBenefitOption([...benefitOption, addBenefitOption]);
                          setAddBenefitOption("");
                        }
                      }}
                    >
                      Add
                    </MDButton>
                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                  {benefitOption?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                    >
                      <MDTypography variant="h6" sx={{ width: "75%" }}>
                        {item}
                      </MDTypography>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => {
                          e.preventDefault();
                          const newOption = [...benefitOption];
                          newOption.splice(index, 1);
                          setBenefitOption(newOption);
                        }}
                      >
                        <Delete
                          sx={({ palette: { white, info } }) => ({
                            color: darkMode ? white.main : info.main,
                          })}
                        />
                      </IconButton>
                    </Box>
                  ))}
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Benefits Image :</MDTypography>
                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        height: 220,
                        width: "100%",
                      },
                      [breakpoints.up("sm")]: {
                        height: 220,
                        width: "100%",
                      },
                      [breakpoints.up("md")]: {
                        height: 320,
                        width: "100%",
                      },
                      [breakpoints.up("lg")]: {
                        height: 320,
                        width: "100%",
                      },
                    })}
                  >
                    <img
                      src={`${process.env.REACT_APP_URI}/${partnerPage?.benefits?.benefitsImage}`}
                      style={{ height: "100%", width: "100%", objectFit: "cover" }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../assets/images/bg-sign-up-cover.jpeg");
                      }}
                    />
                  </MDBox>
                  <Box
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      [breakpoints.up("xs")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("sm")]: {
                        display: "flex",
                        gap: 2,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      },
                      [breakpoints.up("md")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                      [breakpoints.up("lg")]: {
                        display: "flex",
                        gap: 5,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "row",
                      },
                    })}
                  >
                    <ImagePicker
                      name="Banner"
                      multiple={false}
                      images={benefitImg}
                      setImages={setBenefitImg}
                    />

                    {isAdmin?.permissions?.all ? (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    ) : (
                      isAdmin?.permissions?.company.includes("UPDATE") && (
                        <MDButton
                          color={"info"}
                          verdant={"gradient"}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdate();
                          }}
                        >
                          update
                        </MDButton>
                      )
                    )}
                  </Box>
                </MDBox>
                <MDBox
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    gap: 1,
                    [breakpoints.up("xs")]: {
                      px: 1,
                    },
                    [breakpoints.up("sm")]: {
                      px: 1,
                    },
                    [breakpoints.up("md")]: {
                      px: 6,
                    },
                    [breakpoints.up("lg")]: {
                      px: 6,
                    },
                    border: 1,
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 2,
                    py: 2,
                  })}
                >
                  <MDTypography variant="h6">Description List :</MDTypography>
                  <SkEditor
                    editorState={description}
                    setEditorState={setDescription}
                    placeholder={"Description"}
                    initialContent={partnerPage?.focusedArea && partnerPage?.focusedArea}
                    content={"description"}
                    isButton={true}
                  />
                  {isAdmin?.permissions?.all ? (
                    <MDButton
                      color={"info"}
                      verdant={"gradient"}
                      onClick={(e) => {
                        e.preventDefault();
                        handleUpdate();
                      }}
                    >
                      update
                    </MDButton>
                  ) : (
                    isAdmin?.permissions?.company.includes("UPDATE") && (
                      <MDButton
                        color={"info"}
                        verdant={"gradient"}
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpdate();
                        }}
                      >
                        update
                      </MDButton>
                    )
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default DynamicPage;

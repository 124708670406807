import { Delete, Input, Visibility } from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import {
  Card,
  Pagination,
  Stack,
  IconButton,
  Tooltip,
  MenuItem,
  Menu,
  FormControl,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormLabel,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { updateUser } from "redux/festures/userSlice";
import { useNavigate } from "react-router-dom";
import { getAllGlobalUsers } from "redux/festures/userSlice";
import { getAllPartner } from "redux/festures/partnerSlice";
import { updateKYC } from "redux/festures/partnerSlice";
import SkModal from "components/SkModal";
import MDButton from "components/MDButton";

function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "partner details", accessor: "partner details", width: "20%" },
    { Header: "Gender/Phone", accessor: "Gender/Phone", width: "20%" },
    { Header: "Agreement Status", accessor: "agreement status", width: "15%" },
    isAdmin?.permissions?.all
      ? {
          Header: "view",
          accessor: "view",
        }
      : isAdmin?.permissions?.subAdmin?.includes("VIEWS") && {
          Header: "view",
          accessor: "view",
        },
  ].filter(Boolean);
}

const Partners = () => {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const resultAllBrands = createAllBrands(isAdmin);
  const [pagess, setPagess] = useState(1);
  const { allPartners, Loading } = useSelector((state) => ({
    ...state.isPartner,
  }));
  const [parterKycId, setPartnerKycId] = useState(null);
  const [status, setStatus] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const handleOpenMenu = ({ event }) => {
    setOpenMenu(event.currentTarget);
  };
  const [openModel, setOpenModel] = useState(false);
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");

  useEffect(() => {
    dispatch(getAllPartner(`${process.env.REACT_APP_API}/getAllPartnerKyc`));
  }, []);

  const updateUserKYC = (status, reasons) => {
    if (parterKycId && status) {
      dispatch(
        updateKYC({
          url: `${process.env.REACT_APP_API}/updateAgreementByAdmin`,
          data: {
            kycProfileId: parterKycId,
            status: status,
            reason: reasons,
          },
        })
      ).then((res) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${res?.payload?.success ? "success" : "error"}`,
            msg: res?.payload?.message,
          })
        );
        if (res?.payload) {
          setOpenModel(false);
          dispatch(getAllPartner(`${process.env.REACT_APP_API}/getAllPartnerKyc`));
          setComment("");
          setReason("");
        }
      });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (comment !== "Other") {
      updateUserKYC("REJECTED", comment);
    } else if (reason?.length > 2) {
      updateUserKYC("REJECTED", reason);
    }
  };

  useEffect(() => {
    if (allPartners && allPartners.length > 0) {
      const temprows =
        allPartners &&
        allPartners?.at(0) &&
        allPartners?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          "partner details": (
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 1,
              }}
            >
              <MDBox sx={{ height: 40, width: 40 }}>
                <img
                  src={`${process.env.REACT_APP_URI}/${value?.idImage}`}
                  alt={"img"}
                  onError={(e) => {
                    (e.onError = null), (e.target.src = require("../../assets/images/no123.jpeg"));
                  }}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography
                  sx={{ fontSize: 12, fontWeight: "medium" }}
                  variant="text"
                  style={{
                    maxWidth: "350px",
                    lineHeight: "20px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Name: {value?.partnerId?.fullName || null}
                </MDTypography>
                <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                  UserId: {value?.partnerId?._id || "N/A"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),
          "Gender/Phone": (
            <>
              {value?.partnerId?.gender && (
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  fontSize={12}
                  ml={1}
                  lineHeight={2}
                >
                  Gender: {value?.partnerId?.gender}
                </MDTypography>
              )}

              {value?.partnerId?.phoneNumber && (
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  fontSize={12}
                  lineHeight={1}
                >
                  Mobile No : {value?.partnerId?.phoneNumber}{" "}
                </MDTypography>
              )}
            </>
          ),
          "agreement status": (
            <>
              <Tooltip title={value?.status || "N/A"}>
                <IconButton
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  color="info"
                  size="small"
                  circular
                  onClick={(e) => {
                    handleOpenMenu({ event: e });
                    setStatus(value?.status);
                    setPartnerKycId(value?._id);
                  }}
                >
                  <MDBadge
                    badgeContent={value?.status}
                    color={
                      (value?.status === "APPROVED" && "success") ||
                      (value?.status === "REJECTED" && "error") ||
                      (value?.status === "PENDING" && "warning")
                    }
                    variant="gradient"
                    size="lg"
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
          view: (
            <>
              <IconButton
                style={{ padding: "0rem" }}
                aria-label="action_edit"
                onClick={() => {
                  navigate(`/partner/partersList/partnerDetails/${value?.partnerId?._id}`);
                }}
              >
                <Visibility
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),
          "block/unblock": (
            <Tooltip title={value?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  dispatch(
                    updateUser({
                      url: `${process.env.REACT_APP_API}/disableUser/${value?._id}/${admin}`,
                    })
                  ).then((data) => {
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );
                    if (data?.payload?.success) {
                      dispatch(
                        getAllGlobalUsers(`${process.env.REACT_APP_API}/getAllSubadmin/${admin}`)
                      );
                    }
                  });
                }}
              >
                {value?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          ),
        }));

      setRowsData(temprows);
    } else {
      setRowsData([]);
    }
  }, [allPartners]);

  return (
    <>
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
      >
        <>
          {status !== "APPROVED" && (
            <MenuItem
              sx={{ border: "1px solid blue", mb: 1 }}
              onClick={() => {
                setOpenMenu(false);
                updateUserKYC("APPROVED");
              }}
            >
              APPROVED
            </MenuItem>
          )}
          {(status !== "PENDING" || status !== "PENDINGFORM") && (
            <MenuItem
              sx={{ border: "1px solid blue", mb: 1 }}
              onClick={() => {
                setOpenMenu(false);
                updateUserKYC("PENDING");
              }}
            >
              PENDING
            </MenuItem>
          )}
          {status !== "REJECTED" && (
            <MenuItem
              sx={{ border: "1px solid blue", mb: 1 }}
              onClick={() => {
                setOpenMenu(false);
                setOpenModel(true);
              }}
            >
              REJECTED
            </MenuItem>
          )}
        </>
      </Menu>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Partner &apos;s Table{" "}
              </MDTypography>
            </MDBox>

            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : allPartners && allPartners.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,

                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={allPartners && allPartners.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />
                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={1}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={openModel}
        unShow={setOpenModel}
        width={{ sx: "100%", md: "50%", xl: "50%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={{
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 5,
            width: "100%",
          }}
        >
          <Card
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 , 10px",
            }}
          >
            <MDTypography variant="h6" py={0.9}>
              Partnership Reject Reason
            </MDTypography>
          </Card>
        </MDBox>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 3,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 3,
            width: "100%",
            my: 2,
          })}
          component="form"
          role="form"
          onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            gap={1}
            width={"90%"}
            justifyContent="flex-start"
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
          >
            <MDTypography variant="h6">Choose the reason</MDTypography>
            <MDBox
              sx={{
                display: "flex",
                width: "100%",
                gap: 3,
                alignItems: "center",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <MDBox sx={{ width: "100%" }}>
                <FormControl sx={{ width: "100%", alignItems: "start" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="Write Your Comments."
                    name="radio-buttons-group"
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      sx={{ display: "flex", justifyContent: "start" }}
                      value="Pan card detail incorrect"
                      control={<Radio />}
                      label="Pan card detail incorrect."
                    />
                    <FormControlLabel
                      sx={{ display: "flex", justifyContent: "start" }}
                      value="Voter/Aadhar/Driving Licence/Passport ID incorrect"
                      control={<Radio />}
                      label="Voter/Aadhar/Driving Licence/Passport ID incorrect."
                    />
                    <FormControlLabel
                      sx={{ display: "flex", justifyContent: "start" }}
                      value="Changed product"
                      control={<Radio />}
                      label="Bank detail did not match."
                    />
                    <FormControlLabel
                      sx={{ display: "flex", justifyContent: "start" }}
                      value="Other"
                      control={<Radio />}
                      label="Other."
                    />
                  </RadioGroup>
                </FormControl>
              </MDBox>
              <MDBox sx={{ display: "flex", width: "100%" }}>
                <>
                  <TextField
                    fullWidth
                    disabled={comment === "Other" ? false : true}
                    id="outlined-multiline-static"
                    placeholder="Write Your Comments at least 5 character."
                    multiline
                    rows={3}
                    type="text"
                    name="reason"
                    inputProps={{
                      minLength: 5,
                    }}
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  />
                </>
              </MDBox>
            </MDBox>
          </MDBox>

          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
              Reject
            </MDButton>
          </MDBox>
        </MDBox>
      </SkModal>
    </>
  );
};

export default Partners;

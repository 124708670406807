import { Close, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  MenuItem,
  Select,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import TableData from "./Table";
import { getCategory } from "redux/festures/categorySlice";
import PCategoriesForm from "./Forms/PCategoriesForm";
import CCategoriesForm from "./Forms/CCategoriesForm";
import SCategoriesFrom from "./Forms/SCategoriesFrom";
import CCategoriesView from "./View/CCategoriesView";
import PCategoriesView from "./View/PCategoriesView";
import SCategoriesView from "./View/SCategoriesView";
import { getSubGlobalCategory } from "redux/festures/categorySlice";
import { getGlobalCategory } from "redux/festures/categorySlice";
import { updateCategory } from "redux/festures/categorySlice";
import { handleCategories } from "redux/festures/categorySlice";
import { getSubCategory } from "redux/festures/categorySlice";
import { getChildCategory } from "redux/festures/categorySlice";

const disableFilter = [
  {
    _id: 0,
    name: "All",
  },
  {
    _id: false,
    name: "Active",
  },
  {
    _id: true,
    name: "Deleted",
  },
];

export default function Category() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  // const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    Loading,
    category,
    subCategory,
    IsLoading,
    subCategoryData,
    createUpdateLoading,
    isPages,
  } = useSelector((state) => ({
    ...state.isCategory,
  }));

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const [isDisable, setIsDisable] = useState(false);
  const [pagess, setPagess] = useState(1);
  const [isOpenForm, setIsOpenForm] = useState({
    childCategories: false,
    subCategories: false,
    parentCategories: false,
  });
  const [isOpenUpdate, setIsOpenUpdate] = useState({
    childCategories: false,
    subCategories: false,
    parentCategories: false,
  });
  const [isOpenView, setIsOpenView] = useState({
    childCategories: false,
    subCategories: false,
    parentCategories: false,
  });
  // const { Loading, city } = useSelector((data) => ({ ...data?.isCity }));
  const [isOpen, setIsOpen] = useState({
    state: false,
    data: null,
  });
  useEffect(() => {
    dispatch(
      getCategory(
        `${process.env.REACT_APP_API}getAllPcategory/${admin}?disable=${
          isDisable === 0 ? "" : isDisable === false ? false : true || ""
        }&page=${pagess || ""}`
      )
    );
  }, [isDisable, pagess]);

  const handleBinSwitch = (value) => {
    dispatch(
      updateCategory({
        url: `${process.env.REACT_APP_API}disableCategory/${value}/${admin}`,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(
          getGlobalCategory(
            `getAllPcategory/${admin}?disable=${
              isDisable === 0 ? "" : isDisable === false ? false : true || ""
            }&page=${pagess || ""}`
          )
        );

        dispatch(
          getSubCategory({
            url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${value}&disable=false`,
          })
        );
      }
    });
  };
  const handleBinSwitchSub = (value) => {
    dispatch(
      updateCategory({
        url: `${process.env.REACT_APP_API}disableCategory/${value}/${admin}`,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(
          getSubCategory({
            url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${value}&disable=false`,
          })
        );
      }
    });
  };
  const handleBinSwitchChild = (value) => {
    dispatch(
      updateCategory({
        url: `${process.env.REACT_APP_API}disableCategory/${value}/${admin}`,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(
          getChildCategory({
            url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${value}&disable=false`,
          })
        );
      }
    });
  };

  const handleChangeShowInHome = (value) => {
    dispatch(
      updateCategory({
        url: `${process.env.REACT_APP_API}showInHomeCategory/${value}/${admin}`,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(
          getGlobalCategory(
            `getAllPcategory/${admin}?disable=${
              isDisable === 0 ? "" : isDisable === false ? false : true || ""
            }&page=${pagess || ""}`
          )
        );
        dispatch(
          getSubCategory({
            url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${value}&disable=false`,
          })
        );
      }
    });
  };
  const handleChangeShowInHomeSub = (value) => {
    dispatch(
      updateCategory({
        url: `${process.env.REACT_APP_API}showInHomeCategory/${value}/${admin}`,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(
          getSubCategory({
            url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${value}&disable=false`,
          })
        );
      }
    });
  };
  const handleChangeShowInHomeChild = (value) => {
    dispatch(
      updateCategory({
        url: `${process.env.REACT_APP_API}showInHomeCategory/${value}/${admin}`,
      })
    ).then((data) => {
      dispatch(
        handleAlert({
          isOpen: true,
          type: `${data?.payload?.success ? "success" : "error"}`,
          msg: data?.payload?.message,
        })
      );
      if (data?.payload?.success) {
        dispatch(
          getChildCategory({
            url: `${process.env.REACT_APP_API}/getAllCategoryWithSubAndChildCategory?pCategory=${value}&disable=false`,
          })
        );
      }
    });
  };
  return (
    <main>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card id="delete-account">
            <MDBox
              p={1.5}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                flexDirection: { xs: "column", sm: "column", md: "row", xl: "row" },
                gap: 2,
              }}
            >
              <MDButton
                variant="gradient"
                // disabled={Loading}
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: darkMode ? info.main : dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: darkMode ? info.main : dark.main,
                  },
                })}
                onClick={() => {
                  setIsOpenForm((prev) => ({ ...prev, parentCategories: true }));
                  setIsOpenUpdate((prev) => ({ ...prev, parentCategories: false }));
                  dispatch(
                    handleCategories({
                      categories: {},
                      type: "",
                    })
                  );
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create Categories
              </MDButton>
              <MDButton
                variant="gradient"
                // disabled={Loading}
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: darkMode ? info.main : dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: darkMode ? info.main : dark.main,
                  },
                })}
                onClick={() => {
                  setIsOpenForm((prev) => ({ ...prev, subCategories: true }));
                  setIsOpenUpdate((prev) => ({ ...prev, subCategories: false }));
                  dispatch(
                    handleCategories({
                      categories: {},
                      type: "",
                    })
                  );
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create Sub Categories
              </MDButton>

              <MDButton
                // disabled={Loading}
                variant="gradient"
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: darkMode ? info.main : dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: darkMode ? info.main : dark.main,
                  },
                })}
                onClick={() => {
                  setIsOpenForm((prev) => ({ ...prev, childCategories: true }));
                  setIsOpenUpdate((prev) => ({ ...prev, childCategories: false }));
                  dispatch(
                    handleCategories({
                      categories: {},
                      type: "",
                    })
                  );
                }}
              >
                <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                &nbsp; Create Child Categories
              </MDButton>
            </MDBox>{" "}
          </Card>
        </MDBox>

        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Categories table{" "}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                p: 0,
              }}
            >
              <MDBox width="23%" display="flex" flexDirection="column" p={2}>
                <MDTypography variant="button"> Categories Visibility </MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={isDisable}
                  onChange={(e) => setIsDisable(e.target.value)}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {disableFilter?.map((ele, i) => (
                    <MenuItem component="option" key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </MDBox>
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : category && category.length > 0 ? (
                <TableData
                  isOpenForm={isOpenForm}
                  setIsOpenForm={setIsOpenForm}
                  isOpenView={isOpenView}
                  setIsOpenView={setIsOpenView}
                  handleBinSwitch={handleBinSwitch}
                  handleChangeShowInHome={handleChangeShowInHome}
                  isOpenUpdate={isOpenUpdate}
                  setIsOpenUpdate={setIsOpenUpdate}
                  handleBinSwitchSub={handleBinSwitchSub}
                  handleChangeShowInHomeSub={handleChangeShowInHomeSub}
                  handleBinSwitchChild={handleBinSwitchChild}
                  handleChangeShowInHomeChild={handleChangeShowInHomeChild}
                />
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
            <MDBox
              sx={{
                mt: 5,
                py: 2,
                // minHeigth: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack spacing={2} direction={"row"}>
                {/*  */}
                <Pagination
                  sx={({ palette: { dark, white, info } }) => ({
                    "&.MuiPaginationItem-text": {
                      color: darkMode ? white.main : dark.main,
                    },
                    "&	.MuiPaginationItem-icon": {
                      color: darkMode ? white.main : dark.main,
                    },
                    "&		.MuiPaginationItem-textInfo": {
                      color: darkMode ? white.main : dark.main,
                    },
                  })}
                  color="info"
                  variant="text"
                  count={isPages}
                  page={pagess}
                  onChange={(e, value) => setPagess(value)}
                />
              </Stack>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      {/* Forms */}
      <SkModal
        show={isOpenForm.parentCategories}
        unShow={(e) => setIsOpenForm((prev) => ({ ...prev, parentCategories: e }))}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <PCategoriesForm
          isOpenForm={isOpenForm}
          setIsOpenForm={setIsOpenForm}
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
        />
      </SkModal>
      <SkModal
        show={isOpenForm.subCategories}
        unShow={(e) => setIsOpenForm((prev) => ({ ...prev, subCategories: e }))}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <SCategoriesFrom
          isOpenForm={isOpenForm}
          setIsOpenForm={setIsOpenForm}
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
        />
      </SkModal>
      <SkModal
        show={isOpenForm.childCategories}
        unShow={(e) => setIsOpenForm((prev) => ({ ...prev, childCategories: e }))}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"95%"}
        // maxHeight={"80%"}
        padding={3}
        overflowY={true}
      >
        <CCategoriesForm
          isOpenForm={isOpenForm}
          setIsOpenForm={setIsOpenForm}
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
        />
      </SkModal>
      {/* View */}
      <SkModal
        show={isOpenView.parentCategories}
        unShow={(e) => setIsOpenView((prev) => ({ ...prev, parentCategories: e }))}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <PCategoriesView isOpenView={isOpenView} setIsOpenView={setIsOpenView} />
      </SkModal>
      <SkModal
        show={isOpenView.subCategories}
        unShow={(e) => setIsOpenView((prev) => ({ ...prev, subCategories: e }))}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <SCategoriesView isOpenView={isOpenView} setIsOpenView={setIsOpenView} />
      </SkModal>
      <SkModal
        show={isOpenView.childCategories}
        unShow={(e) => setIsOpenView((prev) => ({ ...prev, childCategories: e }))}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <CCategoriesView isOpenView={isOpenView} setIsOpenView={setIsOpenView} />
      </SkModal>
    </main>
  );
}

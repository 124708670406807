import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const skCompany = createAsyncThunk("skCompany", async ({ url }) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const isCompany = createSlice({
  name: "isCompany",
  initialState: {
    Loading: false,
    companyData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(skCompany.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(skCompany.fulfilled, (state, action) => {
        state.Loading = false;
        state.companyData = action.payload?.data;
      })
      .addCase(skCompany.rejected, (state, action) => {
        state.Loading = false;
      });
  },
});
export default isCompany.reducer;

import { useEffect, useMemo, useRef, useState } from "react";
import JoditEditor, { Jodit } from "jodit-react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { skCompany } from "redux/festures/isCompany";
import { handleAlert } from "redux/festures/alertSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useMaterialUIController } from "context";

const SkEditor = ({
  // isHTML,
  editorState,
  setEditorState,
  placeholder,
  initialContent,
  content,
  isButton,
}) => {
  const editor = useRef(null);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const [isContent, setIsContent] = useState(initialContent || "");
  // const [content2, setContent2] = useState("");
  // console.log(content, "content");
  // console.log(content2, "content2");

  const config = useMemo(
    () => ({
      // setEditorState(isContent),
      readonly: false,
      uploader: {
        url: `${process.env.REACT_APP_API_EDITOR}`,
        insertImageAsBase64URI: false, // do not insert base64
        imagesExtensions: ["jpg", "png", "jpeg", "gif"],
        filesVariableName: function (t) {
          return "imgs";
        },
        withCredentials: false,
        pathVariableName: "path",
        format: "json",
        method: "POST",
        prepareData: function (formdata) {
          formdata.delete("path");
          formdata.delete("source");

          const formDataForAPI = new FormData();

          // Iterate over the entries of the original FormData
          for (const [key, value] of formdata.entries()) {
            // Append the key-value pairs to the new FormData with updated keys
            formDataForAPI.append(key, value);
            console.log(key, value);
          }

          console.log("formDataForAPI", ...formDataForAPI);

          return formDataForAPI;
        },

        isSuccess: function (e) {
          console.log(e);
          return e?.success;
        },
        getMessage: function (e) {
          console.log(e);
          return e.data.messages && Array.isArray(e.data.messages) ? e?.data.messages.join("") : "";
        },
        process: function (resp) {
          let files = [];
          files.unshift(resp.data);
          return {
            files: resp.data,
            error: resp.msg,
            msg: resp.msg,
          };
        },
        error: function (e) {
          this.j.e.fire("errorMessage", e.message, "error", 4000);
        },
        defaultHandlerSuccess: function (resp) {
          const j = this;
          if (resp.files && resp.files.length) {
            const tagName = "img";
            resp.files.forEach((filename, index) => {
              const elm = j.createInside.element(tagName);
              elm.setAttribute("src", filename);
              j.s.insertImage(elm, null, j.o.imageDefaultWidth);
            });
          }
        },
        defaultHandlerError: function (e) {
          this.j.e.fire("errorMessage", e.message);
        },
        contentType: function (e) {
          return (
            (typeof this.jodit.ownerWindow.FormData === "undefined" || typeof e === "string") &&
            "application/x-www-form-urlencoded; charset=UTF-8"
          );
        },
      },
      // editHTMLDocumentMode: true,
      inline: true,
      toolbarInlineForSelection: true,
      showPlaceholder: true,
      autofocus: true,
      cursorAfterAutofocus: "end",
    }),
    []
  );
  // const editorMy = Jodit.make("#editor");
  const dispatch = useDispatch();
  const handleSubmitForm = async () => {
    const formData = new FormData();
    formData.append(content, editorState);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API}company/updateCompany`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch(
        handleAlert({
          isOpen: true,
          type: response?.data?.success ? "success" : "error",
          msg: response?.data?.message,
        })
      );
      dispatch(skCompany({ url: `/company/getCompany` }));
      // console.log(response.data, "response");
      // Handle success
    } catch (error) {
      // console.log(error, "responseERROR");
      // Handle error
    }
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          margin: "auto",
          padding: "auto",
          marginTop: "20px",
          // backgroundColor
        }}
      >
        <JoditEditor
          ref={editor}
          value={initialContent}
          config={config}
          tabIndex={3} // tabIndex of textarea
          // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => {
            setEditorState(newContent);
          }}
        />
      </div>

      {!isButton && (
        <MDBox
          sx={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", my: 1.5 }}
        >
          {isAdmin?.permissions?.all ? (
            <MDButton color={"info"} verdant={"gradient"} onClick={handleSubmitForm}>
              update
            </MDButton>
          ) : (
            isAdmin?.permissions?.company.includes("UPDATE") && (
              <MDButton color={"info"} verdant={"gradient"} onClick={handleSubmitForm}>
                update
              </MDButton>
            )
          )}
        </MDBox>
      )}
    </>
  );
};

export default SkEditor;
SkEditor.propTypes = {
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  handleSubmitForm: PropTypes.func,
  initialContent: PropTypes.string,
  content: PropTypes.string,
  placeholder: PropTypes.string,
  isButton: PropTypes.bool,
};

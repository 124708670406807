import http from "Utils/api2"

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllContect = createAsyncThunk("getAllTax", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});



const ContectSlice = createSlice({
  name: "ContectSlice",
  initialState: {
    contect: null,
   
    isPages:null,
    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllContect.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllContect.fulfilled, (state, action) => {
        state.Loading = false;
        state.contect = action.payload?.data;
        state.isPages = action.payload?.page;
      })
      .addCase(getAllContect.rejected, (state, action) => {
        state.Loading = false;
      })
    
     
  },
});
export default ContectSlice.reducer;

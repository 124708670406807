import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Card, CircularProgress } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import AstrieskIcon from "components/AstrieskIcon";
import MDButton from "components/MDButton";
import { handleAlert } from "redux/festures/alertSlice";
import { createRange } from "redux/festures/RangeSlice";
import { getAllGlobleRange } from "redux/festures/RangeSlice";
import { isSingleData } from "redux/festures/RangeSlice";
import { updateRange } from "redux/festures/RangeSlice";

const Form = ({ isOpen, isOpenUpdate, setIsOpenUpdate, setIsOpen }) => {
  const { singleRange } = useSelector((state) => ({ ...state?.isRange }));
  const [data, setData] = useState({
    min: "",
    max: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpenUpdate && singleRange) {
      setData((prev) => ({
        ...prev,
        min: singleRange?.min,
        max: singleRange?.max,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        min: "",
        max: "",
      }));
    }
  }, [isOpenUpdate, singleRange, isOpen]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setIsLoading(false);
      if (isOpenUpdate && singleRange) {
        dispatch(
          updateRange({
            url: `${process.env.REACT_APP_API}/updateDiseaseRange/${singleRange?._id}`,
            data: data,
          })
        ).then((data) => {
          // console.log(data, "data");
          dispatch(
            handleAlert({
              isOpen: true,
              type: `${data?.payload?.success ? "success" : "error"}`,
              msg: data.payload.message,
            })
          );
          if (data?.payload.success) {
            dispatch(getAllGlobleRange(`${process.env.REACT_APP_API}/getAllDiseaseRange`));
          }
          setIsOpenUpdate(false);
          setIsOpen(false);
          dispatch(isSingleData(null));
        });
      } else {
        dispatch(
          createRange({
            url: `${process.env.REACT_APP_API}createDiseaseRange`,
            data: data,
          })
        ).then((data) => {
          // console.log(data, "dataCreate");
          dispatch(
            handleAlert({
              isOpen: true,
              type: `${data?.payload?.success ? "success" : "error"}`,
              msg: data.payload.message,
            })
          );
          if (data?.payload.success) {
            dispatch(getAllGlobleRange(`${process.env.REACT_APP_API}/getAllDiseaseRange`));
          }
          setIsOpenUpdate(false);
          setIsOpen(false);
          dispatch(isSingleData(null));
        });
      }
    } catch (e) {
      setIsLoading(false);
      dispatch(
        handleAlert({
          isOpen: true,
          type: "error",
          msg: e?.response?.data?.message,
        })
      );
    }
  };

  const handleForm = (e) => {
    const { name, value } = e.target;
    if (name === "min" || name === "max") {
      setData((prev) => ({
        ...prev,
        [name]: isNaN(parseInt(value[value.length - 1]))
          ? value.length === 1
            ? ""
            : value.slice(0, value.length - 1)
          : value,
      }));
    } else
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
  };
  return (
    <>
      <MDBox
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 2,
          flexDirection: "column",
          gap: 3,
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {isOpenUpdate ? `Update Dosh Range` : "Create Dosh Range"}
          </MDTypography>
        </Card>

        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 3,
            width: "100%",
            flexWrap: "wrap",
            border: 1,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            py: 3,
          })}
          component="form"
          role="form"
          onSubmit={handleSubmitForm}
        >
          <MDBox
            lineHeight={1}
            gap={2}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Min Value <AstrieskIcon />
              <span style={{ fontSize: 12.5 }}>(min value should be greater then 30)</span>
            </MDTypography>
            <MDInput
              disabled={isLoading}
              required={true}
              type="text"
              placeholder="Enter Min Value"
              fullWidth
              name="min"
              value={data?.min}
              onChange={handleForm}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={2}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Max Value <AstrieskIcon />
              <span style={{ fontSize: 12.5 }}>(min value should be less then 100)</span>
            </MDTypography>
            <MDInput
              disabled={isLoading}
              required={true}
              type="text"
              placeholder="Enter Max Value"
              fullWidth
              name="max"
              value={data?.max}
              onChange={handleForm}
            />
          </MDBox>
          <MDBox
            sx={{
              width: "90%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton disabled={isLoading} color={"info"} verdant={"gradient"} type={"submit"}>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : isOpenUpdate ? (
                `Update Dosh Range`
              ) : (
                "Create Dosh Range"
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default Form;
Form.propTypes = {
  isOpenUpdate: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  setIsOpenUpdate: PropTypes.func,
};

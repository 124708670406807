import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getPartnerPage = createAsyncThunk("getPartnerPage", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllPartner = createAsyncThunk("getAllPartner", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getPartnerDetails = createAsyncThunk("getPartnerDetails", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAgreement = createAsyncThunk("getAgreement", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updateKYC = createAsyncThunk("updateKYC", async ({url,data}) => {
  try {
    const res = await http.put(url,data);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const allPartnerTransaction = createAsyncThunk("allPartnerTransaction", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const partnerTransactionList = createAsyncThunk("partnerTransactionList", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getPromotionCommission = createAsyncThunk("getPromotionCommission", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getPincodeCommission = createAsyncThunk("getPincodeCommission", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updateCommission = createAsyncThunk("updateCommission", async ({ url, data }) => {
  try {
    const response = await http.put(url, data, {
      headers: {
        Authorization: localStorage.getItem("token")
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const updateAgreement = createAsyncThunk("updateAgreement", async ({ url, data }) => {
  try {
    const response = await http.put(url, data, {
      headers: {
        "Content-Type": "multipart/form",
        Authorization: localStorage.getItem("token")
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const updateDynamicPage = createAsyncThunk("updateDynamicPage", async ({ url, data }) => {
  try {
    const response = await http.post(url, data, {
      headers: {
        "Content-Type": "multipart/form",
        Authorization: localStorage.getItem("token")
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

const partnerSlice = createSlice({
  name: "partner",
  initialState: {
    allPartners: null,
    partnerDetails: null,
    agreement: null,
    allTransaction:null,
    Loading: false,
    promotion:null,
    commissionPincode:null,
    partnerTransaction:null,
    partnerPage:null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllPartner.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllPartner.fulfilled, (state, action) => {
        state.Loading = false;
        state.allPartners = action.payload?.data;
      })
      .addCase(getAllPartner.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getPartnerDetails.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getPartnerDetails.fulfilled, (state, action) => {
        state.Loading = false;
        state.partnerDetails = action.payload?.data;
      })
      .addCase(getPartnerDetails.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getAgreement.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAgreement.fulfilled, (state, action) => {
        state.Loading = false;
        state.agreement = action.payload?.data;
      })
      .addCase(getAgreement.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(partnerTransactionList.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(partnerTransactionList.fulfilled, (state, action) => {
        state.Loading = false;
        state.allTransaction = action.payload?.data;
      })
      .addCase(partnerTransactionList.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getPromotionCommission.fulfilled, (state, action) => {
        state.Loading = false;
        state.promotion = action.payload?.data;
      })
      .addCase(getPincodeCommission.fulfilled, (state, action) => {
        state.Loading = false;
        state.commissionPincode = action.payload?.data;
      })
      .addCase(allPartnerTransaction.fulfilled, (state, action) => {
        state.Loading = false;
        state.partnerTransaction= action.payload?.data;
      })
      .addCase(getPartnerPage.fulfilled, (state, action) => {
        state.Loading = false;
        state.partnerPage= action.payload?.data;
      })
  },
});
export default partnerSlice.reducer;

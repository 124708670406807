import PropTypes from "prop-types";
import React from "react";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import { useMaterialUIController } from "context";
import { Card, CardMedia, Divider, Rating, Switch } from "@mui/material";
import { useEffect } from "react";
import http from "Utils/api";
import SkLoading from "components/SkLoading";
import MDBadge from "components/MDBadge";

import { useDispatch, useSelector } from "react-redux";
import { isgetSingleReview } from "redux/festures/reviewSlice";
import { formattedDateServer } from "Utils/dateFunc";
import { updateReview } from "redux/festures/reviewSlice";
import { handleAlert } from "redux/festures/alertSlice";
import MDButton from "components/MDButton";
// import { sanitize } from "dompurify";
import SwiperSlider from "components/SkSlider/Swiper";
import { SwiperSlide } from "swiper/react";
import { SkPrice } from "Utils/dateFunc";

const SingleProduct = ({ single, setViewProductModal }) => {
  const dispatch = useDispatch();
  const admin = localStorage.getItem("admin_id");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // const [single, setSingle] = useState([]);
  const [isUpdate, setIsUpdate] = useState(null);
  const [readMore, setReadMore] = useState(false);
  const [isId, setIsId] = useState(false);
  const [Loading, setLoading] = useState(false);
  const { onLoading, singleReview } = useSelector((state) => ({ ...state.isReview }));
  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     await await http
  //       .get(`/getByIdProduct/${viewProductId}`)
  //       .then((data) => {
  //         // console.log(data);
  //         setLoading(false);
  //         setSingle(data.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setLoading(false);
  //       });
  //   })();
  // }, [viewProductId]);
  // useEffect(() => {
  //   if (viewProductId) {
  //     dispatch(isgetSingleReview(`/getReviewByProductId/${viewProductId}`));
  //   }
  // }, [viewProductId]);

  // console.log(single, "single");

  const supportedImageFormats = ["png", "jpg", "jpeg"];

  const isImageFormatSupported = (fileName) => {
    const lowerCaseFileName = fileName.toLowerCase();
    return supportedImageFormats.some((format) => lowerCaseFileName.endsWith(format));
  };

  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" sx={{ m: 3 }}>
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            SingleProduct details
          </MDTypography>
        </Card>

        {/* <MDTypography variant="h6">{viewProductId}</MDTypography> */}
        {/* <MDTypography variant="h6">{setViewProductModal}</MDTypography> */}
      </MDBox>
      {Loading ? (
        <SkLoading />
      ) : (
        single && (
          <MDBox
            sx={({ palette: { dark, white, info } }) => ({
              border: 0.5,
              borderColor: darkMode ? white.main : dark.main,
              borderRadius: 3,
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              p: 2,
              flexDirection: "column",
              gap: 2.5,
            })}
          >
            <MDBox
              display="flex"
              justifyContent="flex-start"
              gap={1}
              px={1}
              alignItems="flex-start"
              width={"100%"}
              height={"100%"}
              flexDirection="column"
            >
              {single?.name && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="flex-start"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Product Name :</MDTypography>
                  <MDTypography
                    variant="h6"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      "-webkit-line-clamp": 2,
                      "-webkit-box-orient": "vertical",
                      lineHeight: "30px",
                      maxWidth: "70%",
                    }}
                  >
                    {single?.name || "-"}
                  </MDTypography>
                </MDBox>
              )}
              {single?.subtitle && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="flex-start"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Product SubTitle :</MDTypography>
                  <MDTypography
                    variant="h6"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      "-webkit-line-clamp": 2,
                      "-webkit-box-orient": "vertical",
                      lineHeight: "30px",
                      maxWidth: "70%",
                    }}
                  >
                    {single?.subtitle}
                  </MDTypography>
                </MDBox>
              )}

              {single?.thumbnail && (
                <>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                    image
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Product Thumbnail"
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "15rem",
                      p: 0.5,
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_URI}/${single?.thumbnail}`}
                      alt={single?.name}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../assets/images/no123.jpeg");
                      }}
                    />
                  </MDBox>
                </>
              )}
              {single?.productImages && single?.productImages?.length ? (
                <>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Product Images"
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "15rem",
                      p: 0.5,
                    }}
                  >
                    <SwiperSlider items={1}>
                      {Loading ? (
                        <SkLoading />
                      ) : (
                        single?.productImages?.map((value, index) => (
                          <SwiperSlide key={index}>
                            <MDBox // sx={{ height: "20rem", width: "100%" }}
                              sx={({ breakpoints }) => ({
                                [breakpoints.up("xs")]: {
                                  height: "10rem",
                                  width: "100%",
                                },
                                [breakpoints.up("sm")]: {
                                  height: "10rem",
                                  width: "100%",
                                },
                                [breakpoints.up("md")]: {
                                  height: "20rem",
                                  width: "100%",
                                },
                                [breakpoints.up("lg")]: {
                                  height: "20rem",
                                  width: "100%",
                                },
                              })}
                            >
                              {value && isImageFormatSupported(value) ? (
                                <img
                                  src={`${process.env.REACT_APP_URI}/${value}`}
                                  alt="..."
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                    borderRadius: "5px",
                                  }}
                                  onError={(e) => {
                                    e.onerror = null;
                                    e.target.src = require("../../assets/images/no123.jpeg");
                                  }}
                                />
                              ) : (
                                <CardMedia
                                  component="video"
                                  sx={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "10px",
                                    objectFit: "cover",
                                  }}
                                  image={`${process.env.REACT_APP_URI}/${value}`}
                                  title="wellmarts"
                                  autoPlay={false}
                                  controls
                                  // poster={`${process.env.REACT_APP_URI}/${ele?.bannerImage}`}
                                  onError={(e) => {
                                    e.onerror = null;
                                    e.target.src = require("../../assets/images/no123.jpeg");
                                  }}
                                />
                              )}
                            </MDBox>
                          </SwiperSlide>
                        ))
                      )}
                    </SwiperSlider>
                  </MDBox>
                </>
              ) : null}
              {single?.productBanner && single?.productBanner?.length ? (
                <>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Product Banners"
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "15rem",
                      p: 0.5,
                    }}
                  >
                    <SwiperSlider items={1}>
                      {Loading ? (
                        <SkLoading />
                      ) : (
                        single?.productBanner?.map((value, index) => (
                          <SwiperSlide key={index}>
                            <MDBox // sx={{ height: "20rem", width: "100%" }}
                              sx={({ breakpoints }) => ({
                                [breakpoints.up("xs")]: {
                                  height: "10rem",
                                  width: "100%",
                                },
                                [breakpoints.up("sm")]: {
                                  height: "10rem",
                                  width: "100%",
                                },
                                [breakpoints.up("md")]: {
                                  height: "20rem",
                                  width: "100%",
                                },
                                [breakpoints.up("lg")]: {
                                  height: "20rem",
                                  width: "100%",
                                },
                              })}
                            >
                              <img
                                src={`${process.env.REACT_APP_URI}/${value}`}
                                alt="..."
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                  borderRadius: "5px",
                                }}
                                onError={(e) => {
                                  e.onerror = null;
                                  e.target.src = require("../../assets/images/no123.jpeg");
                                }}
                              />
                            </MDBox>
                          </SwiperSlide>
                        ))
                      )}
                    </SwiperSlider>
                    {/* <img
                      src={`${process.env.REACT_APP_URI}/${single?.thumnail}`}
                      alt={single?.name}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      onError={(e) => {
                        e.onerror = null;
                        e.target.src = require("../../../assets/images/bg-sign-up-cover.jpeg");
                      }}
                    /> */}
                  </MDBox>
                </>
              ) : null}
            </MDBox>
            <Divider flexItem></Divider>
            <MDBox
              display="flex"
              justifyContent="flex-start"
              gap={1}
              px={1}
              alignItems="flex-start"
              width={"100%"}
              flexDirection={"column"}
            >
              {single?.categoryId?.name && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Category :</MDTypography>
                  <MDTypography variant="h6">{single?.categoryId?.name}</MDTypography>
                </MDBox>
              )}
              {single?.brandId?.name && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Brand :</MDTypography>
                  <MDTypography variant="h6">{single?.brandId?.name}</MDTypography>
                </MDBox>
              )}
              {single?.cityId?.cityName && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">City :</MDTypography>
                  <MDTypography variant="h6">{single?.cityId?.cityName}</MDTypography>
                </MDBox>
              )}
              {single?.taxId && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Tax Id :</MDTypography>
                  <MDTypography variant="h6">{`${single?.taxId}`}</MDTypography>
                </MDBox>
              )}

              {single?.affiliate?.type && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6"> Affiliate type :</MDTypography>

                  <MDBadge
                    badgeContent={single?.affiliate?.type ? "AMOUNT" : "PERSENT"}
                    color={single?.affiliate?.type ? "success" : "error"}
                    variant="gradient"
                    size="md"
                  />
                </MDBox>
              )}

              {single?.affiliate?.affiliate && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Affiliate :</MDTypography>
                  <MDTypography variant="h6">{`${single?.affiliate?.affiliate}`}</MDTypography>
                </MDBox>
              )}

              {single?.multipleVariant && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Multiple Variant :</MDTypography>

                  <MDBadge
                    badgeContent={single?.multipleVariant ? "Yes" : "No"}
                    color={single?.multipleVariant ? "success" : "error"}
                    variant="gradient"
                    size="md"
                  />
                </MDBox>
              )}

              {single?.showInHome && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Show In Home :</MDTypography>

                  <MDBadge
                    badgeContent={single?.showInHome ? "yas" : "No"}
                    color={single?.showInHome ? "success" : "error"}
                    variant="gradient"
                    size="md"
                  />
                </MDBox>
              )}

              {single?.stock?.toString() && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Stock :</MDTypography>
                  <MDTypography variant="h6">{single?.stock}</MDTypography>
                </MDBox>
              )}
              {single?.sold?.toString() && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Sold :</MDTypography>
                  <MDTypography variant="h6">{single?.sold}</MDTypography>
                </MDBox>
              )}
              {single?.averageRating?.toString() && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6"> Average Rating :</MDTypography>
                  <Rating name="read-only" value={single?.averageRating || "N/A"} readOnly />
                </MDBox>
              )}
              {single?.reviewCount?.toString() && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Review Count :</MDTypography>
                  <MDTypography variant="h6">{single?.reviewCount}</MDTypography>
                </MDBox>
              )}

              {/* {single?.price && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Price :</MDTypography>
                  <MDTypography variant="h6">
                    {single?.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </MDTypography>
                </MDBox>
              )} */}
              {single?.mrp && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">MRP :</MDTypography>
                  <MDTypography variant="h6">
                    {single?.mrp.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </MDTypography>
                </MDBox>
              )}
              {single?.warranty && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Warranty :</MDTypography>
                  <MDTypography variant="h6">{single?.warranty || "-"} </MDTypography>
                </MDBox>
              )}
              {single?.priceVariant && single?.priceVariant?.length > 0 && (
                <MDBox width={"100%"}>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Price Variant"
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  {single?.priceVariant &&
                    single?.priceVariant.map((ele, index) => (
                      <>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          gap={2}
                          alignItems="center"
                          width={"100%"}
                        >
                          <MDTypography variant="h6">MRP :</MDTypography>
                          <MDTypography variant="h6">
                            {ele?.mrp.toLocaleString("en-US", {
                              style: "currency",
                              currency: "INR",
                            })}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          gap={2}
                          alignItems="center"
                          width={"100%"}
                        >
                          <MDTypography variant="h6">Discount :</MDTypography>
                          <MDTypography variant="h6">{`${ele?.discount} %`}</MDTypography>
                        </MDBox>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          gap={2}
                          alignItems="center"
                          width={"100%"}
                        >
                          <MDTypography variant="h6">After Tax Value :</MDTypography>
                          <MDTypography variant="h6">{SkPrice(ele?.afterTaxValue)}</MDTypography>
                        </MDBox>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          gap={2}
                          alignItems="center"
                          width={"100%"}
                        >
                          <MDTypography variant="h6">Before Tax Value :</MDTypography>
                          <MDTypography variant="h6">{SkPrice(ele?.beforeTaxValue)}</MDTypography>
                        </MDBox>
                        <MDBox
                          display="flex"
                          justifyContent="space-between"
                          gap={2}
                          alignItems="center"
                          width={"100%"}
                        >
                          <MDTypography variant="h6">Dis In Rupee Of Variant :</MDTypography>
                          <MDTypography variant="h6">
                            {ele?.disInRupeeOfVariant.toLocaleString("en-US", {
                              style: "currency",
                              currency: "INR",
                            })}
                          </MDTypography>
                        </MDBox>

                        <Divider />
                      </>
                    ))}
                </MDBox>
              )}

              {single?.reviewCount !== 0 && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Review :</MDTypography>
                  <MDTypography variant="h6">
                    <Rating
                      sx={{ color: "info" }}
                      name="half-rating-read"
                      defaultValue={single?.reviewCount}
                      precision={0.5}
                      readOnly
                    />
                  </MDTypography>
                </MDBox>
              )}
              {single?.returnInDays && (
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                  width={"100%"}
                >
                  <MDTypography variant="h6">Return In Days :</MDTypography>
                  <MDTypography variant="h6">{single?.returnInDays || "-"} </MDTypography>
                </MDBox>
              )}
              {single?.description && (
                <React.Fragment>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Product description "
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      p: 0.5,
                    }}
                  >
                    <MDTypography
                      variant="h6"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        // "-webkit-line-clamp": 2,
                        "-webkit-box-orient": "vertical",
                        lineHeight: "30px",
                        maxWidth: "90%",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                      dangerouslySetInnerHTML={{ __html: single?.description }}
                    />
                  </MDBox>
                </React.Fragment>
              )}
              {single?.directionForUse && (
                <React.Fragment>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Direction For Use "
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      p: 0.5,
                    }}
                  >
                    <MDTypography
                      variant="h6"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        // "-webkit-line-clamp": 2,
                        "-webkit-box-orient": "vertical",
                        lineHeight: "30px",
                        maxWidth: "90%",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                      dangerouslySetInnerHTML={{ __html: single?.directionForUse }}
                    />
                  </MDBox>
                </React.Fragment>
              )}
              {single?.keyBenefit && (
                <React.Fragment>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Key Benefit"
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      p: 0.5,
                    }}
                  >
                    <MDTypography
                      variant="h6"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        // "-webkit-line-clamp": 2,
                        "-webkit-box-orient": "vertical",
                        lineHeight: "30px",
                        maxWidth: "90%",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                      dangerouslySetInnerHTML={{ __html: single?.keyBenefit }}
                    />
                  </MDBox>
                </React.Fragment>
              )}
              {single?.SafetyInformation && (
                <React.Fragment>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Safety Information"
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      p: 0.5,
                    }}
                  >
                    <MDTypography
                      variant="h6"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        // "-webkit-line-clamp": 2,
                        "-webkit-box-orient": "vertical",
                        lineHeight: "30px",
                        maxWidth: "90%",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                      dangerouslySetInnerHTML={{ __html: single?.SafetyInformation }}
                    />
                  </MDBox>
                </React.Fragment>
              )}
              {single?.otherInformation && (
                <React.Fragment>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent=" Other Information "
                      color="success"
                      variant="gradient"
                      size="lg"
                      sx={{ zIndex: 10, mt: -1.5 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      p: 0.5,
                    }}
                  >
                    <MDTypography
                      variant="h6"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        // "-webkit-line-clamp": 2,
                        "-webkit-box-orient": "vertical",
                        lineHeight: "30px",
                        maxWidth: "90%",
                        justifyContent: "flex-start",
                        display: "flex",
                      }}
                      dangerouslySetInnerHTML={{ __html: single?.otherInformation }}
                    />
                  </MDBox>
                </React.Fragment>
              )}
            </MDBox>
          </MDBox>
        )
      )}
    </>
  );
};

export default SingleProduct;

SingleProduct.propTypes = {
  //   children: PropTypes.node,
  single: PropTypes.any,
  setViewProductModal: PropTypes.any,
};

import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllOrders = createAsyncThunk("getAllOrders", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleOrders = createAsyncThunk("getSingleOrders", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalOrders = createAsyncThunk("getGlobalOrders", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updateOrderDetails = createAsyncThunk("updateOrderDetails", async ({ url, data }) => {

  try {
    const response = await http.put(url, data);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
const orderSlice = createSlice({
  name: "orderSlice",
  initialState: {
    AllOrders: null,
    singleOrders: null,
    isPages: null,
    Loading: false,
    IsLoading: false,
    isNewOrderId: null,
    isModalStatus: false,
  },
  reducers: {
    handleOrderId: (state, action) => {
      state.isNewOrderId = action.payload.isNewOrderId;
    },
    handleModalStatus: (state, action) => {
      state.isModalStatus = action.payload.isModalStatus;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllOrders.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.Loading = false;
        state.AllOrders = action.payload?.filterData;
        state.isPages = action.payload?.page;
      })
      .addCase(getGlobalOrders.fulfilled, (state, action) => {
        state.Loading = false;
        state.AllOrders = action.payload?.filterData;
        state.isPages = action.payload?.totalPage;
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getSingleOrders.pending, (state, action) => {
        state.IsLoading = true;
      })
      .addCase(getSingleOrders.fulfilled, (state, action) => {
        state.IsLoading = false;
        state.singleOrders = action.payload?.data;
      })
      .addCase(getSingleOrders.rejected, (state, action) => {
        state.IsLoading = false;
      });
  },
});
export const { handleModalStatus, handleOrderId } = orderSlice.actions;
export default orderSlice.reducer;

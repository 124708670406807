import { Delete, Edit, KeyboardArrowUp, KeyboardArrowDown, Visibility } from "@mui/icons-material";
import {
  Card,
  Icon,
  IconButton,
  Collapse,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
} from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";

// import SingleRange from "./SingleRange";

// import Form from "./Form";

import { getAllRange } from "redux/festures/RangeSlice";

import SkConfirm from "components/SkComfirm";

// import Form from "./Form";
import { deleteRange } from "redux/festures/RangeSlice";
import { getAllGlobleRange } from "redux/festures/RangeSlice";
import { isSingleData } from "redux/festures/RangeSlice";
import { isSigleDoshaRange } from "redux/festures/RangeSlice";
import { camelToFlat } from "Utils/dateFunc";
import { getCategory } from "redux/festures/categorySlice";
import { getSubCategory } from "redux/festures/categorySlice";
import ParentCategoriesTable from "./Data/ParentCategories";

export default function TableData({
  isOpenForm,
  setIsOpenForm,
  isOpenView,
  setIsOpenView,
  handleBinSwitch,
  handleChangeShowInHome,
  isOpenUpdate,
  setIsOpenUpdate,
  handleBinSwitchSub,
  handleChangeShowInHomeSub,
  handleBinSwitchChild,
  handleChangeShowInHomeChild,
}) {
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [rIndex, setRIndex] = React.useState(0);
  const {
    Loading,
    category,
    subCategory,
    IsLoading,
    subCategoryData,
    createUpdateLoading,
    isPages,
  } = useSelector((state) => ({
    ...state.isCategory,
  }));
  const dispatch = useDispatch();

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead sx={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell />
              <TableCell
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                  width: 40,
                })}
              >
                S.No
              </TableCell>
              <TableCell
                align="flex-start"
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              >
                Categories Details
              </TableCell>
              <TableCell
                align="center"
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              >
                Show in Home
              </TableCell>
              {isAdmin?.permissions?.all ? (
                <TableCell
                  align="center"
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? white.main : dark.main,
                  })}
                >
                  View
                </TableCell>
              ) : (
                isAdmin?.permissions?.range?.includes("VIEWS") && (
                  <TableCell
                    align="center"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white.main : dark.main,
                    })}
                  >
                    View
                  </TableCell>
                )
              )}
              {isAdmin?.permissions?.all ? (
                <TableCell
                  align="center"
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? white.main : dark.main,
                  })}
                >
                  Delete
                </TableCell>
              ) : (
                isAdmin?.permissions?.range?.includes("DISABLE") && (
                  <TableCell
                    align="center"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white.main : dark.main,
                    })}
                  >
                    Delete
                  </TableCell>
                )
              )}
              {isAdmin?.permissions?.all ? (
                <TableCell
                  align="center"
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? white.main : dark.main,
                  })}
                >
                  Update
                </TableCell>
              ) : (
                isAdmin?.permissions?.range?.includes("UPDATE") && (
                  <TableCell
                    align="center"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white.main : dark.main,
                    })}
                  >
                    Update
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {category &&
              category?.length > 0 &&
              category?.map((items, i) => (
                <ParentCategoriesTable
                  key={i}
                  row={items}
                  index={i}
                  rIndex={rIndex}
                  setRIndex={setRIndex}
                  isOpenForm={isOpenForm}
                  setIsOpenForm={setIsOpenForm}
                  isOpenUpdate={isOpenUpdate}
                  setIsOpenUpdate={setIsOpenUpdate}
                  isOpenView={isOpenView}
                  setIsOpenView={setIsOpenView}
                  handleBinSwitch={handleBinSwitch}
                  handleChangeShowInHome={handleChangeShowInHome}
                  handleBinSwitchSub={handleBinSwitchSub}
                  handleChangeShowInHomeSub={handleChangeShowInHomeSub}
                  handleBinSwitchChild={handleBinSwitchChild}
                  handleChangeShowInHomeChild={handleChangeShowInHomeChild}

                  //   setIsOpenDialog={setIsOpenDialog}
                  //   setIsOpen={setIsOpen}
                  //   setIsOpenUpdate={setIsOpenUpdate}
                  //   setIsOpenView={setIsOpenView}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

TableData.propTypes = {
  isOpenForm: PropTypes.object,
  setIsOpenForm: PropTypes.func,
  isOpenView: PropTypes.object,
  setIsOpenView: PropTypes.func,
  handleBinSwitch: PropTypes.func,
  handleChangeShowInHome: PropTypes.func,
  handleBinSwitchSub: PropTypes.func,
  handleChangeShowInHomeSub: PropTypes.func,
  handleBinSwitchChild: PropTypes.func,
  handleChangeShowInHomeChild: PropTypes.func,
  isOpenUpdate: PropTypes.func,
  setIsOpenUpdate: PropTypes.func,
};

import axios from "axios";
import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getBrands = createAsyncThunk("getBrands", async (url) => {
  try {
    const res = await http.get(url);

    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleBrands = createAsyncThunk("getSingleBrands", async (data) => {
  try {
    return data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalBrands = createAsyncThunk("getGlobalBrands", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateBrands = createAsyncThunk("updateBrands", async ({ url, data }) => {
  try {
    const response = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteBrands = createAsyncThunk("deleteBrands", async ( {url} ) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const createBrands = createAsyncThunk("createBrands", async ({ url, data }) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
const brandSlice = createSlice({
  name: "brandSlice",
  initialState: {
    brands: null,
    singleBrands: null,
    isPages:null,
    Loading: false,
    createUpdateLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrands.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getBrands.fulfilled, (state, action) => {
        state.Loading = false;
        state.brands = action.payload?.data;
        state.isPages = action.payload?.page;
      })
      .addCase(getGlobalBrands.fulfilled, (state, action) => {
        state.brands = action.payload?.data;
        state.isPages = action.payload?.page;
      })
      .addCase(getBrands.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(createBrands.pending, (state, action) => {
        state.createUpdateLoading = true;
      })
      .addCase(createBrands.fulfilled, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(createBrands.rejected, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(updateBrands.pending, (state, action) => {
        state.createUpdateLoading = true;
      })
      .addCase(updateBrands.fulfilled, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(updateBrands.rejected, (state, action) => {
        state.createUpdateLoading = false;
      })
    
      .addCase(getSingleBrands.fulfilled, (state, action) => {
        state.singleBrands = action.payload
      })
     
  },
});
export default brandSlice.reducer;

import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import { Card, CircularProgress } from "@mui/material";
import { useMaterialUIController } from "context";
import AstrieskIcon from "components/AstrieskIcon";
import MDInput from "components/MDInput";
import ApnaSelect2 from "components/ApnaSelect";
import { Cancel } from "@mui/icons-material";
import ImagePicker from "components/ApnaUploader";
import { getSubCategory } from "redux/festures/categorySlice";
import { getSubGlobalCategory } from "redux/festures/categorySlice";
import { getGlobalCategory } from "redux/festures/categorySlice";
import { getSubGlobalCategory2 } from "redux/festures/categorySlice";
import MDButton from "components/MDButton";
import { getEcomCategory } from "redux/festures/categorySlice";
import { creatCategoryData } from "redux/festures/categorySlice";
import { getCategory } from "redux/festures/categorySlice";
import { handleAlert } from "redux/festures/alertSlice";
import { updateCategory } from "redux/festures/categorySlice";

export default function SCategoriesFrom({
  isOpenForm,
  setIsOpenForm,
  isOpenUpdate,
  setIsOpenUpdate,
}) {
  const { isCategories, subCategory2, category2 } = useSelector((state) => ({
    ...state.isCategory,
  }));
  const admin = localStorage.getItem("admin_id");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isImage, setIsImage] = useState("");
  const [isImageServer, setIsImageServer] = useState("");
  const [isName, setIsName] = useState("");
  const [isPCategoriesId, setIsPCategoriesId] = useState("");
  const [fromLoading, setFromLoading] = useState(false);
  const [isCategoriesId, setIsCategoriesId] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getSubGlobalCategory2({
        url: `/getAllSubCategory/${admin}`,
      })
    );
    dispatch(
      getEcomCategory({
        url: `getAllPcategory/${admin}`,
      })
    );
    // dispatch(
    //   getEcomCategory({
    //     url: `getAllCategoryWithPcategory/${admin}`,
    //   })
    // );
  }, [isOpenForm]);
  useEffect(() => {
    if (isCategories?.categories && isOpenUpdate?.subCategories) {
      setIsImageServer(isCategories?.categories?.icon);
      setIsName(isCategories?.categories?.name);
      setIsCategoriesId(isCategories?.categories?.pCategory);
    } else {
      setIsImageServer("");
      setIsName("");
      setIsCategoriesId("");
    }
  }, [isOpenUpdate?.subCategories, isCategories]);
  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();

    formData.append("name", isName);
    formData.append("pCategory", isCategoriesId);
    if (isImage) formData.append("icon", isImage);
    setFromLoading(true);
    if (isOpenUpdate?.subCategories) {
      dispatch(
        updateCategory({
          url: `${process.env.REACT_APP_API}updateCategory/${isCategories?.categories?._id}/${admin}`,
          data: formData,
        })
      ).then((data) => {
        setFromLoading(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        // dispatch(getGlobalCategory(`/getAllCategoryWithPcategory/${admin}`));
        if (data?.payload?.success) {
          dispatch(
            getCategory(`${process.env.REACT_APP_API}getAllPcategory/${admin}?disable=false&page=1`)
          );
          setIsOpenForm((prev) => ({ ...prev, subCategories: false }));
          setIsOpenUpdate((prev) => ({ ...prev, subCategories: false }));
        }
      });
    } else {
      dispatch(
        creatCategoryData({
          url: `${process.env.REACT_APP_API}createCategory/${admin}`,
          reqBody: formData,
        })
      ).then((data) => {
        setFromLoading(false);
        dispatch(
          handleAlert({
            isOpen: true,
            type: data?.payload?.success ? "success" : "error",
            msg: data?.payload?.message,
          })
        );
        if (data?.payload?.success) {
          dispatch(
            getCategory(`${process.env.REACT_APP_API}getAllPcategory/${admin}?disable=false&page=1`)
          );
          setIsOpenForm((prev) => ({ ...prev, subCategories: false }));
          setIsOpenUpdate((prev) => ({ ...prev, subCategories: false }));
        }
      });
    }
  }
  return (
    <div>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {isOpenUpdate?.subCategories ? "Update Sub Categories" : "Create Sub Categories"}
            {isCategories?.categories?.name && (
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( {isCategories?.categories?.name || ""} | {isCategories?.type} )
              </MDTypography>
            )}
          </MDTypography>
        </Card>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 2,
            width: "100%",
            //   height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 3,
          })}
          component="form"
          onSubmit={handleSubmit}
        >
          <MDBox
            lineHeight={1}
            gap={1}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Categories Name <AstrieskIcon />
            </MDTypography>
            <MDInput
              disabled={""}
              required={true}
              type="text"
              placeholder="Categories Name"
              fullWidth
              name="name"
              value={isName}
              onChange={(e) => setIsName(e.target.value)}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={1}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Select Parent Categories
              <AstrieskIcon />
            </MDTypography>

            <ApnaSelect2
              required={true}
              data={category2}
              value={isCategoriesId}
              origin="Parent Categories "
              onChange={(e) => setIsCategoriesId(e.target.value)}
              name="isCategoriesId"
              nameKey={"name"}
              valueKey={"_id"}
              simpleArray={false}
            />
          </MDBox>
          <MDBox
            display="flex"
            flexDirection="column"
            alignItems="center"
            lineHeight={1}
            gap={1}
            width={"90%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Categories Image <AstrieskIcon />
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( image size - 200 × 200 px )
              </MDTypography>
            </MDTypography>
            <ImagePicker
              // disabled={createUpdateLoading}
              required={!isOpenUpdate?.subCategories}
              name="thumbnail"
              multiple={false}
              images={isImage}
              setImages={setIsImage}
              // isImageURLs={isData?.thumbnail}
            />
            {/* {console.log(serverThumbnail, "isThumbnil")} */}

            {!isImage && isImageServer && (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "50px",
                    height: "50px",
                    margin: "0 0.5rem",
                  }}
                >
                  <img
                    className="Image"
                    style={{ width: "100%", height: "100%" }}
                    src={`${process.env.REACT_APP_URI}/${isImageServer}`}
                  />
                </span>
                <span
                  className="cross"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsImageServer(null);
                  }}
                >
                  <Cancel
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white?.main : dark.main,
                    })}
                  />
                </span>
              </div>
            )}
            <MDBox
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                textAlign: "center",
                display: "flex",
              }}
            >
              {" "}
              <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
                {isOpenUpdate?.subCategories ? (
                  fromLoading ? (
                    <Fragment>
                      <CircularProgress color="primary" size={20} /> Updating......
                    </Fragment>
                  ) : (
                    "Update Sub Categories"
                  )
                ) : fromLoading ? (
                  <Fragment>
                    <CircularProgress color="primary" size={20} />
                    Creating......
                  </Fragment>
                ) : (
                  "Create Sub Categories"
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </div>
  );
}
SCategoriesFrom.propTypes = {
  isOpenForm: PropTypes.object,
  setIsOpenForm: PropTypes.func,
  isOpenUpdate: PropTypes.object,
  setIsOpenUpdate: PropTypes.func,
};

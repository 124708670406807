import { Card, Chip, Divider } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { formattedDateServer } from "Utils/dateFunc";
import { SkPrice } from "Utils/dateFunc";

const SingleAffilate = ({ viewData }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  //   const { Loading, singleBlogs } = useSelector((data) => ({ ...data.isBlogs }));
  const { Loading, singleBlogs } = useSelector((data) => ({ ...data?.isBlogs }));
  // console.log(viewData, "asdgagg");

  return (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            Affiliate Users
          </MDTypography>
        </Card>
        {Loading ? (
          <SkLoading />
        ) : (
          <MDBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            sx={({ palette: { dark, white, info } }) => ({
              border: 0.5,
              borderColor: darkMode ? white.main : dark.main,
              borderRadius: 3,
              p: 3,
              width: "100%",
              //   height: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 2,
            })}
          >
            <MDBox
              sx={({ palette: { dark, white, info } }) => ({
                // border: 0.5,
                // borderColor: darkMode ? white.main : dark.main,
                // borderRadius: 3,
                px: 2,
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 1.5,
              })}
            >
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6"> FullName</MDTypography>
                <MDTypography variant="h6">
                  {/* {SkPrice(singleprice?.min) || 0} */}
                  {viewData?.fullName || ""}
                </MDTypography>
              </MDBox>

              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Email</MDTypography>
                <MDTypography variant="h6">
                  {/* {SkPrice(singleprice?.max) || 0} */}
                  {viewData?.email || ""}
                </MDTypography>
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Total Money</MDTypography>

                <MDTypography variant="h6">{SkPrice(viewData?.totalMoney) || "N/A"}</MDTypography>
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">User Id</MDTypography>

                <MDTypography variant="h6">{viewData?.userId || "N/A"}</MDTypography>
              </MDBox>
              <MDBox
              sx={({ palette: { dark, white, info }, breakpoints }) => ({
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "100%",
                gap: 3,
                [breakpoints.up("xs")]: {
                  px: 1,
                },
                [breakpoints.up("sm")]: {
                  px: 1,
                },
                [breakpoints.up("md")]: {
                  px: 6,
                },
                [breakpoints.up("lg")]: {
                  px: 6,
                },
              })}
            >
              <MDTypography variant="h6"> Why You Want Join Affiliate Partener :</MDTypography>

              <MDTypography variant="h6">{viewData?.whyYouWantJoinAffiliatePartener || "N/A"}</MDTypography>
            </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Joining Status :</MDTypography>
                {viewData?.joiningStatus && (
                  <MDBadge
                    badgeContent={viewData?.joiningStatus}
                    color={
                      (viewData?.joiningStatus === "APPROVEL" && "success") ||
                      (viewData?.joiningStatus === "REJECTED" && "error") ||
                      (viewData?.joiningStatus === "PENDING" && "warning")
                    }
                    variant="gradient"
                    size="lg"
                  />
                
                )}
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Status :</MDTypography>
                {viewData?.status && (
                  <MDBadge
                    badgeContent={viewData?.status}
                    color={
                      (viewData?.status === "ACTIVE" && "success") ||
                      (viewData?.status === "SUSPENDED" && "error") ||
                      (viewData?.status === "TERMINATED" && "warning")
                    }
                    variant="gradient"
                    size="lg"
                  />
                
                )}
              </MDBox>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </>
  );
};

export default SingleAffilate;
SingleAffilate.propTypes = {
  viewData: PropTypes.any,
};

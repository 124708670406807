import { Card, Chip, Divider } from "@mui/material";
import { formattedDateServer } from "Utils/dateFunc";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
// import { sanitize } from "dompurify";
import React from "react";
import { useSelector } from "react-redux";

const SingleDisease = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { Loading, disease, singleDisease } = useSelector((state) => ({ ...state?.isDisease }));

  // console.log(singleDisease, "singleDisease");

  return (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            Single Disease
          </MDTypography>
        </Card>
        {Loading ? (
          <SkLoading />
        ) : (
          <MDBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            sx={({ palette: { dark, white, info }, breakpoints }) => ({
              border: 0.5,
              borderColor: darkMode ? white.main : dark.main,
              borderRadius: 3,
              width: "100%",
              //   height: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 2,
              [breakpoints.up("xs")]: {
                py: 3,
              },
              [breakpoints.up("sm")]: {
                py: 3,
              },
              [breakpoints.up("md")]: {
                p: 3,
              },
              [breakpoints.up("lg")]: {
                p: 3,
              },
            })}
          >
            <MDBox
              sx={{
                height: 200,
                width: 200,
                borderRadius: "50%",
                border: 3,
                borderColor: "primary.main",
              }}
            >
              <img
                src={`${process.env.REACT_APP_URI}/${singleDisease?.icon}`}
                style={{ height: "100%", width: "100%", borderRadius: "50%", objectFit: "cover" }}
                onError={(e) => {
                  e.onerror = null;
                  e.target.src = require("../../assets/images/bg-sign-up-cover.jpeg");
                }}
              />
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info } }) => ({
                // border: 0.5,
                // borderColor: darkMode ? white.main : dark.main,
                // borderRadius: 3,
                px: 2,
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 1.5,
              })}
            >
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Dicease Name :</MDTypography>
                <MDTypography
                  variant="h6"
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "70%",
                  }}
                >
                  {singleDisease?.diceaseName}{" "}
                </MDTypography>
              </MDBox>

              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Disable :</MDTypography>
                {singleDisease?.disable ? (
                  <MDBadge badgeContent="Yes" color="success" variant="gradient" size="lg" />
                ) : (
                  <MDBadge badgeContent="No" color="error" variant="gradient" size="lg" />
                )}
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Create At :</MDTypography>
                <MDTypography variant="h6">
                  {formattedDateServer(new Date(singleDisease?.createdAt))}
                </MDTypography>
              </MDBox>
              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">Last Update :</MDTypography>
                <MDTypography variant="h6">
                  {formattedDateServer(new Date(singleDisease?.updatedAt))}
                </MDTypography>
              </MDBox>

              <MDBox
                sx={({ palette: { dark, white, info } }) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderTop: 1,
                  borderTopColor: darkMode ? white.main : info.main,
                  mt: 3,
                })}
              >
                {" "}
                <MDBadge
                  badgeContent="Description"
                  color="success"
                  variant="gradient"
                  size="lg"
                  sx={{ zIndex: 10, mt: -1.5 }}
                />
              </MDBox>

              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  //   justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6"   dangerouslySetInnerHTML={{ __html: singleDisease?.description }}></MDTypography>
              </MDBox>

              <MDBox
                sx={({ palette: { dark, white, info } }) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderTop: 1,
                  borderTopColor: darkMode ? white.main : info.main,
                  mt: 3,
                })}
              >
                {" "}
                <MDBadge
                  badgeContent="Dicease Control Discription"
                  color="success"
                  variant="gradient"
                  size="lg"
                  sx={{ zIndex: 10, mt: -1.5 }}
                />
              </MDBox>

              <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  //   justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6" dangerouslySetInnerHTML={{ __html: singleDisease?.diceaseControlDiscription }}></MDTypography>
              </MDBox>
              {/* <MDBox
                sx={({ palette: { dark, white, info } }) => ({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  borderTop: 1,
                  borderTopColor: darkMode ? white.main : info.main,
                  mt: 3,
                })}
              >
                {" "}
                <MDBadge
                  badgeContent="Dicease Control Discription"
                  color="success"
                  variant="gradient"
                  size="lg"
                  sx={{ zIndex: 10, mt: -1.5 }}
                />
              </MDBox> */}

              {/* <MDBox
                sx={({ palette: { dark, white, info }, breakpoints }) => ({
                  display: "flex",
                  alignItems: "flex-start",
                  //   justifyContent: "space-between",
                  width: "100%",
                  gap: 3,
                  [breakpoints.up("xs")]: {
                    px: 1,
                  },
                  [breakpoints.up("sm")]: {
                    px: 1,
                  },
                  [breakpoints.up("md")]: {
                    px: 6,
                  },
                  [breakpoints.up("lg")]: {
                    px: 6,
                  },
                })}
              >
                <MDTypography variant="h6">{singleDisease?.diceaseControlDiscription}</MDTypography>
              </MDBox> */}

              {singleDisease?.method && singleDisease?.method.length > 0 && (
                <>
                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      borderTop: 1,
                      borderTopColor: darkMode ? white.main : info.main,
                      mt: 3,
                    })}
                  >
                    {" "}
                    <MDBadge
                      badgeContent="Method"
                      color="success"
                      variant="gradient"
                      size="lg"
                      py={2}
                      sx={{ zIndex: 10, mt: -1.5,mb:3 }}
                    />
                  </MDBox>

                  <MDBox
                    sx={({ palette: { dark, white, info } }) => ({
                      display: "flex",
                      // flexDirection: "column",
                      flexWrap: "Wrap",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      gap: 5,
                    })}
                  >
                    {singleDisease?.method &&
                      singleDisease?.method.length > 0 &&
                      singleDisease?.method.map((value) => (
                        <Card key={value?._id} sx={{ minWidth: 300 }}>
                          <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                            <MDBox
                              variant="gradient"
                              bgColor={"success"}
                              color={"success" === "light" ? "dark" : "white"}
                              coloredShadow={"success"}
                              borderRadius="xl"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="4rem"
                              height="4rem"
                              mt={-3}
                            >
                              <img
                                src={`${process.env.REACT_APP_URI}/${value?.image}`}
                                onError={(e) => {
                                  e.onerror = null;
                                  e.target.src = require("../../assets/images/bg-sign-up-cover.jpeg");
                                }}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </MDBox>
                            <MDBox textAlign="right" lineHeight={1.25}>
                              {/* <MDTypography variant="button" fontWeight="light" color="text">
                                {value?.updatedAt &&
                                  `Last Update :${formattedDateServer(new Date(value?.updatedAt))}`}
                              </MDTypography> */}
                              <MDTypography
                                variant="h4"
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  maxWidth: 180,
                                }}
                              >
                                {value?.title}
                              </MDTypography>
                            </MDBox>
                          </MDBox>
                          <Divider />
                          <MDBox pb={2} px={2}>
                            <MDTypography variant="h6">Suggestions:</MDTypography>
                            <MDTypography
                                variant="h6"
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  maxWidth: 180,
                                }}
                                dangerouslySetInnerHTML={{ __html: value?.suggestions }}
                              >
                              </MDTypography>
                         
                          </MDBox>
                        </Card>
                      ))}
                  </MDBox>
                </>
              )}
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </>
  );
};

export default SingleDisease;

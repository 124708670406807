import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Card, IconButton, Pagination, Stack, Tab, Tooltip } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPartnerDetails } from "redux/festures/partnerSlice";
import pdf from "./Vecteezy-License-Information.pdf";
import { partnerTransactionList } from "redux/festures/partnerSlice";
import SkLoading from "components/SkLoading";
import DataTable from "examples/Tables/DataTable";


function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "Customer details", accessor: "User details", width: "20%" },
    { Header: "Transaction Details", accessor: "mobile/email", width: "20%" },
    { Header: "Transaction Type", accessor: "transactionType", width: "20%" },
    { Header: "Status", accessor: "status", width: "10%" },
    { Header: "Amount", accessor: "amount", width: "10%" },
    { Header: "Payment Status", accessor: "payment", width: "20%" },
  ].filter(Boolean);
}

const PartnerDetails = () => {
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const resultAllBrands = createAllBrands(isAdmin);
  const [pagess, setPagess] = useState(1);
  const { partnerDetails } = useSelector((state) => ({
    ...state.isPartner,
  }));
  const { allTransaction, Loading } = useSelector((state) => ({
    ...state.isPartner,
  }));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { id } = useParams();
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // console.log(id,"id")
  useEffect(() => {
    dispatch(
      getPartnerDetails(`${process.env.REACT_APP_API}/getPartnerKycByPartnerUserId?userId=${id}`)
    );
    dispatch(
      partnerTransactionList(
        `${process.env.REACT_APP_API}/getAllpartnerTransactionByPartnerUserId/${id}`
      )
    );
  }, [id]);
  console.log(allTransaction, "allTransaction");

  useEffect(() => {
    if (allTransaction && allTransaction?.length > 0) {
      const temprows =
        allTransaction &&
        allTransaction?.length > 0 &&
        allTransaction?.map((value, index) =>
      ({
            no: (
              <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                {index + 1}
              </MDTypography>
            ),
            "User details": (
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: 1,
                }}
              >
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography
                    sx={{ fontSize: 12, fontWeight: "medium" }}
                    variant="text"
                    style={{
                      maxWidth: "350px",
                      lineHeight: "20px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Name : {value?.orderId?.address?.name || null}
                  </MDTypography>
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    Pincode : {value?.orderId?.address?.pincode || "N/A"}
                  </MDTypography>
                </MDBox>
              </MDBox>
            ),
            "mobile/email": (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography
                    sx={{ fontSize: 12, fontWeight: "medium" }}
                    variant="text"
                    style={{
                      maxWidth: "350px",
                      lineHeight: "20px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    OrderId : {value?.orderId?._id || null}
                  </MDTypography>
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    TransactionId : {value?.transactionId || "N/A"}
                  </MDTypography>
                </MDBox>
              </>
            ),
            transactionType: (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    {value?.type || "N/A"}
                  </MDTypography>
                </MDBox>
              </>
            ),
            status: (
              <>
              <Tooltip title={value?.status || "N/A"}>
                <IconButton
                sx={{cursor:"default"}}
                  variant="gradient"
                  color="info"
                  size="small"
                  circular
                >
                  <MDBadge
                    badgeContent={value?.status}
                    color={
                      (value?.status === "ACTIVE" && "success") ||
                      (value?.status === "SUSPENDED" && "error") ||
                      (value?.status === "PENDING" && "warning")
                    }
                    variant="gradient"
                    size="lg"
                  />
                </IconButton>
              </Tooltip>
              </>
            ),
            amount: (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    {value?.amount ? `₹ ${value?.amount}` : "N/A"}
                  </MDTypography>
                </MDBox>
              </>
            ),
            payment: (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    {value?.paymentConfirmation || "N/A"}
                  </MDTypography>
                </MDBox>
              </>
            ),
          })
        );
      setRowsData(temprows);
    } else {
      setRowsData([]);
    }
  }, [allTransaction]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Partner information
              </MDTypography>
            </MDBox>
            <MDBox py={3}>
              <TabContext value={value}>
                <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Details" value="1" />
                    <Tab label="Documents" value="2" />
                    <Tab label="Agreement" value="3" />
                    <Tab label="Transaction" value="4" />
                  </TabList>
                </MDBox>
                <TabPanel value="1">
                  {" "}
                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                      gap: 1,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                      border: 1,
                      borderColor: darkMode ? white.main : dark.main,
                      borderRadius: 2,
                      py: 2,
                    })}
                  >
                    <MDTypography variant="h6">Partner Details</MDTypography>
                    <MDBox
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        my: 2,
                        gap: 2,
                      }}
                    >
                      <MDBox width={"200px"} height={"200px"}>
                        <img
                          src={`${process.env.REACT_APP_URI}/${partnerDetails?.partnerId?.image}`}
                          onError={(e) => {
                            e.error = null;
                            e.target.src = require("../../assets/images/no123.jpeg");
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "100%",
                          }}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Full Name
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.partnerId?.fullName}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Gender
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.partnerId?.gender}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Mobile No.
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.partnerId?.phoneNumber}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        D.O.B
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.partnerId?.dob}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Wallet Money
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {(partnerDetails && partnerDetails?.partnerId?.walletMoney) || "N/A"}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Verified
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.partnerId?.partnerVerified ? (
                          <MDBadge
                            badgeContent="Yes"
                            color="success"
                            variant="gradient"
                            size="lg"
                          />
                        ) : (
                          <MDBadge badgeContent="No" color="error" variant="gradient" size="lg" />
                        )}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </TabPanel>
                <TabPanel value="2">
                  {" "}
                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                      gap: 1,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                      border: 1,
                      borderColor: darkMode ? white.main : dark.main,
                      borderRadius: 2,
                      py: 2,
                    })}
                  >
                    <MDTypography variant="h6">Bank Details</MDTypography>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Account Holder Name
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.accountHolderName}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Account Number
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.accountNumber}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Confirm Account Number
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.conformAccountNumber}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Branch Name
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.branchName}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        IFSC Code
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.ifscCode}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Pincode
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.pincode}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      <MDTypography fontSize={15}> Pass Book Image </MDTypography>
                      <MDBox width={"100%"} height={"35rem"}>
                        <img
                          src={`${process.env.REACT_APP_URI}/${partnerDetails?.passBookImage}`}
                          onError={(e) => {
                            e.error = null;
                            e.target.src = require("../../assets/images/no123.jpeg");
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "fill",
                            borderRadius: "1rem",
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                      mt: "1rem",
                      gap: 1,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                      border: 1,
                      borderColor: darkMode ? white.main : dark.main,
                      borderRadius: 2,
                      py: 2,
                    })}
                  >
                    <MDTypography variant="h6">PAN Card Details</MDTypography>

                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        Name
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.nameOnPanCard}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        PAN Number
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.panCardNumber}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        mt: 2,
                        gap: 2,
                      }}
                    >
                      <MDBox width={"100%"} height={"35rem"}>
                        <img
                          src={`${process.env.REACT_APP_URI}/${partnerDetails?.panCardImage}`}
                          onError={(e) => {
                            e.error = null;
                            e.target.src = require("../../assets/images/no123.jpeg");
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "fill",
                            borderRadius: "1rem",
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox
                    sx={({ palette: { dark, white, info }, breakpoints }) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "100%",
                      mt: "1rem",
                      gap: 1,
                      [breakpoints.up("xs")]: {
                        px: 1,
                      },
                      [breakpoints.up("sm")]: {
                        px: 1,
                      },
                      [breakpoints.up("md")]: {
                        px: 6,
                      },
                      [breakpoints.up("lg")]: {
                        px: 6,
                      },
                      border: 1,
                      borderColor: darkMode ? white.main : dark.main,
                      borderRadius: 2,
                      py: 2,
                    })}
                  >
                    <MDTypography variant="h6">{partnerDetails?.idType} Card Details</MDTypography>

                    <MDBox
                      sx={({ palette: { dark, white, info }, breakpoints }) => ({
                        [breakpoints.up("xs")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("sm")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 1,
                        },
                        [breakpoints.up("md")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                        [breakpoints.up("lg")]: {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // flexDirection: "column",
                          width: "100%",
                          gap: 1,
                          px: 6,
                        },
                      })}
                    >
                      <MDTypography
                        variant="h6"
                        sx={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        {partnerDetails?.idType} ID
                      </MDTypography>
                      <MDTypography
                        variant="h6"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "wrap",
                          textOverflow: "ellipsis",
                          maxWidth: "70%",
                        }}
                      >
                        {partnerDetails && partnerDetails?.idNuber}
                      </MDTypography>
                    </MDBox>
                    <MDBox
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        mt: 2,
                        gap: 2,
                      }}
                    >
                      <MDBox width={"100%"} height={"35rem"}>
                        <img
                          src={`${process.env.REACT_APP_URI}/${partnerDetails?.idImage}`}
                          onError={(e) => {
                            e.error = null;
                            e.target.src = require("../../assets/images/no123.jpeg");
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "fill",
                            borderRadius: "1rem",
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </TabPanel>
                <TabPanel value="3">
                  {" "}
                  {partnerDetails?.agreementPdf ? (
                    <div>
                      <iframe
                        src={`${process.env.REACT_APP_URI}/${partnerDetails?.agreementPdf}`}
                        width="100%"
                        height="600px"
                      />
                    </div>
                  ) : (
                    <MDBox display="flex" justifyContent="center" gap={2} alignItems="center">
                      <MDTypography variant="h6">No Data Found !</MDTypography>
                    </MDBox>
                  )}
                </TabPanel>
                <TabPanel value="4">
                  <MDBox mb={2}>
                    <Card>
                      <MDBox py={3}>
                        {Loading ? (
                          <SkLoading />
                        ) : allTransaction && allTransaction.length > 0 ? (
                          <>
                            <DataTable
                              table={{
                                columns: resultAllBrands,

                                rows: rowsData,
                              }}
                              isSorted={false}
                              entriesPerPage={false}
                              isPages={allTransaction && allTransaction?.length}
                              noEndBorder
                              canSearch={false}
                              showTotalEntries={false}
                              pagination={false}
                              isPagination={false}
                            />
                            <MDBox
                              sx={{
                                mt: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Stack spacing={2} direction={"row"}>
                                <Pagination
                                  sx={({ palette: { dark, white, info } }) => ({
                                    "&.MuiPaginationItem-text": {
                                      color: darkMode ? white.main : dark.main,
                                    },
                                    "&	.MuiPaginationItem-icon": {
                                      color: darkMode ? white.main : dark.main,
                                    },
                                    "&		.MuiPaginationItem-textInfo": {
                                      color: darkMode ? white.main : dark.main,
                                    },
                                  })}
                                  color="info"
                                  variant="text"
                                  count={1}
                                  page={pagess}
                                  onChange={(e, value) => setPagess(value)}
                                />
                              </Stack>
                            </MDBox>
                          </>
                        ) : (
                          <MDBox display="flex" justifyContent="center" gap={2} alignItems="center">
                            <MDTypography variant="h6">No Data Found !</MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                    </Card>
                  </MDBox>
                </TabPanel>
              </TabContext>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default PartnerDetails;

import { Card, Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { partnerTransactionList } from "redux/festures/partnerSlice";

function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "Partner details", accessor: "Partner details", width: "20%" },
    { Header: "Transaction Details", accessor: "mobile/email", width: "20%" },
    { Header: "Transaction Type", accessor: "transactionType", width: "20%" },
    { Header: "Amount", accessor: "amount", width: "20%" },
    { Header: "Payment Status", accessor: "payment", width: "20%" },
  ].filter(Boolean);
}

const Transactions = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const resultAllBrands = createAllBrands(isAdmin);
  const [pagess, setPagess] = useState(1);
  const { allTransaction, Loading } = useSelector((state) => ({
    ...state.isPartner,
  }));

  useEffect(() => {
    dispatch(partnerTransactionList(`${process.env.REACT_APP_API}/listOfAllTransactionForAdmin`));
  }, []);

  useEffect(() => {
    if (allTransaction && allTransaction?.length > 0) {
      const temprows =
        allTransaction &&
        allTransaction?.length > 0 &&
        allTransaction?.map((value, index) =>
          value?.getAllTransaction?.map((item, i) => ({
            no: (
              <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                {index + 1}
              </MDTypography>
            ),
            "Partner details": (
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: 1,
                }}
              >
                <MDBox sx={{ height: 40, width: 40 }}>
                  <img
                    src={`${process.env.REACT_APP_URI}/${value?.partner?.image}`}
                    alt={"img"}
                    onError={(e) => {
                      (e.onError = null),
                        (e.target.src = require("../../assets/images/no123.jpeg"));
                    }}
                    style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                  />
                </MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography
                    sx={{ fontSize: 12, fontWeight: "medium" }}
                    variant="text"
                    style={{
                      maxWidth: "350px",
                      lineHeight: "20px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Name: {value?.partner?.fullName || null}
                  </MDTypography>
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    partnerId: {value?.partner?.partnerId || "N/A"}
                  </MDTypography>
                </MDBox>
              </MDBox>
            ),
            "mobile/email": (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography
                    sx={{ fontSize: 12, fontWeight: "medium" }}
                    variant="text"
                    style={{
                      maxWidth: "350px",
                      lineHeight: "20px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    OrderId : {item?.orderId || null}
                  </MDTypography>
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    TransactionId: {item?.transactionId || "N/A"}
                  </MDTypography>
                </MDBox>
              </>
            ),
            transactionType: (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    {item?.type || "N/A"}
                  </MDTypography>
                </MDBox>
              </>
            ),
            amount: (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    {item?.amount ? `₹ ${item?.amount}` : "N/A"}
                  </MDTypography>
                </MDBox>
              </>
            ),
            payment: (
              <>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                    {item?.paymentConfirmation || "N/A"}
                  </MDTypography>
                </MDBox>
              </>
            ),
          }))
        );
      const arr = [...temprows];
      setRowsData(...arr);
    } else {
      setRowsData([]);
    }
  }, [allTransaction]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                All Transaction &apos;s list{" "}
              </MDTypography>

              <MDBox sx={{ display: "flex", gap: "2%" }}>
                {isAdmin?.permissions?.all && (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    sx={({ palette: { dark, white, info } }) => ({
                      color: white.main,
                      backgroundColor: dark.main,
                      "&:hover": {
                        color: white.main,
                        backgroundColor: dark.main,
                      },
                    })}
                    onClick={() => {
                      dispatch(
                        partnerTransactionList(
                          `${process.env.REACT_APP_API}/listOfAllTransactionForAdmin?type=UPDATE`
                        )
                      );
                    }}
                  >
                    &nbsp; Paid all
                  </MDButton>
                )}
              </MDBox>
            </MDBox>

            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : allTransaction && allTransaction.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,

                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={allTransaction && allTransaction.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />
                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={1}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox display="flex" justifyContent="center" gap={2} alignItems="center">
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
};

export default Transactions;

import Dashboard from "layouts/dashboard";

import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";

import ServiceProducts from "Pages/Product";
import Orders from "Pages/Orders";
import Users from "Pages/Users";
// import Category from "Pages/Category";
import Coupons from "Pages/Coupons";
import {
  Api,
  ColorLens,
  House,
  Layers,
  LocationCity,
  MenuBook,
  Paid,
  People,
  QueryStats,
  RoomPreferences,
  ShoppingCart,
  VideoLibrary,
  AddBusiness,
  ViewCarousel,
  Anchor,
  Assessment,
  Contactless,
  ContactEmergency,
  Feedback,
  ManageAccounts,
  LocalOffer,
  Subscriptions,
  LocalShipping,
  DynamicFeed,
  LibraryBooks,
  Diversity2,
  Article,
  Fireplace,
  AccountBalanceWallet,
  AcUnit,
  Coronavirus,
  SignalCellularAlt,
  AddCard,
  InsertInvitation,
} from "@mui/icons-material";
import Brands from "Pages/Brands";
import BlogPage from "Pages/Blogs";
import TutorialPage from "Pages/Tutorials";
import QueryPages from "Pages/Query";
import HomePage from "Pages/Home";
import Company from "Pages/Company";
import AttributeSection from "Pages/Attributes";
import Transections from "Pages/Transections";
import Membership from "Pages/Membership";
import Tax from "Pages/Tax";
import HomeBanner from "Pages/HomeBanner";
import EHomeBanner from "Pages/EHomeBanner";
import AppBanner from "Pages/AppBanner";
import HomeCategoryCart from "Pages/HomeCategoryCart";
import EHomeCategoryCart from "Pages/EHomeCategoryCart";
import EHomeProduct from "Pages/EHomeProduct";
import HomeProduct from "Pages/HomeProduct";
// import City from "Pages/City";
import PartnerProfile from "Pages/PartnerProfile";
import EcomAppBanner from "Pages/AppBanner/EcommAppBanner";
import SubAdmin from "Pages/SubAdmin";
import Subscribe from "Pages/Subscribe";
import Shiping from "Pages/Shiping";
import Tactimonials from "Pages/Tactimonials";
// import FeedBack from "Pages/FeedBack";
import ContectUs from "Pages/ContectUs";
import PriceRange from "Pages/PriceRange";
import HowitWork from "Pages/HowitWork";
import Affiliate from "Pages/Affiliate";
import Affilatetransaction from "Pages/Affilatetransaction";
import AffilateWedroll from "Pages/AffilateWedroll";
import OfferOnOccasion from "Pages/OfferOnOccasion";
import Disease from "Pages/Disease";
import RangePrice from "Pages/Range";
import Appoinment from "Pages/Appoinment";
import AssessmentQuestions from "Pages/AssessmentQuestions";
import Category from "Pages/Category";
import MemberShipImage from "Pages/Membership/MemberShipImage";
import AllPartners from "Pages/Partners"
import Tranctions from "Pages/Partners/Transactions"
import Agreements from "Pages/Partners/Agreements";
import PartnerDetails from "Pages/Partners/PartnerDetails";
import Commission from "Pages/Partners/Commission";
import DynamicPage from "Pages/Partners/DynamicPage";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "/",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    collapseKey: "product1",
    route: "/products",
    icon: <Icon fontSize="small">storefront</Icon>,
    component: <ServiceProducts />,

    isPermissions: ["product"],
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <ShoppingCart />,
    route: "/orders",
    component: <Orders />,
    isPermissions: ["orders"],
  },
  {
    type: "collapse",
    name: "Brands",
    key: "brands",
    icon: <Api />,
    route: "/brands",
    component: <Brands />,
    isPermissions: ["brand"],
  },

  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/Categories",
    component: <Category />,
    isPermissions: ["categories"],
  },

  {
    type: "collapse",
    name: "Blogs",
    key: "blogs",
    icon: <MenuBook />,
    route: "/blogs",
    component: <BlogPage />,
    isPermissions: ["blogs"],
  },
  {
    type: "collapse",
    name: "Home Section",
    key: "home",
    icon: <House />,
    // isPermissions: ["homeCategoryCart","banner"],
    collapse: [
      {
        type: "collapse",
        name: "Home Category Cart",
        key: "home",
        collapseKey: "HomeCategories1",
        route: "/home/homeCategoryCart",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <HomeCategoryCart />,
        isPermissions: ["homeCategoryCart"],
      },
      {
        type: "collapse",
        name: "Home Banner",
        key: "home",
        collapseKey: "HomeCategories2",
        route: "/home/homeBanner",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <HomeBanner />,
        isPermissions: ["banner"],
      },
      {
        type: "collapse",
        name: "MemberShip Image",
        key: "home",
        // collapseKey: "HomeCategories3",
        route: "/home/membershipImage",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <MemberShipImage />,
        isPermissions: ["banner"],
      },
      {
        type: "collapse",
        name: "How It Work",
        key: "home",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/home/howItWork",
        component: <HowitWork />,
        isPermissions: ["howItWork"],
      },
      {
        type: "collapse",
        name: "Testimonials ",
        key: "home",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/home/testimonials",
        component: <Tactimonials />,
        isPermissions: ["tactimonials"],
      },
      {
        type: "collapse",
        name: "Offer On Occasion",
        key: "home",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/home/OfferOnOccasion",
        component: <OfferOnOccasion />,
        isPermissions: ["OfferOnOccasion"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Utilities",
    key: "Utilities",
    icon: <Anchor />,
    // isPermissions: ["tax"],
    collapse: [
      {
        type: "collapse",
        name: "Tax",
        key: "Utilities",
        collapseKey: "tax",
        route: "/utilities/tax",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <Tax />,
        isPermissions: ["tax"],
      },

      {
        type: "collapse",
        name: "Subscribe",
        key: "Utilities",
        collapseKey: "Subscribe",
        route: "/utilities/subscribe",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <Subscribe />,

        isPermissions: ["subscribe"],
      },

      {
        type: "collapse",
        name: "Shipping",
        key: "Utilities",
        collapseKey: "Utilities3",
        route: "/utilities/shipping",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <Shiping />,

        isPermissions: ["shipping"],
      },

      {
        type: "collapse",
        name: "Coupons",
        key: "Utilities",
        collapseKey: "Utilities4",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/utilities/coupons",
        component: <Coupons />,

        isPermissions: ["coupons"],
      },
      {
        type: "collapse",
        name: "Price Range",
        key: "Utilities",
        collapseKey: "Utilities5",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/utilities/priceRange",
        component: <PriceRange />,
        isPermissions: ["priceRange"],
      },
      {
        type: "collapse",
        name: "Company",
        key: "Utilities",
        collapseKey: "Utilities6",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/utilities/company",
        component: <Company />,
        isPermissions: ["company"],
      },
      {
        type: "collapse",
        name: "Q & A",
        key: "Utilities",
        collapseKey: "Utilities6",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/utilities/q&a",
        component: <AssessmentQuestions />,
        isPermissions: ["q&a"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <ManageAccounts />,
    // isPermissions: ["customers","subAdmin"],
    collapse: [
      {
        type: "collapse",
        name: "Customers",
        key: "users",
        collapseKey: "users1",
        route: "/users/customers",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <Users />,
        isPermissions: ["customers"],
      },

      {
        type: "collapse",
        name: "Sub Admins",
        key: "users",
        collapseKey: "users4",
        route: "/users/subAdmins",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <SubAdmin />,
        isPermissions: ["subAdmin"],
      },
    ],
  },
  {
    type: "collapse",
    name: "Became a partner",
    key: "partner",
    icon: <ManageAccounts />,
    // isPermissions: ["customers","subAdmin"],
    collapse: [
      {
        type: "collapse",
        name: "Became a partner",
        key: "partner",
        collapseKey: "partner1",
        route: "/partner/beingPartner",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <DynamicPage />,
      },
      {
        type: "collapse",
        name: "All Partners",
        key: "partner",
        collapseKey: "partner2",
        route: "/partner/partersList",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <AllPartners />,
      },
      {
        type: "collapse",
        name: "Agreement",
        key: "partner",
        collapseKey: "partner3",
        route: "/partner/agreement",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <Agreements />,
      },
      {
        type: "collapse",
        name: "Transactions",
        key: "partner",
        collapseKey: "partner4",
        route: "/partner/transaction",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <Tranctions />,
      },
      {
        type: "collapse",
        name: "Commision",
        key: "partner",
        collapseKey: "partner5",
        route: "/partner/commission",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        component: <Commission />,
      },
    ],
  },
  {
    type: "route",
    name: "partnerDetails",
    key: "partner",
    icon: <Icon fontSize="small">partnerDetails</Icon>,
    route: "/partner/partersList/partnerDetails/:id",
    component: <PartnerDetails/>,
  },
  {
    type: "collapse",
    name: "Affiliate",
    key: "Affiliate",
    icon: <AcUnit />,
    // isPermissions: ["affiliate"],
    collapse: [
      {
        type: "collapse",
        name: "Affiliate Users",
        key: "Affiliate",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/affiliate/users",
        component: <Affiliate />,
        isPermissions: ["affiliate"],
      },
      {
        type: "collapse",
        name: "Affiliate Transaction",
        key: "Affiliate",
        icon: <Icon fontSize="small">radio_button_checked</Icon>,
        route: "/affiliate/transaction",
        component: <Affilatetransaction />,
        isPermissions: ["affiliate"],
      },

      // {
      //   type: "collapse",
      //   name: "Affiliate Withdrawal",
      //   key: "Affiliate",
      //   icon: <Icon fontSize="small">radio_button_checked</Icon>,
      //   route: "/affiliate/withdrawal",
      //   component: <AffilateWedroll />,
      //   isPermissions: ["affiliate"],
      // },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Home Category Cart",
  //   key: "Home Category",
  //   collapseKey: "HomeCategories1",
  //   route: "/homeCategoryCart",
  //   icon: <LocationCity />,
  //   component: <HomeCategoryCart />,
  // },

  // {
  //   type: "collapse",
  //   name: "Home Banner",
  //   key: "Home Banner",
  //   collapseKey: "HomeCategories2",
  //   route: "/homesection/homeBanner",
  //   icon: <Fireplace />,
  //   component: <HomeBanner />,
  // },

  // {
  //   type: "collapse",
  //   name: "FeedBack",
  //   key: "feedback",
  //   icon: <Feedback />,
  //   route: "/feedback",
  //   component: <FeedBack />,
  //   isPermissions: ["feedback"],
  // },

  {
    type: "collapse",
    name: "contact Us",
    key: "contact Us",
    route: "/contactUs",
    icon: <Contactless />,
    component: <ContectUs />,
    isPermissions: ["contactUs"],
  },

  {
    type: "collapse",
    name: "Membership",
    key: "Membership",
    icon: <People />,
    route: "/membership",
    component: <Membership />,
    isPermissions: ["membership"],
  },

  {
    type: "collapse",
    name: "Transactions",
    key: "Transactions",
    icon: <AccountBalanceWallet />,
    route: "/transactions",
    component: <Transections />,
    isPermissions: ["transections"],
  },

  {
    type: "collapse",
    name: "Disease",
    key: "Disease",
    icon: <Coronavirus />,
    route: "/disease",
    component: <Disease />,

    isPermissions: ["disease"],
  },

  {
    type: "collapse",
    name: "Range",
    key: "Range",
    icon: <SignalCellularAlt />,
    route: "/Range",
    component: <RangePrice />,

    isPermissions: ["range"],
  },
  {
    type: "collapse",
    name: "Appoinment",
    key: "Appoinment",
    icon: <InsertInvitation />,
    route: "/appoinment",
    component: <Appoinment />,

    isPermissions: ["appoinment"],
  },
  {
    type: "collapse",
    name: "Affiliate Withdrawal",
    key: "Affiliate",
    icon: <AddCard />,
    route: "/affiliate/withdrawal",
    component: <AffilateWedroll />,
    isPermissions: ["affiliate"],
  },

  {
    type: "route",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    isPermissions: ["all"],
  },
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  // {
  //   type: "route",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  // {
  //   type: "route",
  //   name: "Notifications",
  //   key: "Notifications",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/notifications/:id",
  //   component: <Notifications />,
  // },
];

export default routes;

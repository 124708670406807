import { Cancel } from "@mui/icons-material";
import { Card, CircularProgress, FormControlLabel, FormGroup, Switch } from "@mui/material";
import ImagePicker from "components/ApnaUploader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { getBrands } from "redux/festures/brandSlice";

import AstrieskIcon from "components/AstrieskIcon";
import { updateprice } from "redux/festures/PricerangeSlice";
import { createprice } from "redux/festures/PricerangeSlice";
import { getAllprice } from "redux/festures/PricerangeSlice";
import { updateBlog } from "redux/festures/blogSlice";
import { createPostBlogs } from "redux/festures/blogSlice";

const CreateForm = ({ isOpen, isOpenUpdate, setIsOpenUpdate, setIsOpen }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [price, setPrice] = useState({
    min: "",
    max: "",
  });


  const [isShow, setIsShow] = useState("");
  const [isBrandShowHome, setIsBrandShowHome] = useState(false);
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const { Loading, singleprice ,createUpdateLoading} = useSelector((data) => ({ ...data.isPrice }));
  //   console.log(singleprice, "singleprice");
  useEffect(() => {
    if (singleprice && isOpenUpdate) {
      setPrice((prev) => ({
        ...prev,
        min: singleprice?.min,
        max: singleprice?.max,
      }));
    } else {
      setPrice(() => ({
        min: "",
        max: "",
      }));
    }
  }, [singleprice, isOpenUpdate]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (singleprice && isOpenUpdate) {
      dispatch(
        updateprice({
          url: `${process.env.REACT_APP_API}updatePriceRange/${singleprice?._id}/${admin}`,
          data: price,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);
        setIsOpenUpdate(false);

        dispatch(getAllprice(`getAllPriceRange`));
      });
    } else {
      dispatch(
        createprice({
          url: `${process.env.REACT_APP_API}createPriceRange/${admin}`,
          data: price,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);
        setIsOpenUpdate(false);

        dispatch(getAllprice(`getAllPriceRange`));
      });
    }
    // } else {
    //   dispatch(
    //     handleAlert({
    //       isOpen: true,
    //       type: "warning",
    //       msg: "plz fill all input",
    //     })
    //   );
    // }
  };

  return Loading ? (
    <SkLoading />
  ) : (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {isOpenUpdate ? `Update Price Range ` : " Create Price Range"}
          </MDTypography>
        </Card>
        <MDBox
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            width: "100%",
            p: 3,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
          })}
          component="form"
          role="form"
          onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
               <MDTypography variant="h6">
            Min price <AstrieskIcon />
          </MDTypography>
          <MDInput
            required={true}
            disabled={createUpdateLoading}
            type="text"
            placeholder="Min price"
            fullWidth
            name="min"
            value={price?.min}
            onChange={(e) => setPrice({ ...price, min: e.target.value })}
            onInput={(e) => {
              e.target.value = e.target.value
                .replace(/[^0-9]/g, "")
                .slice(0, 10);
            }}
          />
       
          </MDBox>
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
          <MDTypography variant="h6">
            Max price <AstrieskIcon />
          </MDTypography>
          <MDInput
            required={true}
            disabled={createUpdateLoading}
            type="text"
            placeholder="Max price"
            fullWidth
            name="max"
            value={price?.max}
            onChange={(e) => setPrice({ ...price, max: e.target.value })}
            onInput={(e) => {
              e.target.value = e.target.value
                .replace(/[^0-9]/g, "")
                .slice(0, 10);
            }}
          />
        </MDBox>

          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
              {/* <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
            {isOpenUpdate ? `Update price` : ` Create price`}
          </MDButton> */}

<MDButton
              disabled={createUpdateLoading}
              color={"info"}
              verdant={"gradient"}
              type={"submit"}
            >
              {createUpdateLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : isOpenUpdate ? (
                `Update price`
              ) : (
                ` Create price`
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default CreateForm;
CreateForm.propTypes = {
  isOpenUpdate: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.any,
  setIsOpenUpdate: PropTypes.any,
  isOpen: PropTypes.any,
};

import { Cancel } from "@mui/icons-material";
import { Card, CircularProgress, FormControlLabel, FormGroup, Switch } from "@mui/material";
import ImagePicker from "components/ApnaUploader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { getBrands } from "redux/festures/brandSlice";
import { updateBrands } from "redux/festures/brandSlice";
import { createBrands } from "redux/festures/brandSlice";
import AstrieskIcon from "components/AstrieskIcon";
import { getAllQuestions } from "redux/festures/questionAnswerSlice";

const CreateQuestion = ({ isOpen, isOpenUpdate, setIsOpenUpdate, setIsOpen }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [question, setQuestion] = useState("");
  const [ansKapha, setAnsKapha] = useState("");
  const [ansPitta, setAnsPitta] = useState("");
  const [ansVata, setAnsVata] = useState("");
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const { singleQuestion, Loading } = useSelector((state) => ({ ...state?.isQuestion }));

  useEffect(() => {
    if (singleQuestion && isOpenUpdate) {
      setQuestion(singleQuestion?.question);
      setAnsKapha(singleQuestion?.kaphaDoshaAnswer);
      setAnsPitta(singleQuestion?.pittaDoshaAnswer);
      setAnsVata(singleQuestion?.vataDoshaAnswer);
    } else {
      setQuestion("");
      setAnsKapha("");
      setAnsPitta("");
      setAnsVata("");
    }
  }, [singleQuestion, isOpenUpdate, isOpen]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isOpenUpdate) {
      dispatch(
        updateBrands({
          url: `${process.env.REACT_APP_API}doshaUpdate/${singleQuestion?._id}`,
          data: {
            question: question,
            kaphaDoshaAnswer: ansKapha,
            pittaDoshaAnswer: ansPitta,
            vataDoshaAnswer: ansVata,
          },
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);
        setIsOpenUpdate(false);
        setQuestion("");
        setAnsKapha("");
        setAnsPitta("");
        setAnsVata("");

        dispatch(getAllQuestions(`${process.env.REACT_APP_API}/getAllDosha`));
      });
    } else {
      dispatch(
        createBrands({
          url: `${process.env.REACT_APP_API}doshaCreate`,
          data: {
            question: question,
            kaphaDoshaAnswer: ansKapha,
            pittaDoshaAnswer: ansPitta,
            vataDoshaAnswer: ansVata,
          },
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsOpen(false);
        setIsOpenUpdate(false);
        setQuestion("");
        setAnsKapha("");
        setAnsPitta("");
        setAnsVata("");

        dispatch(getAllQuestions(`${process.env.REACT_APP_API}/getAllDosha`));
      });
    }
  };
  
  return Loading ? (
    <SkLoading />
  ) : (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {isOpenUpdate ? `Update Question ` : " Create Question"}
          </MDTypography>
        </Card>
        <MDBox
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            width: "100%",
            p: 3,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
          })}
          component="form"
          role="form"
          onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Question <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Question"
              fullWidth
              name="name"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Answer 1 <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Answer 1"
              fullWidth
              name="name"
              value={ansKapha}
              onChange={(e) => setAnsKapha(e.target.value)}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Answer 2 <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Answer 2"
              fullWidth
              name="name"
              value={ansPitta}
              onChange={(e) => setAnsPitta(e.target.value)}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Answer 3 <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Answer 3"
              fullWidth
              name="name"
              value={ansVata}
              onChange={(e) => setAnsVata(e.target.value)}
            />
          </MDBox>
          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
              {Loading ? (
                <CircularProgress size={20} color="primary" />
              ) : isOpenUpdate ? (
                `Update Question`
              ) : (
                ` Create Question`
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default CreateQuestion;
CreateQuestion.propTypes = {
  isOpenUpdate: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.any,
  setIsOpenUpdate: PropTypes.any,
  isOpen: PropTypes.any,
};

import {
  Close,
  Delete,
  Edit,
  Input,
  NotificationAdd,
  People,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import AstrieskIcon from "components/AstrieskIcon";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { updateUser } from "redux/festures/userSlice";
import { getAllUsers } from "redux/festures/userSlice";
import SingleUserDetails from "./SingleUserDetails";
// import { getAllGlobalUsers } from "redux/festures/userSlice";
import MDInput from "components/MDInput";
import { getSingleUser } from "redux/festures/userSlice";
import { createNotification } from "redux/festures/userSlice";
import Form from "./Form";
import UpdateForm from "./UpdateForm";
import { useLocation } from "react-router-dom";
import { getAllGlobalUsers } from "redux/festures/userSlice";
// const columns = {
//   CUSTOMER: [
//     { Header: "S.No", accessor: "no" },
//     { Header: "user details", accessor: "user details", width: "20%" },
//     { Header: "mobile/email", accessor: "mobile/email", width: "20%" },

//     { Header: "view", accessor: "view", textAlign: "center" },
//   ],
// };

function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "user details", accessor: "user details", width: "20%" },

    
    { Header: "mobile/email", accessor: "mobile/email", width: "20%" },

    isAdmin?.permissions?.all
      ? {
          Header: "block/unblock",
          accessor: "block/unblock",
        }
      : isAdmin?.permissions?.customers?.includes("DISABLE") && {
          Header: "block/unblock",
          accessor: "block/unblock",
        },

    // { Header: "send Notification", accessor: "send Notification" },

    isAdmin?.permissions?.all
      ? {
          Header: "view",
          accessor: "view",
        }
      : isAdmin?.permissions?.customers?.includes("VIEWS") && {
          Header: "view",
          accessor: "view",
        },
  ].filter(Boolean);
}
const Users = () => {
  const { pathname } = useLocation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isOpen, setIsOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [updateData, setUpdateData] = useState("");
  const admin = localStorage.getItem("admin_id");

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAllBrands = createAllBrands(isAdmin);

  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [isUserDetails, setIsUserDetails] = useState(false);
  const [isUserUpdate, setIsUserUpdate] = useState(false);
  const [isNotificationOn, setIsNotificationOn] = useState(false);
  const [isSingleUser, setIsSingleUser] = useState(false);
  const [isSingleUserId, setIsSingleUserId] = useState("");
  const [viewUserId, setViewUserId] = useState("");
  // console.log(isSingleUser, "viewUserId");
  const [isSearch, setIsSearch] = useState("");
  const [isFilterName, setIsFilterName] = useState("search");
  const [pagess, setPagess] = useState(1);
  const [gender, setgender] = useState(0);
  const [isSwitch, setIsSwitch] = useState(null);
  const [userType, setUserType] = useState(null);
  const { AllUsers, Loading, isPages, singleUsers } = useSelector((state) => ({
    ...state.isUsers,
  }));

  useEffect(() => {
    dispatch(
      getAllUsers(
        `/getAllUser/${admin}?page=${pagess}${
          isSearch !== "" ? `&${isFilterName}=${isSearch}` : ""
        }&gender=${gender || ""}`
      )
    );
  }, [pagess, isSearch, isFilterName, update, isOpen, gender]);

  useEffect(() => {
    if (AllUsers && AllUsers.length > 0) {
      const temprows =
        AllUsers &&
        AllUsers?.at(0) &&
        AllUsers?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
              {/* {console.log(value?.fullName, "value")} */}
            </MDTypography>
          ),
          "user details": (
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 1,
              }}
            >
              <MDBox sx={{ height: 40, width: 40 }}>
                {/* {console.log(value,"dfghjkdfgh")} */}
                <img
                  src={`${process.env.REACT_APP_URI}/${value?.image}`}
                  alt={"img"}
                 onError={(e) => {
                    (e.onError = null),
                      (e.target.src = require("../../assets/images/no123.jpeg"));
                  }}
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
              </MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <MDTypography
                  sx={{ fontSize: 12, fontWeight: "medium" }}
                  variant="text"
                  style={{
                    maxWidth: "350px",
                    lineHeight: "20px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 2,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Name: {value?.fullName || null}
                </MDTypography>
                <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                  UserId: {value?._id || "N/A"}
                </MDTypography>
              </MDBox>
            </MDBox>
          ),

          "User Type": (
            <MDBadge
              badgeContent={value?.userType || "N/A"}
              color={
                (value?.userType?.at(0) === "USER" && "primary") ||
                (value?.userType?.at(0) === "ADMIN" && "info") ||
                (value?.userType?.at(0) === "SUB_ADMIN" && "warning") ||
                (value?.userType?.at(0) === "SUB_ADMIN" && "warning") ||
                (value?.userType?.at(0) === "PARTNER" && "primary")
              }
              variant="gradient"
              size="lg"
            />
          ),
          Permission: (
            <MDBadge
              badgeContent={value?.permissions || "N/A"}
              color={(value?.role === "USER" && "primary") || (value?.role === "ADMIN" && "info")}
              variant="gradient"
              size="lg"
            />
          ),
          "mobile/email": (
            <>
              {value?.email && (
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  fontSize={12}
                  ml={1}
                  lineHeight={2}
                >
                  Email: {value?.email}
                </MDTypography>
              )}

              {value?.phoneNumber && (
                <MDTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  ml={1}
                  fontSize={12}
                  lineHeight={1}
                >
                  Mobile No : {value?.phoneNumber}{" "}
                </MDTypography>
              )}
            </>
          ),
          MemberShips: (
            <>
              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
              >
                {value?.membership?.features.length > 0 ? (
                  <>Features: {value?.membership?.features}</>
                ) : (
                  "-"
                )}
              </MDTypography>

              <MDTypography
                display="block"
                variant="button"
                fontWeight="medium"
                ml={1}
                lineHeight={1}
              >
                {value?.membership?.membershipId ? (
                  <>MemberShip Id : {value?.membership?.membershipId}</>
                ) : (
                  "-"
                )}
              </MDTypography>
            </>
          ),
          id: (
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
            >
              {value?._id || "N/A"}
            </MDTypography>
          ),

          view: (
            <>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsUserDetails(true);
                  setViewUserId(value);
                }}
              >
                <Visibility
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),
          "block/unblock": (
            <Tooltip sx={{px:3.5}} title={value?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  dispatch(
                    updateUser({
                      url: `${process.env.REACT_APP_API}/disableUser/${value?._id}/${admin}`,
                    })
                  ).then((data) => {
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );
                    if (data?.payload?.success) {
                      dispatch(getAllGlobalUsers(`${process.env.REACT_APP_API}/getAllUser/${admin}`));
                    }
                  });
                }}
              >
                {value?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          ),

          "send Notification": (
            <>
              <IconButton
                aria-label="msg send"
                onClick={() => {
                  setIsNotificationOn(true);
                  setIsSingleUser(true);
                  setIsSingleUserId(value?._id);
                  dispatch(getSingleUser(`getUserById/${value?._id}`));
                }}
              >
                <NotificationAdd
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),

          action: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setUpdate(true);
                setUpdateData(value);
              }}
            >
              <Edit
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
        }));

      setRowsData(temprows);
    } else {
      setRowsData([]);
    }
  }, [AllUsers]);

  // console.log(singleUsers?._id, "isSingleUserId");

  const [isFormData, setIsFormData] = useState({
    message: "",
    title: "",
    userId: singleUsers?._id,
  });
  const handleForm = (e) => {
    const { name, value } = e.target;
    setIsFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isSingleUser) {
      dispatch(
        createNotification({
          url: `${process.env.REACT_APP_API}sendNotificationToSingleUser`,
          data: {
            message:isFormData?.message,
            title:isFormData?.title,
            userId:singleUsers?._id,
          },
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsSingleUser(false);
        setIsNotificationOn(false);
        setIsFormData((prev) => ({
          ...prev,
          message: "",
          title: "",
        }));
      });
    } else {
      dispatch(
        createNotification({
          url: `${process.env.REACT_APP_API}/sendNotificationToAllUser`,
          data: isFormData,
        })
      ).then((data) => {
        dispatch(
          handleAlert({
            isOpen: true,
            type: `${data?.payload?.success ? "success" : "error"}`,
            msg: data?.payload?.message,
          })
        );
        setIsSingleUser(false);
        setIsNotificationOn(false);
        setIsFormData((prev) => ({
          ...prev,
          message: "",
          title: "",
        }));
      });
    }
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                User &apos;s Table{" "}
              </MDTypography>
              {/* {userType === "ADMIN" || userType === "SUB_ADMIN" ? (
                <MDBox sx={{ display: "flex", gap: "2%" }}>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    disabled={Loading}
                    sx={({ palette: { dark, white, info } }) => ({
                      color: white.main,
                      backgroundColor: dark.main,
                      "&:hover": {
                        color: white.main,
                        backgroundColor: dark.main,
                      },
                    })}
                    onClick={() => {
                      // setIsNotificationOn(true);
                      setIsOpen(true);
                      setIsSingleUser(false);
                    }}
                  >
                    <People />
                    &nbsp; Create Admin And SubAdmin
                  </MDButton>
                </MDBox> */}
              {/* // ) : ( */}
              <MDButton
                variant="gradient"
                color="dark"
                disabled={Loading}
                sx={({ palette: { dark, white, info } }) => ({
                  color: white.main,
                  backgroundColor: dark.main,
                  "&:hover": {
                    color: white.main,
                    backgroundColor: dark.main,
                  },
                })}
                onClick={() => {
                  setIsNotificationOn(true);
                  // setIsOpen(true);
                  // setIsSingleUser(false);
                }}
              >
                <NotificationAdd />
                &nbsp; Send to all User Notification
              </MDButton>
              {/* )} */}
            </MDBox>
            <MDBox
              px={3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <MDBox
                py={3}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 1.5,
                }}
              >
                <MDTypography variant="button">Filters</MDTypography>
                <MDBox sx={{ display: "flex", gap: "1%" }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={isFilterName}
                    disabled={Loading}
                    onChange={(e) => {
                      setIsFilterName(e.target.value);
                      setPagess(1);
                      if (e.target.value === "disable") setIsSearch(0);
                      else setIsSearch("");
                    }}
                    sx={({ palette: { dark, white, info } }) => ({
                      width: 150,
                      height: "3rem",
                      color: darkMode ? white?.main : dark?.main,
                      bgcolor: "transparent",
                      "&	.MuiSelect-icon": {
                        color: darkMode ? white?.main : dark?.main,
                        display: "block !important",
                        fontSize: "1.5rem !important",
                      },
                    })}
                  >
                    <MenuItem checked value={"search"}>
                      Search
                    </MenuItem>
                    <MenuItem value={"disable"}>Visibility</MenuItem>
                    {/* <MenuItem value={"gender"}>Gender</MenuItem> */}
                  </Select>

                  {isFilterName === "search" ? (
                    <MDInput
                      disabled={Loading}
                      placeholder="search here...... "
                      type={
                        (isFilterName === "name" && "text") ||
                        (isFilterName === "mobile" && "number") ||
                        (isFilterName === "email" && "email")
                      }
                      fullWidth
                      name="sarch here"
                      value={isSearch}
                      onChange={(e) => setIsSearch(e.target.value)}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 45,
                        },
                      }}
                    />
                  ) : (
                    <Select
                      disabled={Loading}
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={isSearch}
                      onChange={(e) => {
                        setPagess(1);
                        setIsSearch(e.target.value);
                      }}
                      fullWidth
                      sx={({ palette: { dark, white, info } }) => ({
                        width: "10rem",
                        height: "3rem",
                        color: darkMode ? white?.main : dark?.main,
                        bgcolor: "transparent",
                        "&	.MuiSelect-icon": {
                          color: darkMode ? white?.main : dark?.main,
                          display: "block !important",
                          fontSize: "1.5rem !important",
                        },
                      })}
                    >
                      <MenuItem checked value={0}>
                        Choose Visibility
                      </MenuItem>
                      <MenuItem checked value={false}>
                        Active
                      </MenuItem>
                      <MenuItem value={true}>Disabled </MenuItem>
                    </Select>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                pt={3}
                pb={3}
                px={1}
                sx={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 1.5,
                }}
              >
                <MDTypography variant="button">Gender</MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={gender}
                  onChange={(e) => {
                    setPagess(1);
                    setgender(e.target.value);
                  }}
                  sx={({ palette: { dark, white } }) => ({
                    width: "10rem",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "& .MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {" "}
                  <MenuItem value={0}>ALL</MenuItem>
                  <MenuItem value={"MALE"}>Male</MenuItem>
                  <MenuItem value={"FEMALE"}>Female</MenuItem>
                </Select>
              </MDBox>
            </MDBox>
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : AllUsers && AllUsers.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={AllUsers && AllUsers.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />
                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"90vh"}
        padding={3}
        overflowY={true}
      >
        <Form setIsOpen={setIsOpen} userType={userType} />
      </SkModal>
      <SkModal
        show={update}
        unShow={setUpdate}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        maxHeight="90vh"
        padding={3}
        overflowY={true}
      >
        <UpdateForm
          setUpdate={setUpdate}
          update={update}
          updateData={updateData}
          userType={userType}
        />
      </SkModal>
      <SkModal
        show={isUserDetails}
        unShow={setIsUserDetails}
        width={{ sx: "100%", md: "50%", xl: "50%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <SingleUserDetails
          viewUserId={viewUserId}
          setViewProductModal={setIsUserDetails}
          isUserDetails={isUserDetails}
        />
      </SkModal>
      <SkModal
        show={isNotificationOn}
        unShow={setIsNotificationOn}
        width={{ sx: "100%", md: "50%", xl: "50%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={{
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 5,
            width: "100%",
          }}
        >
          <Card
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 , 10px",
            }}
          >
            <MDTypography variant="h6" py={0.9}>
              {isSingleUser
                ? `${singleUsers?.fullName} Send Notification`
                : "Send to all User Notification"}
            </MDTypography>
          </Card>
        </MDBox>
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          //   sx={{
          //
          //   }}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
            p: 3,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 3,
            width: "100%",
            my: 2,
          })}
          component="form"
          role="form"
          onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            gap={1}
            width={"90%"}
            justifyContent="flex-start"
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
          >
            <MDTypography variant="h6">
              Notification Title <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Notification Title"
              fullWidth
              name="title"
              value={isFormData?.title}
              onChange={handleForm}
            />
          </MDBox>

          <MDBox
            lineHeight={1}
            gap={1}
            width={"90%"}
            justifyContent="flex-start"
            alignItems="flex-start"
            display="flex"
            flexDirection="column"
          >
            <MDTypography variant="h6">
              Notification message <AstrieskIcon />
            </MDTypography>
            <MDInput
              required={true}
              type="text"
              placeholder="Notification message"
              fullWidth
              name="message"
              value={isFormData?.message}
              onChange={handleForm}
              multiline
              rows={5}
            />
          </MDBox>

          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            <MDButton color={"info"} verdant={"gradient"} type={"submit"}>
              {isSingleUser
                ? `${singleUsers?.fullName} Send Notification`
                : "Send to all User Notification"}
            </MDButton>
          </MDBox>
        </MDBox>
      </SkModal>
    </>
  );
};

export default Users;

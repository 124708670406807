import axios from "axios";
import http from "Utils/api";
import { handleAlert } from "./alertSlice";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllTutorials = createAsyncThunk("getAllTutorials", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleTutorialsById = createAsyncThunk("getSingleTutorialsById", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalTutorialsById = createAsyncThunk("getGlobalTutorialsById", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateTutorials = createAsyncThunk("updateTutorials", async ({ url, data }) => {
  try {
    const response = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteTutorials = createAsyncThunk("deleteTutorials", async ({ url }) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const createTutorials = createAsyncThunk("createTutorials", async ({ url, data }) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
const tutorialSlice = createSlice({
  name: "tutorialSlice",
  initialState: {
    tutorials: null,
    singleTutorials: null,
    isPages: null,
    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTutorials.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllTutorials.fulfilled, (state, action) => {
        state.Loading = false;
        state.tutorials = action.payload?.data;
        state.isPages = action.payload?.totalPage;
      })
      .addCase(getGlobalTutorialsById.fulfilled, (state, action) => {
        state.Loading = false;
        state.tutorials = action.payload?.data;
        state.isPages = action.payload?.totalPage;
      })
      .addCase(getAllTutorials.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(createTutorials.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(createTutorials.fulfilled, (state, action) => {
        state.Loading = false;
      })
      .addCase(createTutorials.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getSingleTutorialsById.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getSingleTutorialsById.fulfilled, (state, action) => {
        state.Loading = false;
        state.singleTutorials = action.payload?.data;
      })
      .addCase(getSingleTutorialsById.rejected, (state, action) => {
        state.Loading = false;
      });
  },
});
export default tutorialSlice.reducer;

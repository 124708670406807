import React, { useEffect, useState } from 'react'
import { Close, Delete, Edit, Input, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
// import Form from "./Form";
import { getAllTax, getSingleTax } from "redux/festures/taxSlice";
import { getAllDisease } from "redux/festures/diseaseSlice";
import { getSingleDisease } from "redux/festures/diseaseSlice";
import { updateDisease } from "redux/festures/diseaseSlice";
import { getAllAppointment } from "redux/festures/AppointmentSlice";
import { getSingleAppointment } from "redux/festures/AppointmentSlice";
import { getAllQuestions } from 'redux/festures/questionAnswerSlice';
import { getSingleQuestion } from 'redux/festures/questionAnswerSlice';
import QuestionDetailPage from './QuestionDetailPage';
import CreateQuestion from './CreateQuestion';
import { deleteQuestion } from 'redux/festures/questionAnswerSlice';

const sortFilter = [
    {
      _id: "",
      name: "None",
    },
    {
      _id: "NEWTOOLD",
      name: "New to old",
    },
    {
      _id: "OLDTONEW",
      name: "Old to new",
    },
  ];
  const columns = {
    AllDisease: [
      { Header: "S.No", accessor: "no" },
      { Header: "Questions", accessor: "question" },
    //   { Header: "Email/Mobile", accessor: "Email/Mobile" },
    //   { Header: "age /gender", accessor: "age /gender" },
    //   { Header: "date", accessor: "date" },
      { Header: "view", accessor: "view" },
      { Header: "delete", accessor: "delete" },
      { Header: "action", accessor: "action" },
    ],
  };
const AssessmentQuestions = () => {

    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const admin = localStorage.getItem("admin_id");
    const [isOpen, setIsOpen] = useState(false);
    const [sort, setSort] = useState("");
    const [rowsData, setRowsData] = useState([]);
    const [isOpenView, setIsOpenView] = useState(false);
    const [isOpenUpdate, setIsOpenUpdate] = useState(false);
    const dispatch = useDispatch();
    const {questions,Loading} = useSelector((state)=>({...state?.isQuestion}))
    // console.log(questions)

    useEffect(() => {
        dispatch(
            getAllQuestions(
            `${process.env.REACT_APP_API}/getAllDosha`
          )
        );
      }, []);

    useEffect(() => {
        if (questions && questions.length > 0) {
          const temprows =
          questions &&
          questions?.at(0) &&
          questions?.map((value, index) => ({
              no: (
                <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
                  {index + 1}
                </MDTypography>
              ),
              question: (
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <MDTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                    ml={1}
                    lineHeight={1}
                    style={{
                      maxWidth: "220px",
                      lineHeight: "18px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value?.question || "-"}
                  </MDTypography>
                </MDBox>
              ),
              "Email/Mobile": (
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 0.5,
                      flexDirection: "column",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                    }}
                  >
                    <MDTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                      ml={1}
                      lineHeight={1}
                      style={{
                        maxWidth: "220px",
                        lineHeight: "18px",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {/* Name: {value?.name || "-"} */}
                      Mobile No: {value?.mobile || "-"}
                    </MDTypography>
    
                    <MDTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                      ml={1}
                      lineHeight={1}
                      fontSize={12}
                    >
                      Email Id: {value?.email || "-"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ),
              "age /gender": (
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: 0.5,
                      flexDirection: "column",
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                    }}
                  >
                    <MDTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                      ml={1}
                      lineHeight={1}
                      style={{
                        maxWidth: "220px",
                        lineHeight: "18px",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Age: {value?.age || "-"} year
                    </MDTypography>
    
                    <MDTypography
                      display="block"
                      variant="button"
                      fontWeight="medium"
                      ml={1}
                      lineHeight={1}
                      fontSize={12}
                    >
                      Gender: {value?.gender || "-"}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ),
              date: (
                <MDBox flexDirection="column">
                  <MDTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                    ml={1}
                    lineHeight={1}
                    style={{
                      maxWidth: "220px",
                      lineHeight: "18px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Appoinment Date: {formattedDateServer(new Date(value?.date)) || ""}
                  </MDTypography>
                  <MDTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                    ml={1}
                    lineHeight={1}
                    fontSize={12}
                  >
                    Appoinment Time: {value?.time || ""}
                  </MDTypography>
                </MDBox>
              ),
              view: (
                <IconButton
                  aria-label="action_edit"
                  onClick={() => {
                    setIsOpenView(true);
                    dispatch(getSingleQuestion( `${process.env.REACT_APP_API}/getByIdDosha/${value?._id}`));
                  }}
                >
                  <Visibility
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                </IconButton>
              ),
    
              delete: (
                <Tooltip title={value?.disable ? "move to Active" : "delete"}>
                  <IconButton
                    aria-label="action_edit"
                    // disabled={value?.disable}
                    onClick={() => {
                      dispatch(
                        deleteQuestion({
                          url: `${process.env.REACT_APP_API}deleteDosha/${value?._id}`,
                        })
                      ).then((data) => {
                        dispatch(
                          handleAlert({
                            isOpen: true,
                            type: `${data?.payload?.success ? "success" : "error"}`,
                            msg: data?.payload?.message,
                          })
                        );
                        if (data?.payload?.success) {
                            dispatch(
                                getAllQuestions(
                                `${process.env.REACT_APP_API}/getAllDosha`
                              )
                            );
                        }
                      });
                    }}
                  >
                    {value?.disable ? (
                      <Input
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? info.main : dark.main,
                        })}
                      />
                    ) : (
                      <Delete
                        sx={({ palette: { dark, white, info } }) => ({
                          color: darkMode ? info.main : dark.main,
                        })}
                      />
                    )}
                  </IconButton>
                </Tooltip>
              ),
              action: (
                <>
                  <IconButton
                    aria-label="action_edit"
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(true);
                      // console.log(value?._id);
                      dispatch(getSingleQuestion( `${process.env.REACT_APP_API}/getByIdDosha/${value?._id}`));
                    }}
                  >
                    <Edit
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? info.main : dark.main,
                      })}
                    />
                  </IconButton>
                </>
              ),
            }));
          setRowsData(temprows);
        } else {
          setRowsData(["", " "]);
        }
      }, [questions]);
  return (
    <>
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox py={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="white">
            Q & A&apos;s Table{" "}
            </MDTypography>
            <MDButton
                    variant="gradient"
                    color="dark"
                    disabled={Loading}
                    sx={({ palette: { dark, white, info } }) => ({
                      color: white.main,
                      backgroundColor: dark.main,
                      "&:hover": {
                        color: white.main,
                        backgroundColor: dark.main,
                      },
                    })}
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(false);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; Create Question
                  </MDButton>
          </MDBox>
          <MDBox py={2}>
            {Loading ? (
              <SkLoading />
            ) : questions && questions.length > 0 ? (
              <DataTable
                table={{
                  columns: columns?.AllDisease,
                  rows: rowsData,
                }}
                isSorted={false}
                entriesPerPage={false}
                isPages={questions && questions.length}
                noEndBorder
                canSearch={false}
                showTotalEntries={false}
                pagination={false}
                isPagination={false}
              />
            ) : (
              <MDBox
                // key={index}
                display="flex"
                justifyContent="center"
                gap={2}
                alignItems="center"
                // width={"100%"}
              >
                <MDTypography variant="h6">No Data Found !</MDTypography>
              </MDBox>
            )}
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
    <SkModal
      show={isOpen}
      unShow={setIsOpen}
      width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
      height={"auto"}
      padding={3}
      overflowY={true}
    >
      <CreateQuestion isOpenUpdate={isOpenUpdate} setIsOpenUpdate={setIsOpenUpdate} setIsOpen={setIsOpen} />
    </SkModal>
    <SkModal
      show={isOpenView}
      unShow={setIsOpenView}
      width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
      height="auto"
      maxHeight="90vh"
      padding={3}
      overflowY={true}
    >
      <QuestionDetailPage/>
    </SkModal>
  </>
  )
}

export default AssessmentQuestions
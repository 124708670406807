import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "Utils/api";

export const getAllAppointment = createAsyncThunk("getAllAppointment ", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const getSingleAppointment = createAsyncThunk("getSingleAppointment ", async (data) => {
  try {
    return data;
  } catch (error) {
    return {data:null};
  }
});

const AppointmentSlice = createSlice({
  name: "AppointmentSlice",
  initialState: {
    Loading: false,
    Appointment: null,
    SingleAppointment: null,
    page: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getAllAppointment.fulfilled, (state, action) => {
      state.Loading = false;
      state.Appointment = action.payload?.data;
      state.page = action.payload?.page;
    })
    .addCase(getSingleAppointment.fulfilled, (state, action) => {
      state.SingleAppointment = action.payload;
    })
  },
});
export default AppointmentSlice.reducer;

import {
  Delete,
  Edit,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Visibility,
  Input,
} from "@mui/icons-material";
import {
  Card,
  Icon,
  IconButton,
  Collapse,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";
import PropTypes from "prop-types";
import { handleCategories } from "redux/festures/categorySlice";

export default function ChildCategories({
  row,
  index,
  handleBinSwitch,
  handleChangeShowInHome,
  isOpenForm,
  setIsOpenForm,
  isOpenView,
  setIsOpenView,
  isOpenUpdate,
  setIsOpenUpdate,
}) {
  // const { row } = props;
  // console.log(row, "row DAta");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {/* <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <KeyboardArrowUp
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              />
            ) : (
              <KeyboardArrowDown
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? white.main : dark.main,
                })}
              />
            )}
          </IconButton> */}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          {index + 1}
        </TableCell>

        <TableCell
          component="th"
          align="flex-start"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          <MDBox
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 0.5,
              width: "100%",
            }}
          >
            <MDBox sx={{ height: 40, width: 40 }}>
              <img
                src={`${process.env.REACT_APP_URI}/${row?.icon}`}
                alt={"img"}
                onError={(e) => {
                  (e.onError = null), (e.target.src = require("../../../assets/images/no123.jpeg"));
                }}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            </MDBox>{" "}
            <MDTypography
              display="block"
              variant="button"
              fontWeight="medium"
              ml={1}
              lineHeight={1}
              // wordBreak=" break-all"
              style={{
                maxWidth: "250px",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row?.name}
              {/* {console.log(row?.name, "row?.name")} */}
            </MDTypography>
          </MDBox>
        </TableCell>
        {/* <TableCell
          component="th"
          align="center"
          sx={({ palette: { dark, white, info } }) => ({
            color: darkMode ? white.main : dark.main,
          })}
        >
          <IconButton aria-label="action-edit">
            <Switch
              value={row?.showInHome}
              checked={row?.showInHome}
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : info.main,
              })}
              onChange={(e) => {
                e.stopPropagation();
                handleChangeShowInHome(row?._id);
                // e.preventDefault();
                // handleChangeSwitch(value?._id);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </IconButton>
        </TableCell> */}
        {isAdmin?.permissions?.all ? (
          <TableCell
            component="th"
            align="center"
            sx={({ palette: { dark, white, info } }) => ({
              color: darkMode ? white.main : dark.main,
            })}
          >
            <MDBox
              display="flex"
              alignItems="center"
              lineHeight={1}
              zIndex={10}
              sx={{ width: "20%" }}
            >
              <IconButton
                aria-label="action_edit"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenView((prev) => ({ ...prev, childCategories: true }));
                  dispatch(
                    handleCategories({
                      categories: row,
                      type: "Child Categories",
                    })
                  );
                  // setViewData(value);
                  // setIsOpenView(true);
                }}
              >
                <Visibility
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </MDBox>
          </TableCell>
        ) : (
          isAdmin?.permissions?.category.includes("VIEWS") && (
            <TableCell
              component="th"
              align="center"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
            >
              <MDBox
                display="flex"
                alignItems="center"
                lineHeight={1}
                zIndex={10}
                sx={{ width: "20%" }}
              >
                <IconButton
                  aria-label="action_edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenView((prev) => ({ ...prev, childCategories: true }));
                    dispatch(
                      handleCategories({
                        categories: row,
                        type: "Child Categories",
                      })
                    );
                    //   setViewData(value);
                    //   setIsOpenView(true);
                  }}
                >
                  <Visibility
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                </IconButton>
              </MDBox>
            </TableCell>
          )
        )}
        {isAdmin?.permissions?.all ? (
          <TableCell
            align="center"
            component="th"
            sx={({ palette: { dark, white, info } }) => ({
              color: darkMode ? white.main : dark.main,
            })}
          >
            <Tooltip title={row?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_delete"
                // disabled={row?.disable}
                onClick={() => {
                  handleBinSwitch(row?._id);
                  //   setIsOpenDialog((Preview) => ({
                  //     ...Preview,
                  //     open: true,
                  //     isId: row?._id,
                  //   }));
                }}
              >
                {row?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
        ) : (
          isAdmin?.permissions?.range?.includes("DISABLE") && (
            <TableCell
              align="center"
              component="th"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
            >
              <Tooltip title={row?.disable ? "move to Active" : "delete"}>
                <IconButton
                  aria-label="action_delete"
                  // disabled={row?.disable}
                  onClick={() => {
                    handleBinSwitch(row?._id);
                    //   setIsOpenDialog((Preview) => ({
                    //     ...Preview,
                    //     open: true,
                    //     isId: row?._id,
                    //   }));
                  }}
                >
                  {row?.disable ? (
                    <Input
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? info.main : dark.main,
                      })}
                    />
                  ) : (
                    <Delete
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? info.main : dark.main,
                      })}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </TableCell>
          )
        )}

        {isAdmin?.permissions?.all ? (
          <TableCell
            component="th"
            align="center"
            sx={({ palette: { dark, white, info } }) => ({
              color: darkMode ? white.main : dark.main,
            })}
          >
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenForm((prev) => ({ ...prev, childCategories: true }));
                setIsOpenUpdate((prev) => ({ ...prev, childCategories: true }));
                dispatch(
                  handleCategories({
                    categories: row,
                    type: "Child Categories",
                  })
                );
              }}
            >
              <Edit
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          </TableCell>
        ) : (
          isAdmin?.permissions?.range?.includes("UPDATE") && (
            <TableCell
              component="th"
              align="center"
              sx={({ palette: { dark, white, info } }) => ({
                color: darkMode ? white.main : dark.main,
              })}
            >
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpenForm((prev) => ({ ...prev, childCategories: true }));
                  setIsOpenUpdate((prev) => ({ ...prev, childCategories: true }));
                  dispatch(
                    handleCategories({
                      categories: row,
                      type: "Child Categories",
                    })
                  );
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </TableCell>
          )
        )}
      </TableRow>
    </React.Fragment>
  );
}

ChildCategories.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,

  isOpenForm: PropTypes.object,
  setIsOpenForm: PropTypes.func,
  isOpenView: PropTypes.object,
  setIsOpenView: PropTypes.func,
  isOpenUpdate: PropTypes.object,
  setIsOpenUpdate: PropTypes.func,
  handleBinSwitch: PropTypes.func,
  handleChangeShowInHome: PropTypes.func,
};

import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleUser } from "redux/festures/userSlice";
import { camelToFlat } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";

const SingleUserDetails = ({ viewUserId, setViewProductModal }) => {
  const { isLoading, singleUsers } = useSelector((state) => ({ ...state.isUsers }));
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // useEffect(() => {
  //   dispatch(getSingleUser(`getUserById/${viewUserId}`));
  // }, [viewUserId]);

  console.log(viewUserId, "singleUsers");
  return isLoading ? (
    <SkLoading />
  ) : (
    <>
      <MDBox
        sx={({ palette: { dark, white, info } }) => ({
          border: 0.5,
          borderColor: darkMode ? white.main : dark.main,
          borderRadius: 3,
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
          flexDirection: "column",
          gap: 2.5,
        })}
      >
        {/* {viewUserId?.fullName && (
          <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
            <MDTypography fontSize={12}> Full Name :</MDTypography>
            <MDTypography fontSize={12}> {viewUserId?.fullName}</MDTypography>
          </MDBox>
        )} */}
        <MDBox
          sx={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            gap: 2,
          }}
        >
          <MDBox width={"200px"} height={"200px"} borderRadius={"100%"}>
            <img
              src={`${process.env.REACT_APP_URI}/${viewUserId?.image}`}
              onError={(e) => {
                e.error = null;
                e.target.src = require("../../assets/images/no123.jpeg");
              }}
              style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "50%" }}
            />
          </MDBox>
          <MDTypography fontSize={12}> User Image </MDTypography>
        </MDBox>
        {viewUserId?.fullName && (
          <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
            <MDTypography fontSize={12}> Full Name :</MDTypography>
            <MDTypography fontSize={12}> {viewUserId?.fullName}</MDTypography>
          </MDBox>
        )}
        {viewUserId?.phoneNumber && (
          <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
            <MDTypography fontSize={12}> Mobile :</MDTypography>
            <MDTypography fontSize={12}> {viewUserId?.phoneNumber}</MDTypography>
          </MDBox>
        )}
        {viewUserId?.email && (
          <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
            <MDTypography fontSize={12}> Email :</MDTypography>
            <MDTypography fontSize={12}> {viewUserId?.email}</MDTypography>
          </MDBox>
        )}

        {viewUserId?.userType && viewUserId?.userType?.length ? (
          <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
            <MDTypography fontSize={12}> User Type :</MDTypography>
            <MDTypography fontSize={12}> {viewUserId?.userType?.join(",")}</MDTypography>
          </MDBox>
        ) : null}

        <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
          <MDTypography fontSize={12}> Disable :</MDTypography>
          <MDBadge
            badgeContent={viewUserId?.disable ? "Yes" : "No"}
            color={viewUserId?.disable ? "error" : "success"}
            variant="gradient"
            size="lg"
          />
        </MDBox>

        {viewUserId?.permissions ? (
          <>
            <MDBox
              sx={({ palette: { dark, white, info } }) => ({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                borderTop: 1,
                borderTopColor: darkMode ? white.main : info.main,
                mt: 3,
              })}
              image
            >
              {" "}
              <MDBadge
                badgeContent="Permissions"
                color="success"
                variant="gradient"
                size="lg"
                sx={{ zIndex: 10, mt: -1.5 }}
              />
            </MDBox>
            <MDBox
              sx={({ palette: { dark, white, info } }) => ({
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 2,
              })}
            >
              {Object.entries(viewUserId?.permissions).map(([key, value], index) => (
                <MDBox
                  key={index}
                  sx={({ palette: { dark, white, info }, breakpoints }) => ({
                    borderBottom: "2px",
                    borderColor: darkMode ? white.main : dark.main,
                    borderRadius: 3,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 3,
                  })}
                >
                  <MDTypography
                    textAlign="left"
                    fontSize={12}
                    variant="button"
                    fontWeight={"medium"}
                  >
                    {camelToFlat(key)}
                  </MDTypography>

                  <MDTypography
                    fontWeight={"medium"}
                    textAlign="left"
                    fontSize={12}
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "wrap",
                      textOverflow: "ellipsis",
                      maxWidth: "70%",
                    }}
                  >
                    {value.map((permissions, index) => (
                      <MDBadge
                        key={index}
                        badgeContent={permissions}
                        color={
                          (permissions === "CREATE" && "success") ||
                          (permissions === "UPDATE" && "warning") ||
                          (permissions === "DISABLE" && "error") ||
                          (permissions === "VIEWS" && "primary") ||
                          (permissions === "NONE" && "dark")
                        }
                        variant="gradient"
                        size="md"
                      />
                    ))}
                  </MDTypography>
                </MDBox>
              ))}
            </MDBox>
          </>
        ) : null}

        {/* {singleUsers?.role && (
          <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
            <MDTypography fontSize={12}> Role :</MDTypography>
            <MDTypography fontSize={12}> {singleUsers?.role}</MDTypography>
          </MDBox>
        )} */}
        {/* {singleUsers?.updatedAt && (
          <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
            <MDTypography fontSize={12}> Last Updated :</MDTypography>
            <MDTypography fontSize={12}>
              {formattedDateServer(new Date(singleUsers?.updatedAt))}
            </MDTypography>
          </MDBox>
        )} */}
        {/* <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
          <MDTypography fontSize={12}> User Otp Verify :</MDTypography>
          {singleUsers?.otpVerify ? (
            <MDBadge badgeContent={"Yes"} color={"info"} variant="gradient" size="lg" />
          ) : (
            <MDBadge badgeContent={"No"} color={"error"} variant="gradient" size="lg" />
          )}
        </MDBox>
        <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
          <MDTypography fontSize={12}> Social Login :</MDTypography>
          {singleUsers?.socilaLogin ? (
            <MDBadge badgeContent={"Yes"} color={"info"} variant="gradient" size="lg" />
          ) : (
            <MDBadge badgeContent={"No"} color={"error"} variant="gradient" size="lg" />
          )}
        </MDBox>
        <MDBox display={"flex"} gap={3} justifyContent="space-between" width="100%">
          <MDTypography fontSize={12}> User Visible :</MDTypography>
          {singleUsers?.disable ? (
            <MDBadge badgeContent={"NO"} color={"info"} variant="gradient" size="lg" />
          ) : (
            <MDBadge badgeContent={"Yes"} color={"error"} variant="gradient" size="lg" />
          )}
        </MDBox> */}
      </MDBox>
    </>
  );
};

export default SingleUserDetails;
SingleUserDetails.propTypes = {
  //   children: PropTypes.node,
  viewUserId: PropTypes.any,
  setViewProductModal: PropTypes.any,
};

import axios from "axios";
import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllwork = createAsyncThunk("getAllprice", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllGloblework = createAsyncThunk("getAllGloblework", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSinglework = createAsyncThunk("getSinglework", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updatework = createAsyncThunk("updatework", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const creatework = createAsyncThunk("creatework", async ({ url, data }) => {
  try {
    const res = await axios.post(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    (res.data);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const HowitworkSlice = createSlice({
  name: "WorkSlice",
  initialState: {
    work: null,
    singlework: null,
    createUpdateLoading: false,
    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllwork.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllwork.fulfilled, (state, action) => {
        state.Loading = false;
        state.work = action.payload?.data;
      })
      .addCase(getAllGloblework.fulfilled, (state, action) => {
        state.work = action.payload?.data;
      })
      .addCase(getAllwork.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getSinglework.fulfilled, (state, action) => {
        state.singlework = action.payload?.data;
      })
      .addCase(updatework.pending, (state, action) => {
        state.createUpdateLoading = true;
      })
      .addCase(updatework.fulfilled, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(updatework.rejected, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(creatework.pending, (state, action) => {
        state.createUpdateLoading = true;
      })
      .addCase(creatework.fulfilled, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(creatework.rejected, (state, action) => {
        state.createUpdateLoading = false;
      });
  },
});
export default HowitworkSlice.reducer;
import axios from "axios";
import http from "Utils/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllTactimonials = createAsyncThunk("getAllTactimonials", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getAllglobleTactimonials = createAsyncThunk(
  "getAllglobleTactimonials",
  async (url) => {
    try {
      const res = await http.get(url);
      return res.data;
    } catch (error) {
      return { data: null };
    }
  }
);
export const getSingleTactimonials = createAsyncThunk("getSingleTactimonials", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalBlog = createAsyncThunk("getGlobalBlog", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateTactimonials = createAsyncThunk("updateTactimonials", async ({ url, data }) => {
  try {
    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
});
export const deleteBlog = createAsyncThunk("deleteBlog", async ({ url }) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const createPostTactimonials = createAsyncThunk(
  "createPostTactimonials",
  async ({ url, data }) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
const TactimonialsSlice = createSlice({
  name: "TactimonialsSlice",
  initialState: {
    tactimonials: null,
    singleTactimonials: null,
    isPages: null,
    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTactimonials.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllTactimonials.fulfilled, (state, action) => {
        state.Loading = false;
        state.tactimonials = action.payload?.data;
        state.isPages = action.payload?.totalPage;
      })
      .addCase(getAllglobleTactimonials.fulfilled, (state, action) => {
        state.tactimonials = action.payload?.data;
        state.isPages = action.payload?.totalPage;
      })
      .addCase(getAllTactimonials.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(createPostTactimonials.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(createPostTactimonials.fulfilled, (state, action) => {
        state.Loading = false;
      })
      .addCase(createPostTactimonials.rejected, (state, action) => {
        state.Loading = false;
      })

      .addCase(updateTactimonials.fulfilled, (state, action) => {
      })

      .addCase(getSingleTactimonials.fulfilled, (state, action) => {
        state.singleTactimonials = action.payload?.data;
      });
  },
});
export default TactimonialsSlice.reducer;

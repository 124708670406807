import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "Utils/api";
import axios from "axios";

export const getAllDisease = createAsyncThunk("getAlldisease ", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const getSingleDisease = createAsyncThunk("getSingleDisease", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const createDisease = createAsyncThunk("createDisease", async ({ url, data }) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const updateDisease = createAsyncThunk("updateDisease", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const diseaseSlice = createSlice({
  name: "diseaseSlice",
  initialState: {
    Loading: false,
    disease: null,
    singleDisease: null,

    createUpdateLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDisease.fulfilled, (state, action) => {
        state.Loading = false;
        state.disease = action.payload?.data;
      })
      .addCase(getSingleDisease.fulfilled, (state, action) => {
        state.singleDisease = action.payload.data;
      });
  },
});
export default diseaseSlice.reducer;

import { Card, IconButton, Tooltip, MenuItem, Menu, Stack, Pagination } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDBadge from "components/MDBadge";
import { getAllTransaction } from "redux/festures/AffiliateSlice";
import { updateAffilateTransactionStatus } from "redux/festures/AffiliateSlice";
import SingleTransition from "./SingleTransition";
import { Visibility } from "@mui/icons-material";
import SkModal from "components/SkModal";
import { getSingleTransiton } from "redux/festures/AffiliateSlice";

//   const columns = {
//     AllTax: [
//       { Header: "S.No", accessor: "no" },
//       { Header: "affiliate Product Id", accessor: "affiliateProductId" },
//       { Header: "affiliate User Id", accessor: "affiliateUserId" },
//       { Header: "order Id", accessor: "orderId" },
//       { Header: "status", accessor: "status" },
//       { Header: "change Status", accessor: "change Status" },

//     ],
//   };

function createAlltransation(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "affiliate Product Id", accessor: "affiliateProductId" },
    { Header: "affiliate User Name", accessor: "affiliateUserName" },
    { Header: "order Id", accessor: "orderId" },
    // { Header: "userId", accessor: "userId" },

    isAdmin?.permissions?.all
      ? {
          Header: " status",
          accessor: "status",
        }
      : isAdmin?.permissions?.affiliate?.includes("UPDATE") && {
          Header: "status",
          accessor: "status",
        },
    isAdmin?.permissions?.all
      ? {
          Header: " view",
          accessor: "view",
        }
      : isAdmin?.permissions?.affiliate?.includes("VIEWS") && {
          Header: "view",
          accessor: "view",
        },
  ].filter(Boolean);
}
const Transaction = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [isOpenView, setIsOpenView] = useState(false);
  const { Loading, transaction, isPages } = useSelector((state) => ({ ...state?.isAffiliate }));
  const [transactionId, setTrasactionId] = useState(null);
  const [pagess, setPagess] = useState(1);

  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAlltransition = createAlltransation(isAdmin);


  console.log(transaction,"transaction")

  useEffect(() => {
    dispatch(
      getAllTransaction(
        `${process.env.REACT_APP_API}/getAllAffiliateTransaction?page=${pagess || ""}`
      )
    );
  }, [pagess]);

  const handleStatusChange = (status) => {
    dispatch(
      updateAffilateTransactionStatus({
        url: `${process.env.REACT_APP_API}/updateStatusOfAffiliateTransaction/${transactionId}`,
        data: { status: status },
      })
    ).then((res) => {
      if (res?.payload) {
        dispatch(getAllTransaction(`${process.env.REACT_APP_API}/getAllAffiliateTransaction`));
      }
    });
  };

  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = ({ event }) => {
    setOpenMenu(event.currentTarget);
  };

  useEffect(() => {
    if (transaction && transaction.length > 0) {
      const temprows =
        transaction &&
        transaction?.at(0) &&
        transaction?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),
          affiliateProductId: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>{value?.affiliateProductId || "N/A"}</MDTypography>
            </MDBox>
          ),
          affiliateUserName: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>{value?.affiliateUserId?.fullName || "N/A"}</MDTypography>
            </MDBox>
          ),
          orderId: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>{value?.orderId?.address?._id|| "N/A"}</MDTypography>
            </MDBox>
          ),
          userId: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={12.5}>{value?.orderId?.address?.name || "N/A"}</MDTypography>
            </MDBox>
          ),
          "change Status": (
            <>
              <Tooltip title={value?.status && value?.status?.length === 1 && value?.status}>
                <IconButton
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  variant="gradient"
                  color="info"
                  size="small"
                  circular
                  onClick={(e) => {
                    handleOpenMenu({ event: e });
                    setTrasactionId(value?._id);
                  }}
                >
                  <MDBadge
                    badgeContent="Update Status"
                    color="primary"
                    variant="gradient"
                    size="lg"
                  />
                </IconButton>
              </Tooltip>
            </>
          ),
          status: (
            // <MDBadge
            //   badgeContent={value?.status || "N/A"}
            // //   color={
            //     (value?.status === "PENDING" && "warning") ||
            //     (value?.status === "APPROVED" && "success") ||
            //     (value?.status === "CANCELED" && "error")
            //   }
            //   variant="gradient"
            //   size="lg"
            // />

            <Tooltip title={value?.status || "N/A"}>
              <IconButton
                aria-controls="notification-menu"
                // disabled={value?.status === "SUSPENDED"}
                aria-haspopup="true"
                component="a"
                target="_blank"
                rel="noreferrer"
                variant="gradient"
                color="info"
                size="small"
                circular
                onClick={(e) => {
                  handleOpenMenu({ event: e });
                  setTrasactionId(value?._id);
                }}
              >
                <MDBadge
                  badgeContent={value?.status}
                  color={
                    (value?.status === "PENDING" && "warning") ||
                    (value?.status === "APPROVED" && "success") ||
                    (value?.status === "CANCELED" && "error")
                  }
                  variant="gradient"
                  size="lg"
                />
              </IconButton>
            </Tooltip>
          ),

          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenView(true);
                dispatch(getSingleTransiton(`/getByAffiliateTransactionId/${value?._id}`));
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [transaction]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Affiliate Transaction&apos;s Table{" "}
              </MDTypography>
            </MDBox>
            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : transaction && transaction.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAlltransition,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={transaction && transaction.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />
                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>

      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <SingleTransition />
      </SkModal>

      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
      >
        <>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              handleStatusChange("PENDING");
            }}
          >
            PENDING
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              handleStatusChange("APPROVED");
            }}
          >
            APPROVED
          </MenuItem>
          <MenuItem
            sx={{ border: "1px solid blue", mb: 1 }}
            onClick={() => {
              setOpenMenu(false);
              handleStatusChange("CANCELED");
            }}
          >
            CANCELLED
          </MenuItem>
        </>
      </Menu>
    </>
  );
};

export default Transaction;

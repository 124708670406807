import { Close, Delete, Edit, Visibility, VisibilityOff, Input } from "@mui/icons-material";
import {
  Card,
  FormControlLabel,
  FormGroup,
  Icon,
  Pagination,
  Stack,
  IconButton,
  Switch,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { SkPrice } from "Utils/dateFunc";
import { formattedDateServer } from "Utils/dateFunc";

import { getAllContect } from "redux/festures/ContectSlice";
import { getAllprice } from "redux/festures/PricerangeSlice";
import { getSingleprice } from "redux/festures/PricerangeSlice";
import { updateprice } from "redux/festures/PricerangeSlice";
import Singleprice from "./Singleprice";
import Form from "./Form";
import MDInput from "components/MDInput";
import { getGlobalprice } from "redux/festures/PricerangeSlice";

const disableFilter = [
  {
    _id: 0,
    name: "All",
  },
  {
    _id: false,
    name: "Active",
  },
  {
    _id: true,
    name: "Disable",
  },
];

function createAllBrands(isAdmin) {
  return [
    { Header: "S.No", accessor: "no" },
    { Header: "min price", accessor: "min price" },
    { Header: "max price", accessor: "max price" },

    isAdmin?.permissions?.all
      ? {
          Header: "delete",
          accessor: "delete",
        }
      : isAdmin?.permissions?.priceRange?.includes("DISABLE") && {
          Header: "delete",
          accessor: "delete",
        },
    isAdmin?.permissions?.all
      ? {
          Header: "view",
          accessor: "view",
        }
      : isAdmin?.permissions?.priceRange?.includes("VIEWS") && {
          Header: "view",
          accessor: "view",
        },
    isAdmin?.permissions?.all
      ? {
          Header: "action",
          accessor: "action",
        }
      : isAdmin?.permissions?.priceRange?.includes("UPDATE") && {
          Header: "action",
          accessor: "action",
        },
  ].filter(Boolean);
}

const PriceRange = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const [rowsData, setRowsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenView, setIsOpenView] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));

  const resultAllBrands = createAllBrands(isAdmin);

  const [isDisable, setIsDisable] = useState(false);

  const [pagess, setPagess] = useState(1);

  const { Loading, price, isPages } = useSelector((state) => ({ ...state?.isPrice }));

  useEffect(() => {
    dispatch(
      getAllprice(
        `${process.env.REACT_APP_API}/getAllPriceRange?disable=${
          isDisable === 0 ? "" : isDisable === false ? false : true || ""
        }&page=${pagess || ""}`
      )
    );
  }, [isDisable, pagess]);

  useEffect(() => {
    if (price && price.length > 0) {
      const temprows =
        price &&
        price?.at(0) &&
        price?.map((value, index) => ({
          no: (
            <MDTypography sx={{ fontSize: 12, fontWeight: "medium" }} variant="text">
              {index + 1}
            </MDTypography>
          ),

          "min price": (
            <MDBox>
              <MDTypography variant="button" fontWeight="medium">
                {SkPrice(value?.min) || 0}
              </MDTypography>
            </MDBox>
          ),
          "max price": (
            <MDBox>
              <MDTypography variant="button" fontWeight="medium">
                {SkPrice(value?.max) || 0}
              </MDTypography>
            </MDBox>
          ),

          date: (
            <MDBox flexDirection="column">
              <MDTypography fontSize={10}>
                Create: {formattedDateServer(new Date(value?.createdAt))}
              </MDTypography>
              <MDTypography fontSize={10}>
                Last Update: {formattedDateServer(new Date(value?.updatedAt))}
              </MDTypography>
            </MDBox>
          ),

          view: (
            <IconButton
              aria-label="action_edit"
              onClick={() => {
                setIsOpenView(true);
                dispatch(getSingleprice(`/getByPriceRangeId/${value?._id}/${admin}`));
              }}
            >
              <Visibility
                sx={({ palette: { dark, white, info } }) => ({
                  color: darkMode ? info.main : dark.main,
                })}
              />
            </IconButton>
          ),
          delete: (
            // <Switch
            //   value={value?.disable}
            //   checked={value?.disable}
            //   color={"info"}
            //   onChange={(e) => {

            //     dispatch(
            //       updateBlog({
            //         url: `${process.env.REACT_APP_API}/disableBlog/${value?._id}/${admin}`,
            //       })
            //     ).then((data) => {
            //       dispatch(
            //         handleAlert({
            //           isOpen: true,
            //           type: `${data?.payload?.success ? "success" : "error"}`,
            //           msg: data?.payload?.message,
            //         })
            //       );
            //       if (data?.payload?.success) {
            //         dispatch(getGlobalBlog(`/getAllBlog?adminId=${admin}`));
            //       }
            //     });
            //   }}
            //   inputProps={{ "aria-label": "controlled" }}
            // />
            <Tooltip title={value?.disable ? "move to Active" : "delete"}>
              <IconButton
                aria-label="action_edit"
                // disabled={value?.disable}
                onClick={() => {
                  dispatch(
                    updateprice({
                      url: `${process.env.REACT_APP_API}/disablePriceRange/${value?._id}/${admin}`,
                    })
                  ).then((data) => {
                    dispatch(
                      handleAlert({
                        isOpen: true,
                        type: `${data?.payload?.success ? "success" : "error"}`,
                        msg: data?.payload?.message,
                      })
                    );
                    if (data?.payload?.success) {
                      dispatch(getGlobalprice(`${process.env.REACT_APP_API}/getAllPriceRange`));
                    }
                  });
                }}
              >
                {value?.disable ? (
                  <Input
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                ) : (
                  <Delete
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? info.main : dark.main,
                    })}
                  />
                )}
              </IconButton>
            </Tooltip>
          ),

          action: (
            <>
              <IconButton
                aria-label="action_edit"
                onClick={() => {
                  setIsOpen(true);
                  setIsOpenUpdate(true);
                  // console.log(value?._id);
                  dispatch(getSingleprice(`/getByPriceRangeId/${value?._id}/${admin}`));
                }}
              >
                <Edit
                  sx={({ palette: { dark, white, info } }) => ({
                    color: darkMode ? info.main : dark.main,
                  })}
                />
              </IconButton>
            </>
          ),
        }));
      setRowsData(temprows);
    } else {
      setRowsData(["", " "]);
    }
  }, [price]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography variant="h6" color="white">
                Price Range Table{" "}
              </MDTypography>

              {isAdmin?.permissions?.all ? (
                <MDButton
                  variant="gradient"
                  color="dark"
                  disabled={Loading}
                  sx={({ palette: { dark, white, info } }) => ({
                    color: white.main,
                    backgroundColor: dark.main,
                    "&:hover": {
                      color: white.main,
                      backgroundColor: dark.main,
                    },
                  })}
                  onClick={() => {
                    setIsOpen(true);
                    setIsOpenUpdate(false);
                  }}
                >
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp; Create Price Range
                </MDButton>
              ) : (
                isAdmin?.permissions?.priceRange.includes("CREATE") && (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    disabled={Loading}
                    sx={({ palette: { dark, white, info } }) => ({
                      color: white.main,
                      backgroundColor: dark.main,
                      "&:hover": {
                        color: white.main,
                        backgroundColor: dark.main,
                      },
                    })}
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(false);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp; Create Price Range
                  </MDButton>
                )
              )}
            </MDBox>

            <MDBox
              p={3}
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <MDBox width="23%" display="flex" flexDirection="column" py={3}>
                <MDTypography variant="button"> Price Range Visibility </MDTypography>
                <Select
                  disabled={Loading}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={isDisable}
                  onChange={(e) => setIsDisable(e.target.value)}
                  sx={({ palette: { dark, white, info } }) => ({
                    width: "100%",
                    height: "3rem",
                    color: darkMode ? white?.main : dark?.main,
                    bgcolor: "transparent",
                    "&	.MuiSelect-icon": {
                      color: darkMode ? white?.main : dark?.main,
                      display: "block !important",
                      fontSize: "1.5rem !important",
                    },
                  })}
                >
                  {disableFilter?.map((ele, i) => (
                    <MenuItem component="option" key={i} value={ele?._id}>
                      {ele?.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
            </MDBox>

            <MDBox py={3}>
              {Loading ? (
                <SkLoading />
              ) : price && price.length > 0 ? (
                <>
                  <DataTable
                    table={{
                      columns: resultAllBrands,
                      rows: rowsData,
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    isPages={price && price.length}
                    noEndBorder
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    isPagination={false}
                  />

                  <MDBox
                    sx={{
                      mt: 5,
                      // minHeigth: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack spacing={2} direction={"row"}>
                      <Pagination
                        sx={({ palette: { dark, white, info } }) => ({
                          "&.MuiPaginationItem-text": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&	.MuiPaginationItem-icon": {
                            color: darkMode ? white.main : dark.main,
                          },
                          "&		.MuiPaginationItem-textInfo": {
                            color: darkMode ? white.main : dark.main,
                          },
                        })}
                        color="info"
                        variant="text"
                        count={isPages}
                        page={pagess}
                        onChange={(e, value) => setPagess(value)}
                      />
                    </Stack>
                  </MDBox>
                </>
              ) : (
                <MDBox
                  // key={index}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                  // width={"100%"}
                >
                  <MDTypography variant="h6">No Data Found !</MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <Form
          isOpenUpdate={isOpenUpdate}
          setIsOpenUpdate={setIsOpenUpdate}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      </SkModal>
      <SkModal
        show={isOpenView}
        unShow={setIsOpenView}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <Singleprice />
      </SkModal>
    </>
  );
};

export default PriceRange;

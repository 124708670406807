// import React from 'react';
// import { Delete, Add } from '@mui/icons-material';
// import { TextField, IconButton } from '@mui/material';
// import MDButton from 'components/MDButton';
// import PropTypes from 'prop-types';
// import MDBox from 'components/MDBox';
// import MDInput from 'components/MDInput';

// const AttributeInput = ({ attributes, setAttributes, addField, removeField, placeholder,name, disabled, ...rest }) => {
//   const handleAddAttribute = () => {
//     const temp = [...attributes, ""];
//     addField(temp);
//   };

//   const handleRemoveAttribute = (index) => {
//     const temp = [...attributes];
//     temp.splice(index, 1);
//     removeField(temp);
//   };

//   return (
//     <div style={{width:"100%"}}>
//       <MDBox sx={{width: '100%'}}>
//         <MDBox sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '0.5rem', margin: '0.5rem 0' }}>
//           {attributes && attributes.length ? (
//             attributes.map((ele, i) => (
//               <MDBox key={i} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
//                 <MDInput

//                   disabled={disabled}
//                   sx={{
//                     '& .MuiInputBase-input.MuiOutlinedInput-input': {
//                       maxWidth: '100%',
//                       minWidth: '100%',
//                     },
//                   }}
//                   fullWidth
//                   placeholder={"placeholder"}
//                   name={"name"}
//                   value={ele}
//                   {...rest}
//                   onChange={(e) => {
//                     const temp = [...attributes];
//                     temp[i] = e.target.value;
//                     setAttributes(temp);
//                   }}
//                 />
//                 <IconButton aria-label="remove" disabled={disabled} onClick={() => handleRemoveAttribute(i)}>
//                   <Delete />
//                 </IconButton>
//               </MDBox>
//             ))
//           ) : (
//             <MDInput
//               disabled={disabled}
//               sx={{
//                 '& .MuiInputBase-input.MuiOutlinedInput-input': {
//                   maxWidth: '100%',
//                   minWidth: '100%',
//                 },
//               }}
//               fullWidth
//               placeholder="benefits"
//               name="benefits"
//               value={attributes?.[0] || ''} // Ensure you have a value to avoid "controlled component" warning
//               {...rest}
//               onChange={(e) => {
//                 const temp = [...attributes];
//                 temp[0] = e.target.value;
//                 setAttributes(temp);
//               }}
//             />
//           )}
//         </MDBox>

//         {attributes && attributes.length ? (
//           <MDButton disabled={disabled || attributes.includes("")} startIcon={<Add />} color="info" cursor="pointer" onClick={handleAddAttribute}>
//             Add More
//           </MDButton>
//         ) : null}
//       </MDBox>
//     </div>
//   );
// };

// export default AttributeInput;

// AttributeInput.propTypes = {
//   attributes: PropTypes.array,
//   setAttributes: PropTypes.func,
//   addField: PropTypes.func,
//   removeField: PropTypes.func,
//   disabled: PropTypes.bool,
//   name: PropTypes.string,
//   placeholder: PropTypes.string,
// };

import React from "react";
import { Delete, Add } from "@mui/icons-material";
import { TextField, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";

const AttributeInput = ({
  attributes,
  setAttributes,
  addField,
  removeField,
  placeholder,
  name,
  disabled,
  ...rest
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const handleAddAttribute = () => {
    const temp = [...attributes, ""];
    addField(temp);
  };

  const handleRemoveAttribute = (index) => {
    const temp = [...attributes];
    temp.splice(index, 1);
    removeField(temp);
  };

  return (
    <div style={{ width: "100%" }}>
      <MDBox sx={{ width: "100%" }}>
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "0.5rem",
            margin: "0.5rem 0",
          }}
        >
          {attributes && attributes.length ? (
            attributes.map((ele, i) => (
              <MDBox key={i} sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                <MDInput
                  disabled={disabled}
                  sx={{
                    "& .MuiInputBase-input.MuiOutlinedInput-input": {
                      maxWidth: "100%",
                      minWidth: "100%",
                    },
                  }}
                  fullWidth
                  placeholder={placeholder}
                  name={name}
                  value={ele}
                  {...rest}
                  onChange={(e) => {
                    const temp = [...attributes];
                    temp[i] = e.target.value;
                    setAttributes(temp);
                  }}
                />
                <IconButton
                  aria-label="remove"
                  disabled={disabled}
                  onClick={() => handleRemoveAttribute(i)}
                >
                  <Delete
                    sx={({ palette: { dark, white } }) => ({
                      color: darkMode ? white.main : dark.main,
                    })}
                  />
                </IconButton>
              </MDBox>
            ))
          ) : (
            <MDInput
              disabled={disabled}
              sx={{
                "& .MuiInputBase-input.MuiOutlinedInput-input": {
                  maxWidth: "100%",
                  minWidth: "100%",
                },
              }}
              fullWidth
              placeholder={placeholder}
              name={name}
              value={attributes?.[0] || ""}
              {...rest}
              onChange={(e) => {
                const temp = [...attributes];
                temp[0] = e.target.value;
                setAttributes(temp);
              }}
            />
          )}
        </MDBox>

        {attributes && attributes.length ? (
          <MDButton
            disabled={disabled || attributes.includes("")}
            startIcon={<Add />}
            color="info"
            cursor="pointer"
            onClick={handleAddAttribute}
          >
            Add More
          </MDButton>
        ) : null}
      </MDBox>
    </div>
  );
};

export default AttributeInput;

AttributeInput.propTypes = {
  attributes: PropTypes.array,
  setAttributes: PropTypes.func,
  addField: PropTypes.func,
  removeField: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

//  <MDBox
//                       lineHeight={1}
//                       width={"100%"}
//                       sx={{
//                         display: "flex",
//                         alignItems: "flex-start",
//                         flexDirection: "column",
//                       }}
//                     >
//                       <MDBox
//                         lineHeight={1}
//                         gap={3}
//                         width={"100%"}
//                         sx={{
//                           display: "flex",
//                           alignItems: "flex-start",
//                           flexDirection: "column",
//                         }}
//                       >
//                         <MDTypography variant="h6">
//                           Product MRP <AstrieskIcon />
//                         </MDTypography>
// <MDInput
//   disabled={createUpdateLoading}
//   required={true}
//   type="text"
//   placeholder="MRP"
//   fullWidth
//   name="mrp"
//   value={priceVariant?.[0]?.mrp}
//   // onChange={handleForm}
//   onChange={(e) =>
//     setPriceVariant([
//       {
//         ...priceVariant[0],
//         mrp: e.target.value,
//       },
//     ])
//   }
// />
// </MDBox>
//                       <MDBox
//                         lineHeight={1}
//                         gap={3}
//                         width={"100%"}
//                         sx={{
//                           display: "flex",
//                           alignItems: "flex-start",
//                           flexDirection: "column",
//                         }}
//                       >
//                         <MDTypography variant="h6">
//                           After Tax Value <AstrieskIcon />
//                         </MDTypography>
//                         <MDInput
//                           disabled={createUpdateLoading}
//                           required={true}
//                           type="text"
//                           placeholder="afterTexValue"
//                           fullWidth
//                           name="beforeTaxValue"
//                           value={priceVariant?.[0]?.afterTaxValue}
//                           onChange={(e) =>
//                             setPriceVariant([
//                               {
//                                 ...priceVariant[0],
//                                 afterTaxValue: e.target.value,
//                               },
//                             ])
//                           }
//                         />
//                       </MDBox>

//                       <MDBox
//                         lineHeight={1}
//                         gap={3}
//                         width={"100%"}
//                         sx={{
//                           display: "flex",
//                           alignItems: "flex-start",
//                           flexDirection: "column",
//                         }}
//                       >
//                         <MDTypography variant="h6">
//                           Product Stock <AstrieskIcon />
//                         </MDTypography>

//                         <MDInput
//                           disabled={createUpdateLoading}
//                           required={true}
//                           type="text"
//                           placeholder="stock"
//                           fullWidth
//                           name="stock"
//                           value={priceVariant?.[0]?.stock}
//                           onChange={(e) =>
//                             setPriceVariant([
//                               {
//                                 ...priceVariant[0],
//                                 stock: e.target.value,
//                               },
//                             ])
//                           }
//                         />

//                         {/* <AttributeInput
//                           placeholder={"stock"}
//                           name={"stock"}
//                           attributes={memberData?.benefits}
//                           setAttributes={(e) =>
//                             setIsData({ target: { name: "stock", value: e } })
//                           }
//                           addField={(e) =>
//                             setIsData({
//                               target: {
//                                 name: "stock",
//                                 value: e,
//                               },
//                             })
//                           }
//                           removeField={(e) =>
//                             handleForm({
//                               target: {
//                                 name: "stock",
//                                 value: e,
//                               },
//                             })
//                           }
//                         /> */}
//                       </MDBox>
//                     </MDBox>

import { Icon, Box, Divider, Card, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import SkModal from "components/SkModal";
import { useMaterialUIController } from "context";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import SkConfirm from "components/SkComfirm";
import { getAllHomeBanner, getSingleHomeBanner } from "redux/festures/homeBannerSlice";
import { SwiperSlide } from "swiper/react";
import SwiperSlider from "components/SkSlider/Swiper";
import { deleteHomeBanner } from "redux/festures/homeBannerSlice";
import { getMemberShipImage } from "redux/festures/membershipSlice";
import AstrieskIcon from "components/AstrieskIcon";
import MDInput from "components/MDInput";
import ImagePicker from "components/ApnaUploader";
import { Cancel } from "@mui/icons-material";
import { updateMemberImage } from "redux/festures/membershipSlice";

const MemberShipImage = () => {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { Loading, membershipImage } = useSelector((data) => ({
    ...data?.isMembership,
  }));
//   console.log(membershipImage, "membershipImage");
  const { isAdmin } = useSelector((data) => ({ ...data?.admin }));
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [memberImage, setMemberImage] = useState("");
  const [isShow, setIsShow] = useState("");

  useEffect(() => {
    dispatch(getMemberShipImage(`${process.env.REACT_APP_API}/getMembershipBanner`));
  }, []);

  useEffect(() => {
    if (isOpenUpdate) {
      setIsShow(membershipImage?.data?.bannerImage);
    } else {
      setIsShow("");
    }
  }, [isOpenUpdate, isOpen]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if ((memberImage && memberImage !== "") || (isShow && isShow !== "")) {
      if (isOpenUpdate) {
        const formData = new FormData();
        memberImage && formData.append("membershipBanner", memberImage);
        dispatch(
          updateMemberImage({
            url: `${process.env.REACT_APP_API}/membershipBannerImageUpdate`,
            data: formData,
          })
        ).then((data) => {
          dispatch(
            handleAlert({
              isOpen: true,
              type: `${data?.payload?.success ? "success" : "error"}`,
              msg: data?.payload?.message,
            })
          );
          setIsOpen(false);
          setIsOpenUpdate(false);
          setIsShow("");
          setMemberImage("");
          dispatch(getMemberShipImage(`${process.env.REACT_APP_API}/getMembershipBanner`));
        });
      }
    } else {
      dispatch(
        handleAlert({
          isOpen: true,
          type: "warning",
          msg: "all filed is required",
        })
      );
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <MDBox py={3}>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDTypography variant="h6" color="white">
              Membership Image
            </MDTypography>
          </MDBox>
        </MDBox>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ px: 2.5 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", my: 4, width: "100%" }}>
              <MDTypography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  width: "75%",
                }}
                color="white"
              >
                {membershipImage?.data?.title}
              </MDTypography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {isAdmin?.permissions?.all ? (
                  <MDButton
                    variant="gradient"
                    color="dark"
                    sx={({ palette: { dark, white, info, success } }) => ({
                      color: white.main,
                      backgroundColor: white.main,
                      width: { sm: "9rem" },
                      "&:hover": {
                        color: success.main,
                        backgroundColor: success.main,
                      },
                      mx: 2,
                    })}
                    onClick={() => {
                      setIsOpen(true);
                      setIsOpenUpdate(true);
                    }}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                  </MDButton>
                ) : (
                  isAdmin?.permissions?.banner.includes("UPDATE") && (
                    <MDButton
                      variant="gradient"
                      color="dark"
                      sx={({ palette: { dark, white, info, success } }) => ({
                        color: white.main,
                        backgroundColor: white.main,
                        width: { sm: "9rem" },
                        "&:hover": {
                          color: success.main,
                          backgroundColor: success.main,
                        },
                        mx: 2,
                      })}
                      onClick={() => {
                        setIsOpen(true);
                        setIsOpenUpdate(true);
                      }}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Update
                    </MDButton>
                  )
                )}
              </Box>
            </Box>
            <Box sx={{ height: "50vh", width: "100%", marginBottom: "5%" }}>
              <img
                src={`${process.env.REACT_APP_URI}/${membershipImage?.data?.bannerImage}`}
                alt={"img"}
                onError={(e) => {
                  (e.onError = null), (e.target.src = require("../../assets/images/no123.jpeg"));
                }}
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
            <Divider />
          </Box>
        </Box>

        <Footer />
      </DashboardLayout>
      <SkModal
        show={isOpen}
        unShow={setIsOpen}
        width={{ sx: "100%", md: "65%", xl: "65%", sm: "100%" }}
        height={"auto"}
        padding={3}
        overflowY={true}
      >
        <MDBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          sx={{
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            gap: 2,
            width: "100%",
          }}
        >
          <Card
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 , 10px",
            }}
          >
            <MDTypography variant="h6" py={0.9}>
              {isOpenUpdate ? `Update Membership Image ` : " Create Membership Image"}
            </MDTypography>
          </Card>
          <MDBox
            lineHeight={1}
            sx={({ palette: { dark, white, info } }) => ({
              border: 0.5,
              justifyContent: "center",
              textAlign: "center",
              flexDirection: "column",
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              width: "100%",
              p: 3,
              borderColor: darkMode ? white.main : dark.main,
              borderRadius: 3,
            })}
            component="form"
            role="form"
            onSubmit={handleFormSubmit}
          >
            <MDBox
              lineHeight={1}
              width={"100%"}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <MDTypography variant="h6">
                Membership Image <AstrieskIcon />
                <MDTypography variant="body1" component="span" fontSize={11}>
                  &nbsp; ( image size - 1519 × 443 px )
                </MDTypography>
              </MDTypography>
              <ImagePicker
                name="Brand Image"
                multiple={false}
                images={memberImage}
                setImages={setMemberImage}
                //
              />

              {memberImage === "" && isShow && (
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "50px",
                      height: "50px",
                      margin: "0 0.5rem",
                    }}
                  >
                    <img
                      className="Image"
                      style={{ width: "100%", height: "100%", objectFit: "contain" }}
                      src={`${process.env.REACT_APP_URI}/${isShow}`}
                    />
                  </span>
                  <span
                    className="cross"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsShow(null);
                    }}
                  >
                    <Cancel
                      sx={({ palette: { dark, white, info } }) => ({
                        color: darkMode ? white?.main : dark.main,
                      })}
                    />
                  </span>
                </div>
              )}
            </MDBox>
            <MDBox
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                textAlign: "center",
                display: "flex",
              }}
            >
              {" "}
              <MDButton
                // disabled={createHomeLoading}
                color={"info"}
                verdant={"gradient"}
                type={"submit"}
              >
                {Loading ? (
                  <CircularProgress size={20} color="primary" />
                ) : isOpenUpdate ? (
                  `Update Membership Image`
                ) : (
                  `Create Membership Image`
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </SkModal>
    </>
  );
};

export default MemberShipImage;

import axios from "axios";
import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllprice = createAsyncThunk("getAllprice", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getGlobalprice = createAsyncThunk("getGlobalprice", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleprice = createAsyncThunk("getSingleprice", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updateprice = createAsyncThunk("updateprice", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
  
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const createprice = createAsyncThunk("createprice", async ({ url, data }) => {
  try {
    const res = await axios.post(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return res.data;
  } catch (error) {
    return { data: null };
  }
});

const PricerangeSlice = createSlice({
  name: "PricerangeSlice",
  initialState: {
    price: null,
    singleprice: null,
    isPages:null,
    createUpdateLoading: false,
    Loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllprice.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllprice.fulfilled, (state, action) => {
        state.Loading = false;
        state.price = action.payload?.data;
        state.isPages = action.payload?.page;
      })
      .addCase(getGlobalprice.fulfilled, (state, action) => {
       
        state.price = action.payload?.data;
        state.isPages = action.payload?.page;
      })
      .addCase(getAllprice.rejected, (state, action) => {
        state.Loading = false;
      })

  
      .addCase(getSingleprice.fulfilled, (state, action) => {
        state.singleprice = action.payload?.data;
      })
   

      .addCase(updateprice.pending, (state, action) => {
        state.createUpdateLoading = true;
      })
      .addCase(updateprice.fulfilled, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(updateprice.rejected, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(createprice.pending, (state, action) => {
        state.createUpdateLoading = true;
      })
      .addCase(createprice.fulfilled, (state, action) => {
        state.createUpdateLoading = false;
      })
      .addCase(createprice.rejected, (state, action) => {
        state.createUpdateLoading = false;
      });
  },
});
export default PricerangeSlice.reducer;

import http from "Utils/api";
import axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllNotification = createAsyncThunk("getAllNotification", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleNotification = createAsyncThunk("getSingleNotification", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const getNotificationCount = createAsyncThunk("getNotificationCount", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

export const UpdateNotification = createAsyncThunk("UpdateNotification", async ({ url, data }) => {
  try {
    const response = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
export const deleteNotification = createAsyncThunk("deleteNotification", async ({ url }) => {
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response.data;
  } catch (error) {
    return error.response.data;
  }
});
const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: {
    notification: null,
    singleNotification: null,
    isCount: null,
    isSetLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllNotification.pending, (state, action) => {
        state.isSetLoading = true;
      })
      .addCase(getAllNotification.fulfilled, (state, action) => {
        state.isSetLoading = false;
        state.notification = action.payload?.data;
      })
      .addCase(getAllNotification.rejected, (state, action) => {
        state.isSetLoading = false;
      })
      .addCase(getSingleNotification.pending, (state, action) => {
        state.isSetLoading = true;
      })
      .addCase(getSingleNotification.fulfilled, (state, action) => {
        state.isSetLoading = false;
        state.singleNotification = action.payload?.data;
      })
      .addCase(getSingleNotification.rejected, (state, action) => {
        state.isSetLoading = false;
      })
      .addCase(getNotificationCount.pending, (state, action) => {
        state.isSetLoading = true;
      })
      .addCase(getNotificationCount.fulfilled, (state, action) => {
        state.isSetLoading = false;
        state.isCount = action.payload?.count;
      })
      .addCase(getNotificationCount.rejected, (state, action) => {
        state.isSetLoading = false;
      })
      .addCase(deleteNotification.pending, (state, action) => {
        state.isSetLoading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.isSetLoading = false;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.isSetLoading = false;
      });
  },
});
export default notificationSlice.reducer;

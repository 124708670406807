import { Cancel } from "@mui/icons-material";
import { Card, CircularProgress, FormControlLabel, FormGroup, Switch } from "@mui/material";
import ImagePicker from "components/ApnaUploader";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SkLoading from "components/SkLoading";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "redux/festures/alertSlice";
import { getBrands } from "redux/festures/brandSlice";
import { updateBrands } from "redux/festures/brandSlice";
import { createBrands } from "redux/festures/brandSlice";
import AstrieskIcon from "components/AstrieskIcon";
import Skeditor from "components/SKeditor";
import { EditorState, convertToRaw, convertFromHTML } from "draft-js";
import { creatework } from "redux/festures/HowitworkSlice";
import { updatework } from "redux/festures/HowitworkSlice";
import { stateToHTML } from "draft-js-export-html";
import { getAllwork } from "redux/festures/HowitworkSlice";
import SkEditor from "components/SKeditor/SkEditor";

const CreateWork = ({ isOpen, isOpenUpdate, setIsOpenUpdate, setIsOpen }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isBrandName, setIsBrandName] = useState("");
  const [isDescriptionServer, setIsDescriptionServer] = useState("");
  const [isDescription, setIsDescription] = useState("");
  const [isBrandImage, setIsBrandImage] = useState("");
  const [isShow, setIsShow] = useState("");

  const admin = localStorage.getItem("admin_id");
  const dispatch = useDispatch();
  const { createUpdateLoading, Loading, singlework } = useSelector((data) => ({ ...data.isWork }));

  // console.log(singlework,"singlework")

  useEffect(() => {
    if (singlework && isOpenUpdate) {
      setIsBrandName(singlework?.title);
      setIsShow(singlework?.icon);
      setIsDescription(singlework?.discription)
      // setIsDescriptionServer(singlework?.discription);
      // setIsDescription(EditorState.createEmpty());
    } else {
      setIsBrandName("");
      setIsShow("");
      setIsDescription("")
      // setIsDescriptionServer("");
      // setIsDescription(EditorState.createEmpty());
    }
  }, [singlework, isOpenUpdate, isOpen]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if ((isBrandImage && isBrandImage !== "") || (isShow && isShow !== "")) {
      if (isOpenUpdate) {
        const convertContentToHTML = () => {
          const contentState = isDescription?.getCurrentContent();
          const html = stateToHTML(contentState);
          return html;
        };

        const formData = new FormData();
        formData.append("title", isBrandName);
        isBrandImage && formData.append("icon", isBrandImage);
        formData.append("discription", isDescription);

        dispatch(
          updatework({
            url: `${process.env.REACT_APP_API}updateHowItWork/${singlework?._id}`,
            data: formData,
          })
        ).then((data) => {
          dispatch(
            handleAlert({
              isOpen: true,
              type: `${data?.payload?.success ? "success" : "error"}`,
              msg: data?.payload?.message,
            })
          );
          setIsOpen(false);
          setIsOpenUpdate(false);
          setIsBrandName("");
          setIsBrandImage("");
          // setIsBrandShowHome("");
          dispatch(getAllwork(`${process.env.REACT_APP_API}/getAllHowItWork`));
        });
      } else {
        const convertContentToHTML = () => {
          const contentState = isDescription?.getCurrentContent();
          const html = stateToHTML(contentState);
          return html;
        };
        const formData = new FormData();
        formData.append("title", isBrandName);
        formData.append("icon", isBrandImage);
        formData.append("discription", isDescription);

        dispatch(
          creatework({
            url: `${process.env.REACT_APP_API}createHowItWork`,
            data: formData,
          })
        ).then((data) => {
          dispatch(
            handleAlert({
              isOpen: true,
              type: `${data?.payload?.success ? "success" : "error"}`,
              msg: data?.payload?.message,
            })
          );
          setIsOpen(false);
          setIsOpenUpdate(false);
          setIsBrandName("");
          setIsBrandImage("");
          // setIsBrandShowHome("");
          dispatch(getAllwork(`${process.env.REACT_APP_API}/getAllHowItWork`));
        });
      }
    } else {
      dispatch(
        handleAlert({
          isOpen: true,
          type: "warning",
          msg: "all filed is required",
        })
      );
    }
  };
  return Loading ? (
    <SkLoading />
  ) : (
    <>
      <MDBox
        display="flex"
        alignItems="center"
        lineHeight={1}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        <Card
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 , 10px",
          }}
        >
          <MDTypography variant="h6" py={0.9}>
            {isOpenUpdate ? `Update How It Work ` : " Create How It Work "}
          </MDTypography>
        </Card>
        <MDBox
          lineHeight={1}
          sx={({ palette: { dark, white, info } }) => ({
            border: 0.5,
            justifyContent: "center",
            textAlign: "center",
            flexDirection: "column",
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            width: "100%",
            p: 3,
            borderColor: darkMode ? white.main : dark.main,
            borderRadius: 3,
          })}
          component="form"
          role="form"
          onSubmit={handleFormSubmit}
        >
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              {" "}
              Title <AstrieskIcon />
            </MDTypography>
            <MDInput
              disabled={createUpdateLoading}
              required={true}
              type="text"
              placeholder="Title"
              fullWidth
              name="isBrandName"
              value={isBrandName}
              onChange={(e) => setIsBrandName(e.target.value)}
            />
          </MDBox>
          <MDBox
            lineHeight={1}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Image{" "}<AstrieskIcon/>
              <MDTypography variant="body1" component="span" fontSize={11}>
                &nbsp; ( image size - 160 × 160 px )
              </MDTypography>
            </MDTypography>
            <ImagePicker
              required={singlework ? false : true}
              disabled={createUpdateLoading}
              name=" Image"
              multiple={false}
              images={isBrandImage}
              setImages={setIsBrandImage}
              //
            />

            {isBrandImage === "" && isShow && (
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <span
                  style={{
                    display: "inline-block",
                    width: "50px",
                    height: "50px",
                    margin: "0 0.5rem",
                  }}
                >
                  <img
                    className="Image"
                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    src={`${process.env.REACT_APP_URI}/${isShow}`}
                  />
                </span>
                <span
                  className="cross"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsShow(null);
                  }}
                >
                  <Cancel
                    sx={({ palette: { dark, white, info } }) => ({
                      color: darkMode ? white?.main : dark.main,
                    })}
                  />
                </span>
              </div>
            )}
          </MDBox>
          <MDBox
            lineHeight={1}
            gap={3}
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <MDTypography variant="h6">
              Description <AstrieskIcon /> <MDTypography variant="body1" component="span" fontSize={11}>
                    &nbsp; (Character limit upto 100 Max)
                  </MDTypography>
            </MDTypography>

            {/* <Skeditor
              required={true}
              disabled={createUpdateLoading}
              editorState={isDescription}
              setEditorState={setIsDescription}
              placeholder={"Description"}
              initialContent={isOpen && singlework ? isDescriptionServer : ""}
              // initialContent={singlework && isOpen && singlework?.discription}
              isButton={true}
            /> */}

            {/* <SkEditor
              editorState={isDescription}
              setEditorState={setIsDescription}
              placeholder={"Description"}
              isButton={true}
              initialContent={singlework && isOpenUpdate && singlework?.discription}
              // content={"description"}
            /> */}
           
            <MDInput
            // disabled={createHomeLoading}
              required={true}
              type="text"
              placeholder="Description"
              fullWidth
              name="name"
              value={isDescription}
              onChange={(e) => setIsDescription(e.target.value.slice(0,100))}
            />
          </MDBox>
          <MDBox
            sx={{
              width: "100%",
              justifyContent: "flex-end",
              textAlign: "center",
              display: "flex",
            }}
          >
            {" "}
            {/* <MDButton color="info" verdant="gradient" type="submit">
              {isOpenUpdate ? `Update How it Work` : ` Create How it Work`}
            </MDButton> */}
            <MDButton
              disabled={createUpdateLoading}
              color={"info"}
              verdant={"gradient"}
              type={"submit"}
            >
              {createUpdateLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : isOpenUpdate ? (
                `Update How it Work`
              ) : (
                ` Create How it Work`
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default CreateWork;
CreateWork.propTypes = {
  isOpenUpdate: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.any,
  setIsOpenUpdate: PropTypes.any,
  isOpen: PropTypes.any,
};

import axios from "axios";
import http from "Utils/api2";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getAllAffiliate = createAsyncThunk("getAllAffiliate", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleTransiton = createAsyncThunk("getSingleTransiton", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const getSingleWithdral = createAsyncThunk("getSingleWithdral", async (url) => {
  try {
    const res = await http.get(url);
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updateStatus = createAsyncThunk("updateTax", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return { data: null };
  }
});
export const updateJoiningStatus = createAsyncThunk("updateTax", async ({ url, data }) => {
  try {
    const res = await axios.put(url, data, {
      headers: {
        authorization: localStorage.getItem("token"),
      },
    });
    return res.data;
  } catch (error) {
    return { data: null };
  }
});

// Transaction

export const getAllTransaction = createAsyncThunk("getAllTransaction", async (url) => {
  try {
    const res = await http.get(url);
    return res?.data;
  } catch (error) {
    return { data: null };
  }
});

export const updateAffilateTransactionStatus = createAsyncThunk(
  "updateAffilateTransactionStatus",
  async ({ url, data }) => {
    try {
      const res = await axios.put(url, data, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      return res?.data;
    } catch (error) {
      return { data: null };
    }
  }
);

// withdraw

export const getAllWithdraw = createAsyncThunk("getAllWithdraw", async (url) => {
  try {
    const res = await http.get(url);
    return res?.data;
  } catch (error) {
    return { data: null };
  }
});

const AffiliateSlice = createSlice({
  name: "AffiliateSlice",
  initialState: {
    affiliate: null,
    Loading: false,
    transaction: null,
    singletransition: null,
    withdraw: null,
    singlewithdral: null,
    isPages: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAffiliate.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllAffiliate.fulfilled, (state, action) => {
        state.Loading = false;
        state.affiliate = action.payload?.data;
        state.isPages = action?.payload?.page;
      })
      .addCase(getAllAffiliate.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getAllTransaction.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllTransaction.fulfilled, (state, action) => {
        state.Loading = false;
        state.transaction = action.payload?.data;
        state.isPages = action?.payload?.page;
      })
      .addCase(getAllTransaction.rejected, (state, action) => {
        state.Loading = false;
      })
      .addCase(getAllWithdraw.pending, (state, action) => {
        state.Loading = true;
      })
      .addCase(getAllWithdraw.fulfilled, (state, action) => {
        state.Loading = false;
        state.withdraw = action.payload?.data;
        state.isPages = action?.payload?.page;
       
      })
      .addCase(getAllWithdraw.rejected, (state, action) => {
        state.Loading = false;
      })

      .addCase(getSingleTransiton.fulfilled, (state, action) => {
        state.Loading = false;
        state.singletransition = action.payload?.data;
      })
      .addCase(getSingleWithdral.fulfilled, (state, action) => {
        state.Loading = false;
        state.singlewithdral = action.payload?.data;
      });
  },
});
export default AffiliateSlice.reducer;
